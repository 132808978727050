DocumentEnteteValidator.$inject = ['validator'];

export default function DocumentEnteteValidator(validator) {
	const instance = new validator();
	instance.ruleFor('code').notEmpty().when(isNotCodeAuto).withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('code').length(1, 20).when(isNotCodeAuto).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
	instance
		.ruleFor('code')
		.matches(/^[a-zA-Z0-9_|]*$/)
		.when(isNotCodeAuto)
		.withMessage('CODE_CHARACTERS_NOK');
	instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

	return instance;

	function isNotCodeAuto(obj) {
		return !obj.estCodeAuto;
	}
}
