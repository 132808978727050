OffreValidationParametersValidator.$inject = ['validator'];

export default function OffreValidationParametersValidator(validator) {
	const instance = new validator();

	instance.ruleFor('message').length(0, 500).when(isCompleted).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
	instance.ruleFor('smtpLogin').emailAddress().withMessage('VALIDATION_EMAIL');
	instance.ruleFor('smtpPass').length(1, 50).withMessage('VALIDATION_TOO_LONG_50');
	instance.ruleFor('smtpServer').length(1, 50).withMessage('VALIDATION_TOO_LONG_50');
	instance.ruleFor('smtpPort').greaterThan(0).withMessage('VALIDATION_NOT_INTEGER_POSITIVE');
	instance.ruleFor('smtpAlias').emailAddress().when(isCompleted).withMessage('VALIDATION_EMAIL');
	return instance;
	function isCompleted(prop) {
		return prop != null && typeof prop !== 'undefined' && prop !== '';
	}
}
