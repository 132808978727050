import ClientDiversEntete from './client.divers.entete.model';

export default class ClientDiversEnteteController {
	static $inject = [
		'$state',
		'$stateParams',
		'ClientsDiversCommunicationService',
		'ClientsDiversService',
		'notification',
		'ModalService',
		'$uibModal'
	];

	constructor($state, $stateParams, ClientsDiversCommunicationService, ClientsDiversService, notification, ModalService, $uibModal) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ClientsDiversCommunicationService = ClientsDiversCommunicationService;
		this.ClientsDiversService = ClientsDiversService;
		this.notification = notification;
		this.ModalService = ModalService;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		this.loading = false;
		this.codeLoading = false;
		this.isEditMode = false;
		this.textMaskConfigTel = {
			showMask: false,
			mask: [[0], /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
		};
		this.unregisterReinit = this.ClientsDiversCommunicationService.registerReinit(() => this.initForm());
		this.initForm();
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	initForm() {
		let entete = {};
		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
			entete = this.clientDivers.entete;
		} else {
			this.isEditMode = false;
		}

		this.clientDivers.entete = new ClientDiversEntete(entete);
		this.getSocietes();
		this.getClientsGeneriques();
		this.getPays();
	}

	async getSocietes() {
		this.startLoading();
		try {
			const res = await this.ClientsDiversService.getSocietes();
			this.societesListe = res; //.items;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async onSocieteChanged() {
		await this.getClientsGeneriques();
	}

	async getClientsGeneriques() {
		try {
			this.clientsGeneriqueListe = await this.ClientsDiversService.getClientsGeneriques();
			//gestion des comptes ouverts
			for (let index = 0; index < this.clientsGeneriqueListe.length; index++) {
				const site = this.clientsGeneriqueListe[index];
				let res = await this.ClientsDiversService.getCompteOuverts(site.id);
				if (!res.find((r) => r.idSociete == this.clientDivers.entete.idSociete)) {
					this.clientsGeneriqueListe.splice(index, 1);
					index--;
				}
			}
			//Le client générique n'est pas dans la nouvelle société, on retire
			if (!this.clientsGeneriqueListe.find((client) => client.id == this.clientDivers.entete.idClientGenerique))
				this.clientDivers.entete.idClientGenerique = null;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	/*async createSite() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'CLIENTSDIVERS.CONFIRM_CREATE_SITE.TITLE',
            modalMsg: 'CLIENTSDIVERS.CONFIRM_CREATE_SITE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go('clients-divers.newSite'));
    }*/

	// async createSiteEdit() {
	//     let clientDiversId = this.clientDivers.entete.id;
	//     const modalInstance = this.ModalService.confirm({
	//         modalTitle: 'CLIENTSDIVERS.CONFIRM_CREATE_SITE.TITLE',
	//         modalMsg: 'CLIENTSDIVERS.CONFIRM_CREATE_SITE.MESSAGE',
	//         headerClass: 'modal-warning'
	//     });
	//     modalInstance.result.then(() => this.$state.go('clients-divers.editMachin', {id: clientDiversId}));
	// }

	// SL modal et redirection pour la transformation
	// async transformClient() {
	//     const modalInstance = this.ModalService.confirm({
	//         modalTitle: 'CLIENTSDIVERS.CONFIRM_TRANSFORM_CLIENT.TITLE',
	//         modalMsg: 'CLIENTSDIVERS.CONFIRM_TRANSFORM_CLIENT.MESSAGE',
	//         headerClass: 'modal-warning'
	//     });
	//     modalInstance.result.then(() => this.$state.go('clients-divers.transformClient', {clientDiversId: this.clientDivers.entete.id}));
	// }

	async transformClient() {
		const _this = this;
		_this.source = this.clientDivers.entete.id;
		_this.societe = this.clientDivers.entete.idSociete;
		_this.clientGenerique = this.clientDivers.entete.idClientGenerique;
		this.$uibModal
			.open({
				template:
					'<client-divers-modal client-divers-id="$ctrl.source" societe-id = "$ctrl.societe" modal-instance="$ctrl.uibModalInstance"></client-divers-modal>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.societe = _this.societe;
						$ctrl.clientGenerique = _this.clientGenerique;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'l',
				backdrop: false
			})
			.result.then(
				function () {},
				function () {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	async getPays() {
		this.startLoading();
		try {
			const res = await this.ClientsDiversService.getPays();
			this.paysListe = res.items;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async checkCodeUnicity(code) {
		if (!code) {
			this.clientDivers.entete.codeExists = null;
			return;
		}
		this.startCodeLoading();
		try {
			if (code.match(/^[a-zA-Z0-9_]*$/)) {
				this.clientDivers.entete.codeExists = await this.ClientsDiversService.codeExists(code);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopCodeLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
