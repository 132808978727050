import { StateService } from '@uirouter/angularjs';
import { LoDashStatic } from 'lodash';
import * as uiGrid from 'ui-grid';
import { Synthese } from '../../synthese';
import SyntheseEntityService from '../../../../../../services/synthese.entity.service';
import SyntheseSettingService from '../../../../../../services/synthese.setting.service';

class BonLivraisonSyntheseController extends Synthese {
	filter: boolean;
	watcher: (() => void)[];
	/* @ngInject */
	constructor(
		$scope: ng.IScope,
		SyntheseEntityService: SyntheseEntityService,
		SyntheseSettingService: SyntheseSettingService,
		$state: StateService,
		$translate: any,
		$timeout: ng.ITimeoutService,
		_: LoDashStatic,
		uiGridConstants: uiGrid.IUiGridConstants,
		uiGridTemplate: any,
		moment: any,
		notification: any
	) {
		super(
			$scope,
			SyntheseEntityService,
			SyntheseSettingService,
			$state,
			$translate,
			$timeout,
			_,
			uiGridConstants,
			uiGridTemplate,
			moment,
			'bon de livraison',
			notification
		);
		this.watcher = [
			this.$scope.$watch(
				() => this.result,
				() => this.setWatcherContent(() => this.updateData())
			),
			this.$scope.$watch(
				() => this.filter,
				() => this.handleResize()
			)
		];
	}
	$onInit() {
		this.setGridOptions();
	}
	$onDestroy() {
		for (let i = 0; i < this.watcher.length; i++) {
			this.watcher[i]();
		}
	}

	terminer = async () => {
		this.SyntheseSettingService.currentTypeSynthese = this.synthese.typeSynthese;
		this.$state.go('synthese.synthese.list');
	};

	updateData() {
		if (this.result.results.length > 0) {
			this.gridOptions.columnDefs = this.createColumnsDef(this.result.results);
			this.gridOptions.data = this.result.results[0].grid.body;
			this.refreshGrid();
		}
	}

	setGridOptions() {
		this.gridOptions.rowTemplate = this.uiGridTemplate.synthentsRow;
		this.gridOptions.onRegisterApi = this.onRegisterApi;
	}

	onRegisterApi = (gridApi: uiGrid.IGridApi) => {
		this.gridApi = gridApi;
		if (this.synthese && this.synthese.setting) {
			this.$timeout(() => {
				const setting = JSON.parse(this.synthese.setting.setting);
				gridApi.saveState.restore(this.$scope, setting);
			}, 10);
		}
		this.setAggregationChange();
		this.listenToColumnPinnedEvent();
		//gridApi.core.refresh();
	};

	setAggregationChange() {
		if (!this.gridApi) {
			return;
		}
		this.gridApi.grouping.on.aggregationChanged(this.$scope, (col) => {
			const table = this.result.results[0].grid;
			this.aggregationChanged(col, table);
		});
	}

	aggregationChanged(col: any, table: any) {
		if (this.changeAgg) {
			return;
		}
		this.changeAgg = true;
		const finded = table.header.filter((x: any) => x.displayName === col.displayName);
		for (let i = 0; i < finded.length; i++) {
			const f = finded[i];
			this.gridApi.grouping.aggregateColumn(`columns.${f.field}.value`, col.treeAggregation.type, col.treeAggregation.label);
		}
		this.changeAgg = false;
	}

	createColumnsDef(results: any) {
		const table = results[0].grid;
		this.gridOptions.columnFooterHeight = table.footer.length * 30;
		const columns = this.setColumnDefs(table);
		return columns;
	}

	saveSynthese() {
		this.save(this.gridApi, false, false, false);
	}

	genSynth() {
		if (this.gridApi) {
			// Parametre en standby le temps de trouver du temps pour mieux le gerer
			//this.setting = this.gridApi.saveState.save();
		}
		this.generate();
	}
	duplicate() {
		// console.log(this);
		this.save(this.gridApi, false, false, true);
	}
}

export default {
	bindings: {
		result: '<',
		save: '=',
		openSynthese: '=',
		generate: '<',
		synthese: '<',
		filter: '<'
	},
	controller: BonLivraisonSyntheseController,
	template: require('./bon.livraison.tpl.html')
};
