import OffreValidationParameters from './offre.validation.parameters.model';

export default class OffreValidationParametersController {
	static $inject = ['$scope', '$state', '$stateParams', 'ModalService', 'OffresService', 'notification', '$translate', '$filter', '_', '$uibModal'];
	constructor($scope, $state, $stateParams, ModalService, OffresService, notification, $translate, $filter, _, $uibModal) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ModalService = ModalService;
		this.OffresService = OffresService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		this.startLoading();
		this.societes = await this.OffresService.getSocietes();
		this.stopLoading();
		this.params = new OffreValidationParameters();
	}

	async loadParams() {
		const parm = (await this.OffresService.getEmailValidationParameters(this.params.idSociete, 'offres')) || null;
		if (parm) {
			this.params = new OffreValidationParameters(parm);
		}
	}

	async save() {
		if (this.checkValidity()) {
			// try {
			await this.OffresService.createOrUpdateEmailValidationParameters({
				idSociete: this.params.idSociete, //number
				idDomaine: this.params.idDomaine, //number
				message: this.params.message, //string
				smtpLogin: this.params.smtpLogin, //email
				smtpPass: this.params.smtpPass, //string
				smtpServer: this.params.smtpServer, //string
				smtpPort: this.params.smtpPort, //number
				smtpAlias: this.params.smtpAlias, //email
				smtpIsSslConnection: this.params.smtpIsSslConnection //bool
			});
			return this.notification.success('OFFRES.RESULTAT_VALIDATION_PARAMETERS.PARAMETERS_SAVED');
			// } catch (ex) {
			//   return this.notification.error(ex.data);
			// }
		} else {
			return this.notification.error(this.params.$errors.$all[0].errorMessage);
		}
	}

	cancel() {
		this.$scope.$parent.$dismiss();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
	async selectItem(data, list) {
		if (this.params.idSociete === data.id) {
			let index = list.findIndex((x) => x.id === data.id);
			this.params.idSociete = null;
			list[index].selected = false;
			this.params = null;
		} else {
			list.forEach((x) => {
				x.selected = false;
			});
			this.params.idSociete = data.id;
			if (this.params.idSociete > 0) {
				await this.loadParams();
			}
		}
	}
	resetItem(list) {
		list.forEach((x) => {
			x.selected = false;
		});

		this.params.idSociete = null;
		this.params = null;
	}

	checkValidity() {
		console.log('🔥: OffreValidationParametersController -> checkValidity -> ');
		let validity = true;

		console.log('🔥: OffreValidationParametersController -> checkValidity -> this.params', this.params);
		if (!this.params.isValid()) {
			validity = false;
		}
		return validity;
	}
}
