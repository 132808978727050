import { StateService } from '@uirouter/angularjs';
import { Type } from '../../../../../core/models/type/Type';
import { Profil } from '../../../../../core/models/Profil';
import { Unite } from '../../../../../core/models/Unite';
import ProfilsService from '../../../../common/profils/services/profils.service';
import UnitesService from '../../../../common/unites/services/unites.service';
import TypesService from '../../../../common/types/services/types.service';
import MassiaApplicationService from '../../../../massia/layout/components/massia-application/massia.application.service';
import { ui } from 'angular';

import { ValidationDevisNiveaux, ValidationDevis, ValidationDevisProduits } from './ValidationDevis';
import OffresService from '../../services/offres.service';
import OffreValidationDevis from './offre.validation.devis.model';
import OffreValidationNiveaux from './offre-validation-niveau/offre.validation.niveaux.model';
import * as _ from 'lodash';
import MassiaRightsService from '../../../../massia/authentication/services/massia.rights.service';

import { isSeuilMontant, isSeuilVolume, isValidationProduitRow } from './offre.validation.service';

export default class OffreValidationDevisController {
	TypesService: TypesService;
	ProfilsService: ProfilsService;
	MassiaApplicationService: MassiaApplicationService;
	MassiaRightsService: MassiaRightsService;
	$state: StateService;
	UnitesService: UnitesService;
	$uibModal: ui.bootstrap.IModalService;
	loading: boolean;
	isEditMode: boolean;
	form: ValidationDevis;
	profils: Profil[];
	typesProduit: Type[] = [];
	unites: Unite[];
	notification: any;
	OffresService: OffresService;
	validationNiveau: any[];
	validationProfilAControler: boolean;
	niveauSelection: number;

	/* @ngInject */
	constructor(
		$state: StateService,
		notification: any,
		OffresService: OffresService,
		TypesService: TypesService,
		UnitesService: UnitesService,
		ProfilsService: ProfilsService,
		MassiaApplicationService: MassiaApplicationService,
		MassiaRightsService: MassiaRightsService,
		$uibModal: ui.bootstrap.IModalService
	) {
		this.$state = $state;
		this.notification = notification;

		this.OffresService = OffresService;
		this.TypesService = TypesService;
		this.UnitesService = UnitesService;
		this.ProfilsService = ProfilsService;
		this.MassiaApplicationService = MassiaApplicationService;
		this.MassiaRightsService = MassiaRightsService;
		this.$uibModal = $uibModal;
		this.loading = false;
		// this.isEditMode = true;
		this.profils = [];
		this.unites = [];
		this.validationNiveau = [];
		this.validationProfilAControler = true;
		this.niveauSelection = 0;
	}

	async $onInit() {
		this.isEditMode = this.MassiaRightsService.userHasRight('offrevaliddev', 'create', 'gestion');
		this.initValidationNiveau(0);
		const config: ValidationDevis = await this.getConfigValidationDevis();
		await this.loadTypeProduit();
		await this.loadProfils();
		await this.loadUnites();
		this.form = new OffreValidationDevis();
		if (config) {
			this.form = new OffreValidationDevis(config);
			// pour la validation des niveaux
			const vdnl = this.form.validationDevisNiveaux.length;
			for (let index = 0; index < vdnl; index++) {
				this.initValidationNiveau(index);
			}
		}
	}

	debug() {
		console.log('🚀: OffreValidationDevisController -> debug -> ', this);
	}

	// log(...arg: any) {
	//     console.log(arg);
	// }

	copyArray(array: Type[]) {
		return _.cloneDeep(array);
	}

	getLibelleProfil(id: number) {
		return this.profils.find((profil) => profil.id === id)?.libelle;
	}

	async getConfigValidationDevis() {
		return await this.OffresService.getConfigValidationDevis();
	}

	async loadProfils() {
		const data = await this.ProfilsService.getProfils([], [], { skip: 0, take: 0 }, this.MassiaApplicationService.getApplication());
		this.profils = data.items;
	}
	async loadTypeProduit() {
		const data: any = await this.TypesService.getTypes(
			[{ criterion: 'domaine', value: 'Produits', type: 'Contains' }],
			[],
			{ skip: 0, take: 0 },
			this.MassiaApplicationService.getApplication()
		);
		this.typesProduit = data.items;
	}
	async loadUnites() {
		const data = await this.UnitesService.getUnites([], [], { skip: 0, take: 0 });
		this.unites = data.items;
	}

	// reset les valeurs si changement de modèle du seuil
	changeModelSeuilVolume(vdn: ValidationDevisNiveaux) {
		vdn.valeurMinVolume = null;
		vdn.valeurMaxVolume = null;
	}
	changeModelSeuilMontant(vdn: ValidationDevisNiveaux) {
		vdn.valeurMinMontant = null;
		vdn.valeurMaxMontant = null;
	}
	changeModelSeuilProduit(validationDevisProduits: ValidationDevisProduits) {
		validationDevisProduits.valeurMin = null;
		validationDevisProduits.valeurMax = null;
	}

	addNiveau(index: number) {
		this.form.validationDevisNiveaux.push(new OffreValidationNiveaux({ niveau: index }));

		// pour la validation des valeurs min et max
		this.initValidationNiveau(index - 1);
	}

	deleteNiveau = (niveauToDelete: number) => {
		this.form.validationDevisNiveaux.splice(niveauToDelete, 1);
		this.form.validationDevisNiveaux.forEach((x: any, index: number) => (x.niveau = index + 1));
		this.validationNiveau.splice(niveauToDelete, 1);
		const newNiveau = niveauToDelete !== 0 ? niveauToDelete - 1 : niveauToDelete;
		this.changeNiveauSelec(newNiveau);
	};

	async sauvegarder(): Promise<boolean> {
		if (this.checkValidity()) {
			this.startLoading();

			try {
				let config;
				if (this.form.id) {
					config = await this.OffresService.updateConfigValidationDevis(this.form);
				} else {
					config = await this.OffresService.createConfigValidationDevis(this.form);
				}
				if (config) {
					this.notification.success('OFFRES.CREATED');
					const data = await this.getConfigValidationDevis();

					this.form = new OffreValidationDevis(data);
					return true;
				}
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
		}
	}

	async appliquer() {
		const success = await this.sauvegarder();
		if (success) {
			this.$state.go('offres.validation');
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();
		if (success) {
			this.annuler();
		}
	}
	annuler() {
		this.$state.go('offres.list');
	}

	checkValidity(): boolean {
		let validity: boolean = true;

		this.validationProfilAControler = true;

		if (!this.form.idProfilAControler) {
			this.validationProfilAControler = false;
			this.notification.error('OFFRES.VALIDATION_DEVIS.NO_PROFIL_A_CONTROLER');
			validity = false;
		}

		if (this.form.validationDevisNiveaux && this.form.validationDevisNiveaux.length > 0) {
			_.forEach(this.form.validationDevisNiveaux, (nv, index) => {
				// pour la validation des valeurs min et max
				this.initValidationNiveau(index);
				const validationNiveau = this.validationNiveau[index];

				if (!nv.idProfilDeValidation) {
					validationNiveau.haveProfilDeValidation = false;
					this.notification.error('OFFRES.VALIDATION_DEVIS.NO_PROFIL_DE_VALIDATION');
					validity = false;
				}

				if (nv.isSeuilVolume) {
					let volumeValide = true;

					volumeValide = isSeuilVolume(nv, volumeValide);
					if (!volumeValide) {
						validationNiveau.isSeuilVolumeOk = false;
						this.notification.error('OFFRES.VALIDATION_DEVIS.CONTROLE_VOLUME.ERROR');
						validity = false;
					}
				}
				if (nv.isSeuilMontant) {
					let montantValide = true;
					montantValide = isSeuilMontant(nv, montantValide);
					if (!montantValide) {
						validationNiveau.isSeuilMontantOk = false;
						validity = false;
						this.notification.error('OFFRES.VALIDATION_DEVIS.CONTROLE_MONTANT.ERROR');
					}
				}
				//row produit non valide
				_.forEach(nv.validationDevisProduits, (produit) => {
					const isProduitValide: boolean = isValidationProduitRow(produit);
					if (!isProduitValide) {
						validity = isProduitValide;
						this.notification.error('OFFRES.VALIDATION_DEVIS.CONTROLE_PRODUIT.ERROR');
						this.validationNiveau[nv.niveau - 1].isSeuilRemiseProduit = isProduitValide;
					}
				});
				// validation produit activé mais sans produit
				if (nv.isSeuilRemiseProduit) {
					if (nv.validationDevisProduits && nv.validationDevisProduits.length < 1) {
						validationNiveau.isSeuilRemiseProduit = false;
						validity = false;
						this.notification.error('OFFRES.VALIDATION_DEVIS.CONTROLE_PRODUIT.ERROR');
					}
				}
			});
		}
		return validity;
	}

	initValidationNiveau(index: number) {
		this.validationNiveau[index] = {
			haveProfilDeValidation: true,
			isSeuilMontantOk: true,
			isSeuilVolumeOk: true,
			isSeuilRemiseProduit: true
		};
	}

	changeNiveauSelec(niveau: number) {
		this.niveauSelection = niveau;
	}

	async getModalParameters() {
		this.$uibModal.open({
			component: 'offreValidationParameters',
			controllerAs: '$ctrl',
			backdrop: false,
			animation: true,
			size: 'l'
		});
	}

	startLoading() {
		this.loading = true;
	}
	stopLoading() {
		this.loading = false;
	}
}
