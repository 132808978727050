import { StateParams, StateService, UIRouterGlobals } from '@uirouter/angularjs';
import { UniteConversionService } from '../../../../../../../core/http/unite-conversion.service';
import { UniteConversion } from '../../../../../../../core/models/UniteConversion';
import { DebugLevel, LoggerService } from '../../../../../../../libs/massia-component/massia-service/logger.service';
import { RouterHistoryService } from '../../../../../../../libs/massia-component/massia-service/router.history.service';
import MassiaApplicationService from '../../../../../../massia/layout/components/massia-application/massia.application.service';
import { CritereAffichage } from '../../../../models/criteres.affichage.model';
import Synthese from '../../../../models/synthese.model';
import SyntheseCacheService from '../../../../services/synthese.cache.service';
import SyntheseEntityService from '../../../../services/synthese.entity.service';
import SyntheseModelesService from '../../../../services/synthesemodeles.service';
import * as angular from 'angular';

/* @Component({
    selector: 'setupSyntheseModal',
    controller: SetupSyntheseModal,
    template: require('./setup.modal.html'),
    bindings: {
        modalInstance: '=',
    },
}) */
export class SetupSyntheseModal {
	modalInstance: ng.ui.bootstrap.IModalInstanceService;
	resolve: {
		synthese: Synthese;
		isUser: boolean;
		fromResult: boolean;
		allowGen: boolean;
	};
	loading: boolean = true;
	active: number = 0;
	subActive: number = 0;
	synthese: Synthese;
	nbrBloc: number = 0;
	modelSelection: any;
	navigation: any;
	elements: any;
	uniteConv: UniteConversion[];
	application: string;
	private _u: () => void;

	$scope: ng.IScope;
	$state: StateService;
	$stateParams: StateParams;
	$uiRouterGlobal: UIRouterGlobals;
	SyntheseEntityService: SyntheseEntityService;
	SyntheseModelesService: SyntheseModelesService;
	MassiaApplicationService: MassiaApplicationService;
	$uibModal: ng.ui.bootstrap.IModalService;
	$log: ng.ILogService;
	$document: ng.IDocumentService;
	notification: any;
	RouterHistoryService: RouterHistoryService;
	SyntheseCacheService: SyntheseCacheService;
	UniteConversionSvc: UniteConversionService;
	LogSvc: LoggerService;
	isLoaded: boolean;
	allowGen: boolean;

	/* @ngInject */
	constructor(
		$scope: ng.IScope,
		$state: StateService,
		$stateParams: StateParams,
		SyntheseEntityService: SyntheseEntityService,
		SyntheseModelesService: SyntheseModelesService,
		MassiaApplicationService: MassiaApplicationService,
		$uibModal: ng.ui.bootstrap.IModalService,
		$log: ng.ILogService,
		$document: ng.IDocumentService,
		notification: any,
		RouterHistoryService: RouterHistoryService,
		SyntheseCacheService: SyntheseCacheService,
		UniteConversionService: UniteConversionService,
		$uiRouterGlobals: UIRouterGlobals,
		Logger: LoggerService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SyntheseEntityService = SyntheseEntityService;
		this.SyntheseModelesService = SyntheseModelesService;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.$log = $log;
		this.$document = $document;
		this.notification = notification;
		this.RouterHistoryService = RouterHistoryService;
		this.SyntheseCacheService = SyntheseCacheService;
		this.UniteConversionSvc = UniteConversionService;
		this.$uiRouterGlobal = $uiRouterGlobals;
		this.LogSvc = Logger;
		this.isLoaded = false;
	}
	async $onInit(): Promise<void> {
		try {
			this.application = this.MassiaApplicationService.getApplication();
			this.elements = {
				_1: [],
				_2: [],
				_3: [],
				_0: []
			};
			this.synthese = this.resolve.synthese;
			this.allowGen = this.resolve.allowGen;
			if (this.$stateParams.base > 0) {
				this.synthese = await this.SyntheseEntityService.getOne(this.$stateParams.base);
				this.synthese.isduplicate = true;
				this.synthese.id = -1;
			}
			this.nbrBloc = this.synthese.blocSelections ? this.synthese.blocSelections.length : 1;
			this.modelSelection = await this.loadModeleSelection();
			this._u = this.$scope.$watch(
				() => this.synthese.typeSynthese,
				async () => {
					this.modelSelection = await this.loadModeleSelection();
					this.synthese.blocSelections = this.synthese.blocSelections.map((x: any) => {
						if (!this.modelSelection.map((m: any) => m.id).includes(x.modelSelection.id)) {
							x.modelSelection = null;
						}
						return x;
					});
					this.setNavigation();
				},
				true
			);
			this.loadUniteConversions();
			this.SyntheseCacheService.loadEssai();
			this.loading = false;
		} catch (err) {
			this.loading = false;
			this.LogSvc.log(DebugLevel.ERROR, err);
		}
	}
	$onDestroy(): void {
		if (this._u) {
			this._u();
		}
		this.SyntheseEntityService.setSyntheseLoaded(false);
	}

	$doCheck() {
		this.isLoaded = this.SyntheseEntityService.getSyntheseLoaded();
	}

	async loadUniteConversions() {
		const res = await this.UniteConversionSvc.getAll(null, null, { skip: 0, take: 0 });
		this.uniteConv = res.items;
	}

	async loadModeleSelection() {
		try {
			if (this.synthese.typeSynthese !== null && typeof this.synthese.typeSynthese !== 'undefined') {
				const app = this.MassiaApplicationService.getApplication();
				return (await this.SyntheseModelesService.getSyntheseModeles(null, null, null, app, this.synthese.typeSynthese)).items;
			}
			return [];
		} catch (err) {
			console.error(err);
		}
	}

	setNavigation() {
		this.LogSvc.log(DebugLevel.INFO, this.$uiRouterGlobal.current);
		const navigation = [
			{
				id: 'n0',
				libelle: 'SYNTHENTS.NAV.ENTETE',
				icon: 'massia-icon-info',
				condition: () => !this.resolve.isUser,
				component: 'synthese-entete',
				model: [
					{
						name: 'model',
						data: this.synthese
					}
				]
			},
			{
				id: 'n1',
				libelle: 'SYNTHENTS.NAV.CONTROL',
				icon: 'massia-icon-lab',
				component: 'synthese-controle',
				app: ['laboratoire']
			},
			...this.setSubNavigation(3),
			{
				id: 'n2',
				libelle: 'SYNTHENTS.NAV.AFFICHAGE',
				icon: 'massia-icon-table2',
				condition: () => this.synthese.typeSynthese !== null && typeof this.synthese.typeSynthese !== 'undefined' && !this.resolve.isUser,
				component: 'synthese-affichage',
				model: [
					{
						name: 'synthese',
						data: this.synthese
					},
					{
						name: 'elements',
						data: this.elements
					},
					{
						name: 'conversion',
						data: this.uniteConv
					},
					{
						name: 'gen',
						data: this.$uiRouterGlobal.current.name === 'synthese.synthese.edit'
					}
				]
			},
			{
				id: 'n3',
				icon: 'glyphicon glyphicon-cog',
				libelle: 'SETTINGS',
				condition: () => !this.resolve.isUser,
				component: 'synthese-setting'
			}
		];
		if (!this.resolve.isUser && !this.active) {
			this.active = 0;
		}
		this.navigation = navigation;
	}

	setSubNavigation(length: number) {
		if (this.resolve.isUser && !this.active) {
			this.active = length - 1;
		}
		const subnav = [];
		if (this.synthese.blocSelections && this.synthese.blocSelections.length > 0) {
			for (let i = 0; i < this.synthese.blocSelections.length; i++) {
				const bloc = this.synthese.blocSelections[i];
				subnav.push({
					id: `b${bloc.id}`,
					libelle: bloc.libelle,
					icon: 'massia-icon-list-numbered',
					delete: (active: number) => {
						const act = this.active;
						this.removeBloc(i);
						if (act === active) {
							this.active === 0;
						}
					},
					model: [
						{
							name: 'bloc',
							data: this.synthese.blocSelections[i]
						},
						{
							name: 'modeleSelection',
							data: this.modelSelection
						},
						{
							name: 'isUser',
							data: !this.resolve.isUser
						}
					],
					component: 'synthese-bloc-selection'
				});
			}
		}
		subnav.push({
			id: 'l1',
			libelle: 'SYNTHENTS.NEW_BLOC',
			component: 'synthese-modal-bloc-selection',
			icon: 'glyphicon glyphicon-plus',
			condition: () =>
				this.synthese.typeSynthese !== null &&
				typeof this.synthese.typeSynthese !== 'undefined' &&
				((this.MassiaApplicationService.getApplication() === 'gestion' && this.synthese.blocSelections.length === 0) ||
					this.MassiaApplicationService.getApplication() !== 'gestion'),
			model: [
				{
					name: 'type',
					data: this.synthese.typeSynthese
				},
				{
					name: 'resolve',
					data: {
						idTypeSynthese: this.synthese.typeSynthese,
						idSynthese: this.synthese.id,
						blocSelection: this.synthese.blocSelections
					}
				},
				{
					name: 'close',
					data: async (bloc: any) => {
						if (!this.synthese.blocSelections) {
							this.synthese.blocSelections = [];
						}
						this.synthese.blocSelections.push(bloc);
						this.nbrBloc = this.synthese.blocSelections.length;
						this.modelSelection = await this.loadModeleSelection();
						this.setNavigation();
						this.active = 1 + this.nbrBloc;
					}
				}
			]
		});
		return subnav;
	}

	removeBloc(index: number) {
		this.synthese.blocSelections.splice(index, 1);
		this.setNavigation();
	}

	setActiveNav(item: any, i: any) {
		if (!item.onClick) {
			this.active = i;
		}
		if (item.onClick) {
			item.onClick();
		}
	}

	async duplicate() {
		this.synthese.isduplicate = true;
		const res = await this.openSaveAsModal();
		if (res) await this.save(true, false, true);
	}

	async openSaveAsModal() {
		const code = this.synthese.code;
		const libelle = this.synthese.libelle;
		const type = this.synthese.typeSynthese;
		const modal = this.$uibModal.open({
			animation: false,
			component: 'saveAsModal',
			windowClass: 'modern-modal',
			size: '60',
			backdrop: 'static',
			appendTo: this.$document.find('massia-application').eq(0),
			resolve: {
				code: () => code,
				libelle: () => libelle,
				type: () => type
			}
		});
		try {
			const res = await modal.result;
			if (res) {
				this.synthese.code = res.code;
				this.synthese.libelle = res.libelle;
				return true;
			}
		} catch (err) {
			//modal closed
		}
		return false;
	}

	debug() {
		// console.log(this.synthese);
	}

	ok() {
		this.getCritereAffichage();
		this.modalInstance.close(this.synthese);
	}

	getCritereAffichage() {
		if (!this.resolve.isUser) {
			const elem = [];
			for (const key in this.elements) {
				if (Object.prototype.hasOwnProperty.call(this.elements, key)) {
					const element = this.elements[key];
					elem.push(
						...element.map((e: any) => {
							e.type = parseInt(key.replace('_', ''));
							return e;
						})
					);
				}
			}
			const crit = elem.map((e: any) => new CritereAffichage(e, typeof e.type === 'number' ? e.type : 1));
			const tmp = [];
			for (let i = 0; i < crit.length; i++) {
				const element = crit[i];
				const current = this.synthese.critereAffichages.find(
					(x: any) =>
						x.champ === element.champ &&
						x.champCompo === element.champCompo &&
						x.idDonneeSelectionable === element.idDonneeSelectionable &&
						x.idCaracteristique === element.idCaracteristique &&
						x.type === element.type
				);
				if (current) {
					current.ordre = element.ordre;
					current.title = element.title;
					current.idUnite = element.idUnite;
					current.style = element.style;
					tmp.push(current);
					continue;
				}
				tmp.push(element);
			}
			this.synthese.critereAffichages = tmp;
		}
	}

	cancel() {
		this.modalInstance.dismiss();
		if (this.resolve.fromResult) return;

		const back =
			this.RouterHistoryService?.history != null && this.RouterHistoryService?.history.length > 0
				? this.RouterHistoryService?.history[this.RouterHistoryService.history.length - 1]?.name
				: 'synthese.synthese.list';
		if (!this.RouterHistoryService.back() || !back.includes('synthese.list')) {
			this.$state.go('synthese.synthese.list');
		}
	}

	isCodeConforme() {
		return this.synthese.code.match(/^[a-zA-Z0-9_]*$/);
	}

	async isCodeUnique() {
		//Recherche par contains pour gérer la casse après
		const filtre = [{ criterion: 'code', value: this.synthese.code, type: 'Contains' }];
		const synths = await this.SyntheseEntityService.getAll(this.synthese.typeSynthese, filtre);
		let isUnique = true;
		for (let index = 0; index < synths.items.length; index++) {
			const synth = synths.items[index];
			if (synth.code.toLowerCase() == this.synthese.code.toLowerCase()) {
				isUnique = false;
				break;
			}
		}
		return isUnique;
	}

	async save(exit = false, gen = false, onlySave = false) {
		let id = null;
		try {
			if (!this.synthese.id || this.synthese.id <= 0) {
				if (!this.synthese.code || !this.isCodeConforme()) {
					this.notification.error('CODE_CHARACTERS_NOK', null, {
						timeOut: 2000,
						extendedTimeOut: 0,
						closeButton: false
					});

					return;
				} else {
					if (!(await this.isCodeUnique())) {
						this.notification.error('CODE_UNIQUE_NOK', null, {
							timeOut: 2000,
							extendedTimeOut: 0,
							closeButton: false
						});

						return;
					}
				}
			}

			if (!this.synthese.libelle) {
				this.notification.error('LIBELLE_EMPTY_NOK', null, {
					timeOut: 2000,
					extendedTimeOut: 0,
					closeButton: false
				});

				return;
			}

			this.notification.info('SAVE_IN_PROGRESS', null, {
				timeOut: 2000,
				extendedTimeOut: 0,
				closeButton: false
			});
			this.getCritereAffichage();

			if (!this.synthese.isduplicate) {
				if (this.$stateParams.id) {
					id = this.$stateParams.id;
				} else if (this.synthese.id != undefined) {
					id = this.synthese.id;
				}

				// Si nouvelle Synthèse
				if (!id) {
					id = await this.SyntheseEntityService.create(this.synthese);
					this.notification.clear();
					this.notification.success('SUCCESS_CREATE', null, {
						closeButton: false,
						progressBar: true
					});
				}
				//Si mise à jour
				else {
					await this.SyntheseEntityService.updateOne(this.synthese.id, this.synthese);
					this.notification.clear();
					this.notification.success('SUCCESS_UPDATE', null, {
						closeButton: false,
						progressBar: true
					});
				}
			} else {
				// On set les ID à 0 pour trigger l'auto_increment de la base de donnée
				this.synthese.id = 0;
				this.synthese.critereAffichages.forEach((element: any) => {
					element.id = 0;
					element.style.id = 0;
				});
				this.synthese.blocSelections.forEach((bloc: any) => {
					bloc.modelSelection.modelSelectionCriteres.forEach((crit: any) => {
						crit.modelSelectionCritereValeur.forEach((critVal: any) => {
							critVal.id = 0;
						});
					});
				});

				const newSynth = angular.copy(this.synthese);
				id = await this.SyntheseEntityService.create(newSynth);

				this.notification.clear();
				this.notification.success('SUCCESS_CREATE', null, {
					closeButton: false,
					progressBar: true
				});
				if (!!id && this.resolve.isUser) {
					this.modalInstance.close();
					await this.$state.go('synthese.synthese.edit', { id: id, synthese: null });
					return;
				}
			}

			// Si ici, l'id vaut toujours null ou est undefined, il y a un problème
			if (!id) throw new Error(`❌ Unhandled error while saving Synthese (id: ${id} )`);

			if (!onlySave) {
				this.$state.go('synthese.synthese.edit', { id: id });
			} else {
				this.synthese.id = id;
			}

			if (exit && !gen) {
				this.exit();
			}

			if (gen) {
				return this.generate(id);
			}
		} catch (error) {
			// L'api gère déjà l'erreur
		}
	}

	generate(id: number | null = null) {
		this.$state.go('synthese.synthese.generate', { id: id || this.$stateParams.id, synthese: this.synthese });
	}

	exit() {
		this.modalInstance.close(this.synthese);
		this.cancel();
	}
}

export default {
	bindings: {
		modalInstance: '=',
		resolve: '='
	},
	controller: SetupSyntheseModal,
	template: require('./setup.modal.html')
};
