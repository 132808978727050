import OffreValidationParametersController from './offre.validation.parameters.controller';
import offreValidationParametersHtml from './offre.validation.parameters.html';

export default {
	bindings: {
		modalInstance: '='
	},
	template: offreValidationParametersHtml,
	controller: OffreValidationParametersController
};
