import * as _ from 'lodash';
import elementsLiesEntree from './elements.lies.entrees.html';
import elementsLiesSortie from './elements.lies.sorties.html';

export default class ElementsLiesController {
	static $inject = [
		'$state',
		'$stateParams',
		'CentreGestionService',
		'ModalService',
		'notification',
		'$translate',
		'$filter',
		'_',
		'$scope',
		'$templateCache'
	];
	constructor($state, $stateParams, CentreGestionService, ModalService, notification, $translate, $filter, _, $scope, $templateCache) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.CentreGestionService = CentreGestionService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this._ = _;
		this.$scope = $scope;
		this.eligible = ['transportsLies', 'produitsLies'];
		this.isUpdateMode = false;
		$templateCache.put('element-liee-entree.html', elementsLiesEntree);
		$templateCache.put('element-liee-sortie.html', elementsLiesSortie);
	}

	async $onInit() {
		this.codeParametre = this.parametre.codeParametre;

		this.changedValue = [false, false];
		// await this.getElementsSelectionnables(this.codeParametre);
		console.log(this.codeParametre);
		await this.getLibelleElement();
		await this.activeUpdateMode();
		this.$scope.$watch('$ctrl.frontFilters', async () => {
			await this.prepareView();
		});
	}

	async activeUpdateMode() {
		await this.getElementsSelectionnables(this.codeParametre);
		this.isUpdateMode = true;
	}

	async getLibelleElement() {
		switch (this.codeParametre) {
			case 'produitsLies':
				this.filterToBack.entreeSortie = 0;
				this.libelleElementEntree = await this.CentreGestionService.getProduitsLibelle(this.filterToBack);
				this.filterToBack.entreeSortie = 1;
				this.libelleElementSortie = await this.CentreGestionService.getProduitsLibelle(this.filterToBack);
				break;
			case 'transportsLies':
				this.filterToBack.entreeSortie = 0;
				this.libelleElementEntree = await this.CentreGestionService.getTransportsLibelle(this.filterToBack);

				this.filterToBack.entreeSortie = 1;
				this.libelleElementSortie = await this.CentreGestionService.getTransportsLibelle(this.filterToBack);
				break;
		}
	}

	async prepareView() {
		this.statusHeritageInput = this.getStatusHeritage(this.parametre, 0);
		this.statusHeritageOutput = this.getStatusHeritage(this.parametre, 1);
	}

	//region Récupération et preparation
	// /**
	//  *  INFOS  Il s'agit de la mise en forme des élements qui
	//  * PEUVENT être selectionnés
	// */
	prepareListeElementsLies(elementsSelectionnablesOrdonnes) {
		let tmp = null;
		const temp = [];
		for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			const currentValue = elementsSelectionnablesOrdonnes[index];
			const toReturn = [];
			if (Object.prototype.hasOwnProperty.call(currentValue, 'transportId')) {
				let newlist = {
					valeur: false,
					produitId: null,
					transportId: currentValue.transportId,
					transporteurId: currentValue.transporteurId,
					producteurId: null,
					libelle: currentValue.transportLibelle,
					entreeSortie: null,
					elementCode: 'transpLie',
					elementId: 7,
					elementType: 'int',
					elementDomaine: 'transport',
					achatVente: null,
					id: null,
					societeId: null,
					siteCommercialId: null,
					chantierId: null,
					prestationId: null,
					benneId: null,
					clientId: null,
					tvaId: null,
					paysId: null,
					dateApplication: null,
					disabled: false,
					isHerited: false,
					search: currentValue.transportLibelle
				};
				if (this.frontFilters) {
					const pourFiltre = this.getPropFiltre(this.frontFilters);
					newlist[this.getProperty(pourFiltre.domaine)] = pourFiltre.id;
				}
				toReturn.push(newlist);
			} else {
				if (tmp == null) {
					//creation du premier groupe
					tmp = currentValue.producteurId;
					toReturn.push({
						libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>',
						msGroup: true,
						producteurId: currentValue.producteurId,
						valeur: false,
						elementCode: 'prodLie'
					});
				} else {
					if (tmp != currentValue.producteurId) {
						//création des autres groupes
						toReturn.push({
							msGroup: false,
							valeur: false
						});
						tmp = currentValue.producteurId;
						toReturn.push({
							libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>',
							msGroup: true,
							producteurId: currentValue.producteurId,
							valeur: false,
							elementCode: 'prodLie'
						});
					}
				}
				let newlist = {
					valeur: false,
					produitId: currentValue.produitId,
					producteurId: currentValue.producteurId,
					libelle: currentValue.produitLibelle + ' [' + currentValue.produitCode + ']',
					entreeSortie: null,
					elementCode: 'prodLie',
					elementId: 5,
					elementType: 'int',
					elementDomaine: 'produit',
					achatVente: null,
					id: null,
					societeId: null,
					siteCommercialId: null,
					transporteurId: null,
					chantierId: null,
					prestationId: null,
					transportId: null,
					benneId: null,
					clientId: null,
					tvaId: null,
					paysId: null,
					dateApplication: null,
					disabled: false,
					isHerited: false,
					search: currentValue.produitLibelle + ' ' + currentValue.produitCode + ' ' + currentValue.producteurLibelle
				};
				if (this.frontFilters) {
					const pourFiltre = this.getPropFiltre(this.frontFilters);
					newlist[this.getProperty(pourFiltre.domaine)] = pourFiltre.id;
				}
				toReturn.push(newlist);

				if (index === elementsSelectionnablesOrdonnes.length - 1) {
					toReturn.push({
						msGroup: false,
						valeur: false
					});
				}
			}
			temp.push(toReturn);
		}
		const sorties = _.flatten(temp);
		for (let i = 0; i < sorties.length; i++) {
			const sortie = sorties[i];
			sortie.entreeSortie = 1;
		}
		const entrees = angular.copy(sorties);
		for (let i = 0; i < entrees.length; i++) {
			const entree = entrees[i];
			entree.entreeSortie = 0;
		}
		return new Promise((resolve) => resolve([entrees, sorties]));
	}

	formatProduitLiesForMassiaMultiSelect() {
		let currentProducteur = '';
		let newList = [];
		for (let globalIndex = 0; globalIndex < this.listes.produitsLies.length; globalIndex++) {
			const list = this.listes.produitsLies[globalIndex];
			let subList = [];
			for (let index = 0; index < list.length; index++) {
				const ligne = list[index];
				if (ligne.msGroup == true) {
					let reg = new RegExp('<.[^<>]*>', 'gi');
					currentProducteur = ligne.libelle.replace(reg, '');
				} else if (ligne.msGroup == false) {
					//fin de bloc
				} else {
					ligne.groupLibelle = currentProducteur + ' | ';
					subList.push(ligne);
				}
			}
			newList.push(subList);
		}
		return newList;
	}
	/**
	 * INFOS  Récupère les élements séléctionnables
	 */
	async getElementsSelectionnables(codeParametre) {
		if (this.domaine == 'Societe' || this.domaine == 'SiteUtil' || this.frontFilters.siteCommercial || this.frontFilters.societes) {
			try {
				let elementsSelectionnablesOrdonnes = null;
				switch (codeParametre) {
					case 'produitsLies':
						this.listes.produitsSelectionnables = await this.CentreGestionService.getProduitsSelectionnables(this.filterToBack);
						elementsSelectionnablesOrdonnes = this.$filter('orderBy')(this.listes.produitsSelectionnables, 'producteurProduitLibelle');
						this.listes.produitsLies = await this.prepareListeElementsLies(elementsSelectionnablesOrdonnes);
						this.listes.produitsLies = this.formatProduitLiesForMassiaMultiSelect();
						break;
					case 'transportsLies':
						// la récupération via getPrestationsSelectionnables() se fait maintenant dans le parent car utilisé dans la liste des Taux de TVA
						this.listes.transportsSelectionnables = await this.CentreGestionService.getTransportsSelectionnables(this.filterToBack);
						elementsSelectionnablesOrdonnes = this.$filter('orderBy')(this.listes.transportsSelectionnables, 'transportLibelle');
						this.listes.transportsLies = await this.prepareListeElementsLies(elementsSelectionnablesOrdonnes);
						break;
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				switch (codeParametre) {
					case 'produitsLies':
						this.setSelection(this.listes.produitsLies, this.parametre);
						break;
					case 'transportsLies':
						this.setSelection(this.listes.transportsLies, this.parametre);
						break;
				}
			}
		}
	}
	//endregion

	setSelection(listeSelectionnables, parametre) {
		// INFOS Entrées ET sorties
		for (let es = 0; es < 2; es++) {
			// SL si on a des valeurs propres, on gère le précochage du iSteven
			const nombreValeurs = parametre.donnees[es].valeurs.length;
			for (let i = 0; i < nombreValeurs; i++) {
				const selected = parametre.donnees[es].valeurs[i];
				const index = this._.findIndex(listeSelectionnables[es], this.getSearchFor(selected));

				if (index != -1) {
					if (parametre.donnees[es].parent == null) {
						// valeurs propres
						listeSelectionnables[es][index].valeur = true;
						listeSelectionnables[es][index].id = selected.id;
					} else {
						// valeurs héritées
						// listeSelectionnables[es][index].disabled = true;
						listeSelectionnables[es][index].valeur = true;
						listeSelectionnables[es][index].isHerited = true;
					}
					listeSelectionnables[es][index].disabled = selected.isHerited;
				}
			}
		}
	}

	getSearchFor(selected) {
		switch (this.codeParametre) {
			case 'prestationsLiees':
				return {
					prestationId: selected.prestationId,
					entreeSortie: selected.entreeSortie
				};
			case 'produitsLies':
				return {
					producteurId: selected.producteurId,
					produitId: selected.produitId,
					entreeSortie: selected.entreeSortie
				};
			case 'transportsLies':
				return {
					transportId: selected.transportId,
					entreeSortie: selected.entreeSortie
				};
		}
	}
	goodIndex(data, selectionnables, es) {
		switch (selectionnables) {
			case 'prestationsLiees':
				return _.findIndex(this.listes[selectionnables][es], {
					prestationId: data.prestationId
				});
			case 'produitsLies':
				return _.findIndex(this.listes[selectionnables][es], {
					producteurId: data.producteurId,
					produitId: data.produitId
				});
			case 'transportsLies':
				return _.findIndex(this.listes[selectionnables][es], {
					transportId: data.transportId
				});
		}
	}

	// buildFilters() {
	//     var filtre = {};
	//     for (var domaine in this.frontFilters) {
	//         if (this.frontFilters[domaine] != null) {
	//             filtre[this.getProperty(domaine)] = Number(this.frontFilters[domaine].id);
	//         }
	//     }
	//     return filtre
	// }

	async refreshLabels() {
		if (this.codeParametre == 'transportsLies' || this.codeParametre == 'produitsLies') {
			if (this.selectedElms[0].length == 0 && this.changedValue[0]) this.changedValue[0] = false;
			if (this.selectedElms[1].length == 0 && this.changedValue[1]) this.changedValue[1] = false;
			let newParameters = await this.CentreGestionService.getParametersWithFilter(this.domaine, this.source.id, this.filterToBack);
			let factuParam = null;
			for (let index = 0; index < newParameters.length; index++) {
				const param = newParameters[index];
				factuParam = param.parametres.find((p) => p.codeParametre == this.codeParametre && p.id == this.parametre.id);
				if (factuParam) break;
			}
			if (factuParam) this.parametre = factuParam;
			await this.prepareView();
		}
	}

	/**
	 *  INFOS  Gère l'enregistrement des produits
	 * en cas de selection
	 */
	async istevenOnClick(data, selectionnables, es) {
		// si on coche un producteur
		if (data.msGroup) {
			// on coche tous les produits du producteur
		}

		this.startLoading(es);
		const index = this.goodIndex(data, selectionnables, es);

		if (this.filterToBack) {
			const entries = Object.entries(this.filterToBack);
			for (let i = 0; i < entries.length; i++) {
				data[entries[i][0]] = entries[i][1];
			}
		}
		data.codeDomaine = this.domaine;
		data.entreeSortie = es;

		// clone du data (pas une référence) car ayant toutes les données du filtre
		const backUpData = Object.assign({}, data);

		// si on coche une ligne
		if (index != -1) {
			if (data.valeur == true) {
				// si on coche on enregistre l'élément
				try {
					const prop = this.getProperty(this.domaine);
					data[prop] = this.source.id;

					const retour = await this.CentreGestionService.insert(data);
					this.listes[selectionnables][es][index].id = retour;
				} catch (err) {
					if (err.data) {
						this.notification.error(err.data);
					} else {
						throw err;
					}
				} finally {
					this.changedValue[es] = true;
					this.stopLoading(es);
				}
			} else {
				// si on décoche, on supprime l'élément
				if (data.isHerited == false) {
					// à condition qu'il ne soit pas hérité
					try {
						this.retour = await this.CentreGestionService.effacer(data.id);
					} catch (err) {
						if (err.data) {
							this.notification.error(err.data);
						} else {
							throw err;
						}
					} finally {
						// this.listes[selectionnables][es][index].valeur = false;
						this.changedValue[es] = true;
					}
				} else {
					this.changedValue[es] = true;
				}
			}
		} else {
			//TODO - La sélection par producteur n'est plus disponoble avec le massia multi select. Mais il faudra vérifier cette partie si ça revient
			// si on coche un producteur
			/*for (let i = 0; i < this.listes[selectionnables][es].length; i++) {
				const element = this.listes[selectionnables][es][i];
				if (element.valeur == true && element.id == null) {
					// en fonction du type d'élément, on met à jour les bons Ids
					if (element.elementCode == 'prodLie') {
						backUpData.produitId = element.produitId;
						backUpData.producteurId = element.producteurId;
					} else if (element.elementCode == 'transpLie') {
						backUpData.transportId = element.transportId;
					}

					// on enregistre la ligne
					const retourId = await this.CentreGestionService.insert(backUpData);
					this.listes[selectionnables][es][i].id = retourId;
				}
			}*/
		}

		// enregistrer les éléments hérités au niveau actuel ssi c'est les produits liés
		if (selectionnables == 'produitsLies') {
			await this.saveHeritedLines(backUpData, selectionnables, es);
		} else {
			// si c'est les transports liés, on rafraichit l'affichage
			for (let i = 0; i < this.listes[selectionnables][es].length; i++) {
				const element = this.listes[selectionnables][es][i];
				if (element.isHerited == true) {
					// si valeurs héritées - On désactive l'héritage pour rendre possible à resélectionner
					if (element.valeur == true) {
						element.valeur = false;
						element.isHerited = false;
					}
				}
			}
		}
		await this.refreshLabels();
	}

	async saveHeritedLines(backUpData, selectionnables, es) {
		// SL cas d'une liste avec que des valeurs héritée
		// si on coche un nouveau produit, on enregistre aussi les produits grisés, issus de l'héritage du niveau supérieur
		// si on décoche un produit, on enregistre les autres produits grisés, issus de l'héritage du niveau supérieur
		let elmToSave = [];
		const dataToAdd = {};

		if (this.filterToBack) {
			const entries = Object.entries(this.filterToBack);
			for (var i = 0; i < entries.length; i++) {
				dataToAdd[entries[i][0]] = entries[i][1];
			}
		}
		elmToSave.push(dataToAdd);

		for (let i = 0; i < this.listes[selectionnables][es].length; i++) {
			const element = this.listes[selectionnables][es][i];
			if (element.isHerited == true) {
				if (element.valeur == true && element.id == null) {
					elmToSave.push(element);
				}
			}
		}
		if (elmToSave.length > 1) {
			const closePopupButton = $('#multiSelectCloseButton');
			if (closePopupButton.length > 0) closePopupButton[0].click(); //On ferme la popup pendant le traitement
			//Note : ça peut être très long selon le nombre de produits du parent...
			if (elmToSave.length > 30) {
				this.savingElements = true;
			}
			await this.CentreGestionService.insertMultiple(elmToSave);
			if (elmToSave.length > 30) {
				this.savingElements = false;
			}
			this.onUpdate(); //Force un refresh pour passer sur les éléments propres et plus les éléments hérités
		}
	}

	async saveAllItem(selectionnables, es) {
		const elmToSave = [];
		const dataToAdd = {};
		let cptOK = 0;

		if (this.filterToBack) {
			const entries = Object.entries(this.filterToBack);
			for (var i = 0; i < entries.length; i++) {
				dataToAdd[entries[i][0]] = entries[i][1];
			}
		}
		elmToSave.push(dataToAdd);

		for (let i = 0; i < this.listes[selectionnables][es].length; i++) {
			const element = this.listes[selectionnables][es][i];

			if (element.msGroup == true || element.msGroup == false) {
				// on ne prend pas la ligne
			} else {
				//id != null implique que l'élément est déjà une valeur propre ou héritée
				if (element.id == null && element.valeur) {
					const prop = this.getProperty(this.domaine);
					element[prop] = this.source.id;
					element.codeDomaine = this.domaine;
					if (this.frontFilters && this.frontFilters.typeBenne) element.typeBenneId = this.frontFilters.typeBenne.id;
					elmToSave.push(element);
				}
			}
		}

		if (elmToSave.length < 2)
			//Le premier n'est pas un élément mais des infos de liens. S'il est seul il n'y a pas nécessité de sauvegarde
			return;

		try {
			if (elmToSave.length > 30) {
				this.savingElements = true;
			}
			await this.CentreGestionService.insertMultiple(elmToSave);
			this.savingElements = false;
			cptOK = elmToSave.length;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.notification.success(this.$translate.instant('CENTRE_GESTION.MESSAGES.SAVED', { nombre: cptOK }));
		}
		this.onUpdate();
	}

	getElement(element, selectionnables, es) {
		let elm = null;
		switch (selectionnables) {
			case 'prestationsLiees':
				elm = this._.find(this.listes[selectionnables][es], {
					prestationId: element.prestationId
				});
				break;
			case 'produitsLies':
				elm = this._.find(this.listes[selectionnables][es], {
					producteurId: element.producteurId,
					produitId: element.produitId
				});
				break;
			case 'transportsLies':
				elm = this._.find(this.listes[selectionnables][es], {
					transportId: element.transportId
				});
				break;
			default:
		}
		return elm;
	}

	getStatusHeritage(parametre, es) {
		if (typeof parametre.donnees[es] !== 'undefined' && this.changedValue[es] == false) {
			const parentElm = parametre.donnees[es].parent;
			if (parentElm == null) {
				return 1;
			} else if (parentElm != null && parentElm.idParent == -1) {
				return 0;
			} else if (parentElm.parentLibelle == 'MULTIPLE' && parentElm.idParent == 0) {
				return this.$translate.instant('CENTRE_GESTION.HERITAGE_MULTIPLE');
			}
			return this.$translate.instant('CENTRE_GESTION.HERITAGE', { libelle: parentElm.parentLibelle });
		} else if (typeof parametre.donnees[es] !== 'undefined' && this.changedValue[es] == true) {
			return 1;
		}
		return 0;
	}

	async upsertElement(objet, es, groupIndex) {
		let element = null;
		let parent = null;
		if (this.parametre.donnees.length > 1) {
			element = this.parametre.donnees[es].valeurs;
			parent = this.parametre.donnees[es].parent;
		} else {
			element = this.parametre.donnees[0].valeurs;
			parent = this.parametre.donnees[0].parent;
		}

		for (let i = 0; i < element.length; i++) {
			const valeur = element[i];
			if (this.frontFilters) {
				// this.monFiltre = {};
				// var pourFiltre = this.getPropFiltre(this.frontFilters);
				// valeur[this.getProperty(pourFiltre.domaine)] = pourFiltre.id;

				if (this.filterToBack) {
					const entries = Object.entries(this.filterToBack);
					for (let ix = 0; ix < entries.length; ix++) {
						element[entries[ix][0]] = entries[ix][1];
					}
				}
				// element.dateApplication = this.dateApplication;
				element.codeDomaine = this.domaine;
			}
			try {
				if (this.domaine == 'SiteUtil') {
					valeur.societeId = null;
				} else if (this.domaine == 'Chantiers') {
					valeur.clientId = null;
				}
				const prop = this.getProperty(this.domaine);
				valeur[prop] = this.source.id;

				if (parent != null) {
					valeur.id = null;
					const nouvelId = await this.CentreGestionService.insert(valeur);
					valeur.id = nouvelId;
					objet.parent = null;
				} else {
					// TODO-EFFACER Effacer le console.log
					//console.log('Should update', parent, valeur);
					// this.retour = await this.CentreGestionService.update(element);
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.changedValue[es] = true;
				this.activeTab = groupIndex;
			}
		}
	}

	// c'est la touche GOMME / DéselectionnerTout
	async reinit(selectionnables, es, fromEraser = false) {
		const elmToDelete = [];
		for (let i = 0; i < this.listes[selectionnables][es].length; i++) {
			const element = this.listes[selectionnables][es][i];

			// on vérifie que ce sont bien des valeurs propres
			// et du coup, on peut supprimer toutes les lignes cochées
			// if (element.valeur == true && this.parametre.donnees[es].parent == null) {
			// quand on clique sur le "DéselectionnerTout", element.valeur se met à false direct avant d'appeler la méthode reinit

			// la mécanique d'affichage a changé donc on peut se contenter de tester s'il y a un Id
			if (element.id != null && (fromEraser || !element.valeur)) {
				//eraser || !valeur permet de différencier la gomme et le removeAll de la popup
				const elm = this.getElement(element, selectionnables, es);
				element.valeur = false;
				elm.valeur = false;
				elmToDelete.push(elm.id);
			}
		}

		try {
			await this.CentreGestionService.deleteMultiple(elmToDelete);
			this.onDelete();
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			// this.onDelete();
		}
	}
	getPropFiltre(filtres) {
		const keyNames = Object.keys(filtres);
		let id;
		for (let i = 0; i < keyNames.length; i++) {
			switch (keyNames[i]) {
				case 'SiteCommercial':
				case 'siteCommercial':
					if (this.frontFilters.siteCommercial) {
						this.domaineFiltre = 'SiteCommercial';
						id = this.frontFilters.siteCommercial.id;
					}
					break;
				case 'Societes':
				case 'societes':
					if (this.frontFilters.societes) {
						this.domaineFiltre = 'Societes';
						id = this.frontFilters.societes.id;
					}
					break;
				case 'siteTransporteur':
					// this.domaineFiltre = "SiteTransporteur";
					// var id = this.frontFilters.siteTransporteur.id
					break;
				case 'siteClient':
					if (this.frontFilters.siteClient) {
						this.domaineFiltre = 'Client';
						id = this.frontFilters.siteClient.id;
					}
					break;
				case 'typeClient':
					// this.domaineFiltre = "";
					// var id = this.frontFilters.typeClient
					break;
				case 'chantier':
					if (this.frontFilters.chantier) {
						this.domaineFiltre = 'Chantier';
						id = this.frontFilters.chantier.id;
					}
					break;
				default:
				// this.domaineFiltre = "";
				// var id = this.frontFilters.dateApplication
			}
		}
		return {
			domaine: this.domaineFiltre,
			id: id
		};
	}
	getProperty(domaine) {
		switch (domaine) {
			case 'Societes':
			case 'societes':
				return 'societeId';
			case 'SiteCommercial':
			case 'siteCommercial':
			case 'SiteUtil':
				return 'siteCommercialId';
			case 'chantier':
			case 'chantiers':
			case 'Chantiers':
				return 'chantierId';
			case 'Produits':
				return 'produitId';
			case 'SiteClient':
				return 'clientId';
			case 'Pays':
				return 'paysId';
		}
	}
	startLoading() {
		this.loading = true;
	}
	stopLoading() {
		this.loading = false;
	}
	startCodeLoading() {
		this.codeLoading = true;
	}
	stopCodeLoading() {
		this.codeLoading = false;
	}
}
