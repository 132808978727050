import Chantier from './chantier.model';

export default class ChantierFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'ChantiersCommunicationService',
		'ChantiersService',
		'notification',
		'$location',
		'$anchorScroll',
		'$uibModal',
		'RouterHistoryService',
		'MassiaApplicationService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		ChantiersCommunicationService,
		ChantiersService,
		notification,
		$location,
		$anchorScroll,
		$uibModal,
		RouterHistoryService,
		MassiaApplicationService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ChantiersCommunicationService = ChantiersCommunicationService;
		this.ChantiersService = ChantiersService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$uibModal = $uibModal;
		this.RouterHistory = RouterHistoryService;
		this.MassiaApplicationService = MassiaApplicationService;
		this.fromDap = false;
		this.dap = {};
	}

	$onInit() {
		this.isChantier = this.$stateParams.genre === 'chantiers';
		this.chantier = {};
		this.loading = false;
		this.form = {
			entete: {},
			caracteristiques: {}
		};

		// Configuration
		this.fromDap = this.$stateParams.fromDap;

		if (this.fromDap) {
			this.ongletOpen = {
				isEnteteOpen: true,
				isCaracteristiqueOpen: false
			};

			this.dap = this.$stateParams.dap;
			const chantier = this.dap.chantier;
			console.log('🚀 ~ ChantierFormController ~ $onInit ~ chantier:', chantier);
			console.log('CHANTIER DAP', this.dap);
			this.chantier.entete = chantier;
			// Transformation des données de la DAP Courte pour le chantier
			switch (chantier.contaminationChantier) {
				case 2:
					this.chantier.entete.isContamine = true;
					break;
				case 1:
					this.chantier.entete.isPotentielContamine = true;
					break;
				default:
					this.chantier.entete.isContamine = false;
					this.chantier.entete.isPotentielContamine = false;
			}

			this.chantier.entete.isDuBatiment = chantier.isChantierDuBatiment;
			if (this.chantier.entete.isDuBatiment) {
				this.chantier.entete.isHeritageFromClient = false;
			}
			this.chantier.entete.isReferenceBasias = chantier.isChantierReferenceBasias;
			this.chantier.entete.isReferenceBasol = chantier.isChantierReferenceBasol;
			this.chantier.entete.idSite = this.dap.demandeurId;
			this.chantier.entete.nomSite = this.dap.siteDemandeur.nomSociete;
			this.chantier.entete.idLieu = chantier.lieu?.id || null;
			this.chantier.entete.dateDebut = this.dap.chantier.dateDebutLivraison.substring(0, 10);
			console.log(this.chantier.entete.dateDebut);
		} else {
			this.reset();
		}

		console.log(this.$state);
	}

	open(domaine) {
		const _this = this;
		_this.source = this.chantier;
		_this.domaine = domaine;
		this.$uibModal
			.open({
				template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.domaine;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			});
	}

	openTarifs() {
		const _this = this;
		_this.source = this.chantier;
		//_this.domaine = domaine;
		this.$uibModal
			.open({
				template: '<tarifs source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></tarifs>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.isChantier ? 'chantier' : 'provenance';
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			});
	}

	openGrilles() {
		const _this = this;
		_this.source = this.chantier;
		//_this.domaine = domaine;
		this.$uibModal
			.open({
				template: '<grilles source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></grilles>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.isChantier ? 'chantier' : 'provenance';
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			});
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateChantierTimeout);
	}

	async reset() {
		this.startLoading();

		const data = {
			id: this.idChantier ? this.idChantier : this.$stateParams.id
		};

		if (data.id) {
			try {
				data.entete = await this.ChantiersService.getChantierEnteteById(data.id);
				console.log('🚀 ~ file: chantier.form.controller.js:164 ~ ChantierFormController ~ reset ~ data.entete:', data.entete);
				data.caracteristiques = await this.ChantiersService.getChantierValeursCaracteristiquesAssociationsById(data.id);
			} catch (ex) {
				this.notification.error(ex.data);
				this.annuler();
			}
		}

		// SL cree l objet Chantier lui meme creant les objets
		// ChantierEntete, ChantierCaracteristiques
		this.chantier = new Chantier(data);

		if (this.$stateParams.duplicate) {
			this.chantier.id = undefined;
			this.chantier.entete.code += '_copie';
		}

		this.ongletOpen = {
			isEnteteOpen: true,
			isCaracteristiqueOpen: false
		};

		// Si la creation est faite à partir de la transformation en commande d'une proposition on fige le client et le lieu
		if (this.$stateParams.clientId) {
			const client = await this.ChantiersService.getClientById(this.$stateParams.clientId);
			this.chantier.entete.objetSiteClient = client;
		}

		if (this.$stateParams.lieuId) {
			this.chantier.entete.idLieu = this.$stateParams.lieuId;
		}

		if (this.$stateParams.propositionId) {
			const proposition = await this.ChantiersService.getProposition(this.$stateParams.propositionId);
			this.chantier.entete.dateDebut = proposition.offre.dateDebutLivraison;
			this.chantier.entete.dateFin = proposition.offre.dateFinLivraison;
			this.chantier.entete.libelle = proposition.offre.designationChantier;
			this.chantier.entete.adresse = proposition.offre.adresse;
			this.chantier.entete.codePostal = proposition.offre.codePostal;
			this.chantier.entete.ville = proposition.offre.ville;
			this.chantier.entete.idPays = proposition.offre.idPays;
			this.chantier.entete.referenceChantierClient = proposition.offre.referenceChantier;
			this.chantier.entete.idContact = proposition.offre.idContact;
			this.chantier.entete.commentaireLivraison = proposition.commentaire;
			this.chantier.entete.idLieu = proposition.idLieu;
			this.chantier.entete.codeLieu = proposition.codeLieu;
			this.chantier.entete.nomLieu = proposition.nomLieu;
		}

		this.$timeout.cancel(this.updateChantierTimeout);
		this.updateChantierTimeout = this.$timeout(() => this.stopLoading());
	}

	async sauvegarder() {
		if (this.checkValidity()) {
			if (
				this.chantier.entete.objetSiteClient == null ||
				this.chantier.entete.objetSiteClient == '' ||
				!this.chantier.entete.objetSiteClient.id
			) {
				this.notification.error('CHANTIERS.NOCLIENT');
				return null;
			}

			//On a un objetSociete mal paramétré, on le reset
			if (this.chantier.entete.objetSociete && (!this.chantier.entete.objetSociete.id || this.chantier.entete.objetSociete.id == 0)) {
				this.chantier.entete.nomSociete = null;
				this.chantier.entete.objetSociete = null;
			}
			this.startLoading();
			try {
				let id = false;
				if (this.chantier.id) {
					await this.ChantiersService.updateChantier(this.chantier);
					this.notification.success(this.isChantier ? 'CHANTIERS.UPDATED' : 'CHANTIERS.UPDATED_PROV');
					id = this.chantier.id;
				} else {
					id = await this.ChantiersService.createChantier(this.chantier);
					this.notification.success(this.isChantier ? 'CHANTIERS.CREATED' : 'CHANTIERS.CREATED_PROV');
				}

				if (this.fromDap) {
					this.retourDap(id);
					return;
				}

				return id;
			} catch (ex) {
				console.log('🚀 ~ file: chantier.form.controller.js:238 ~ ChantierFormController ~ sauvegarder ~ ex:', ex);
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
		}
	}

	async retourDap(id) {
		console.log('test id before navigate back ', id);
		if (this.RouterHistory && this.RouterHistory.history && this.RouterHistory.history.length > 0) {
			console.log('GOOOOOOOOOOOOOOOOOOOOOOOOO', id);
			this.RouterHistory.back({ chantierId: id });
		}
	}

	async appliquer() {
		const id = await this.sauvegarder();

		if (id && this.chantier.id) {
			this.reset();
		} else if (id) {
			if (this.isChantier) {
				this.$state.go('chantiers.edit', { id: id });
			} else {
				this.$state.go('provenances.edit', { id: id });
			}
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			this.newChantierId = success;
			this.annuler();
		}
	}

	async annuler() {
		this.backToPreviousState();
	}

	backToPreviousState() {
		// Differentes pages de retour possibles.
		// Si la creation du chantier est fait elors de la transformation en commande d'une proposition
		if (this.$stateParams.parentState == 'offres.commande') {
			const params = this.RouterHistory.history[0].params;
			const personneId = params.personneId ? params.personneId : '';
			this.$state.go('offres.commande', {
				id: this.$stateParams.propositionId,
				personneId: personneId,
				clientId: this.chantier.entete.objetSiteClient.id,
				chantierId: this.newChantierId
			});
		} else {
			// Sinon le cas général la liste des chantiers
			if (this.isChantier) {
				this.$state.go('chantiers.list');
			} else {
				this.$state.go('provenances.list');
			}
		}
	}

	checkValidity() {
		let validity = true;
		//on va seulement scroller et focus sur le premier onglet où il y a une erreur
		let firstScroll = true;
		if (!this.chantier.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('chantierEnteteValidations');
		}

		if (!this.chantier.caracteristiques.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiques');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueOpen = true;
			validity = false;
			this.$scope.$broadcast('chantierCaracteristiquesValidations');
		}

		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	onUpdateEntete(entete) {
		this.chantier.entete = entete;
	}

	onUpdateCaracteristiques(caracteristiques) {
		this.chantier.caracteristiques = caracteristiques;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
