import validatorBuilder from 'validator-builder';

let offreValidationParametersValidator = null;

export default class OffreValidationParameters {
	constructor(data) {
		data = data || {};

		this.idSociete = data.idSociete || null;
		this.idDomaine = data.idDomaine || null;
		this.message = data.message || null;
		this.smtpLogin = data.smtpLogin || null;
		this.smtpPass = data.smtpPass || null;
		this.smtpServer = data.smtpServer || null;
		this.smtpPort = data.smtpPort || 0;
		this.smtpAlias = data.smtpAlias || null;
		this.smtpIsSslConnection = data.smtpIsSslConnection || false;
	}

	isValid() {
		offreValidationParametersValidator = offreValidationParametersValidator || validatorBuilder.getInstanceFor('OffreValidationParameters');

		const validationResults = offreValidationParametersValidator.validate(this);

		return validationResults.isValid;
	}
}
