import saveAsTemplate from './save.as.modal.tpl.html';

export class SaveAsModalController {
	/* @ngInject */
	constructor($scope, $translate, $state, $stateParams, $timeout, SyntheseEntityService, notification) {
		this.$scope = $scope;
		this.$translate = $translate;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$timeout = $timeout;
		this.SyntheseEntityService = SyntheseEntityService;
		this.notification = notification;
	}
	$onInit() {
		this.libelle = this.resolve?.libelle + '_copy';
		this.code = this.resolve?.code + '_copy';
		if (this.code.length > 15) this.code = this.code.slice(0, 15);
		this.type = this.resolve?.type;
	}

	async valider() {
		let success = true;
		if (!this.checkCode()) {
			success = false;
			this.notification.error('CODE_CHARACTERS_NOK', null, {
				timeOut: 2000,
				extendedTimeOut: 0,
				closeButton: false
			});
		} else if (!(await this.checkCodeUnicity())) {
			success = false;
			this.notification.erro;
			this.notification.error('CODE_UNIQUE_NOK', null, {
				timeOut: 2000,
				extendedTimeOut: 0,
				closeButton: false
			});
		} else if (!this.libelle) {
			success = false;
			this.notification.erro;
			this.notification.error('LIBELLE_EMPTY_NOK', null, {
				timeOut: 2000,
				extendedTimeOut: 0,
				closeButton: false
			});
		}

		if (success) {
			this.modalInstance.close({
				libelle: this.libelle,
				code: this.code
			});
		}
	}
	cancel() {
		this.modalInstance.dismiss();
	}

	checkCode() {
		return this.code.match(/^[a-zA-Z0-9_]*$/);
	}

	async checkCodeUnicity() {
		const filtre = [{ criterion: 'code', value: this.code, type: 'Contains' }];
		const synths = await this.SyntheseEntityService.getAll(this.type, filtre);
		let isUnique = true;
		for (let index = 0; index < synths.items.length; index++) {
			const synth = synths.items[index];
			if (synth.code.toLowerCase() == this.code.toLowerCase()) {
				isUnique = false;
				break;
			}
		}
		return isUnique;
	}
}
export default {
	bindings: {
		modalInstance: '=',
		resolve: '='
	},
	controller: SaveAsModalController,
	template: saveAsTemplate
};
