import * as _ from 'lodash';

const serviceUrl = `${__configuration.apiUrl}/massia/sites`;

export default class SitesService {
	static $inject = ['$http', '$translate', 'TypesNiveauxHierarchique'];

	constructor($http, $translate, TypesNiveauxHierarchique) {
		this.$http = $http;
		this.$translate = $translate;
		this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
	}

	async getSites(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
			}
		});

		return res.data;
	}

	async getSitesCommerciaux(filters, sorts, pagination) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getSitesClients(filters, sorts, pagination, isFiltered) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 }),
				isFiltered: JSON.stringify(isFiltered || false)
			}
		});

		return res.data;
	}

	async getSitesProducteurs(application, filters, sorts, pagination, isFiltered) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-producteurs/by-app`, {
			params: {
				application: application,
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 }),
				isFiltered: JSON.stringify(isFiltered || false)
			}
		});

		return res.data;
	}

	async getSitesTransporteurs(filters, sorts, pagination, isFiltered) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-transporteurs`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				isFiltered: JSON.stringify(isFiltered || false)
			}
		});

		return res.data;
	}

	async getSitesFournisseurs(filters, sorts, pagination, isFiltered) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-fournisseurs`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				isFiltered: JSON.stringify(isFiltered || false)
			}
		});

		return res.data;
	}

	async getSitesLaboratoires(filters, sorts, pagination) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-laboratoires`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
			}
		});

		return res.data;
	}

	async getSitesProprietaires(filters, sorts, pagination) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-proprietaires`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getSitesLocataires(filters, sorts, pagination) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-locataires`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getSitesAuditeurs(filters, sorts, pagination) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-auditeurs`, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
			}
		});

		return res.data;
	}

	// SL get permettant de remplir les combobox
	async getSitesHeaders() {
		const url = `${serviceUrl}/headers`;
		const res = await this.$http.get(url);
		return res.data;
	}

	// SL/NG get permettant d avoir la liste des sites par societe
	async getSitesBySocieteHeaders(id) {
		const url = `${serviceUrl}/header/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async deleteSiteById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getSiteEnteteById(id, genre, application) {
		const url = `${serviceUrl}/${id}/entete/genre/${genre}/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSiteObjectifById(id) {
		const url = `${serviceUrl}/${id}/objectif`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSiteIndicateurById(id) {
		const url = `${serviceUrl}/${id}/indicateur`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSiteValeursCaracteristiquesUtilisateurAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-utilisateur`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesUtilisateurById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesUtilisateurById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-utilisateur`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesClientAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-client`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesClientById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesClientById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-client`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesFournisseurAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-fournisseur`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesFournisseurById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesFournisseurById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-fournisseur`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesProducteurAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-producteur`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesProducteurById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesProducteurById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-producteur`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesTransporteurAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-transporteur`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesTransporteurById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesTransporteurById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-transporteur`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesLaboratoireAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-laboratoire`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesLaboratoireById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesLaboratoireById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-laboratoire`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesProprietaireAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-proprietaire`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesProprietaireById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesProprietaireById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-proprietaire`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesLocataireAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-locataire`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesLocataireById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesLocataireById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-locataire`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async getSiteValeursCaracteristiquesAuditeurAssociationsById(id) {
		/*let url = `${serviceUrl}/${id}/valeurs-caracteristiques-auditeur`;
        let res = await this.$http.get(url);
        return res.data;*/
		const data = await this.getSiteValeursCaracteristiquesAuditeurById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSiteValeursCaracteristiquesAuditeurById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques-auditeur`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	async createSite(site, application) {
		site.entete.inertes = site.inertes;
		site.entete.zoneStockages = site.zoneStockages;
		site.entete.codeReference = site.codeReference;
		const data = {
			entete: site.entete,
			objectif: site.objectif,
			indicateur: site.indicateur,
			caracteristiquesUtilisateur: [],
			caracteristiquesClient: [],
			caracteristiquesFournisseur: [],
			caracteristiquesProducteur: [],
			caracteristiquesTransporteur: [],
			caracteristiquesLaboratoire: [],
			caracteristiquesProprietaire: [],
			caracteristiquesLocataire: [],
			caracteristiquesAuditeur: [],
			niveaux: site.niveaux,
			application: application,
			certificationCe: site.certificationCe,
			configurationBalance: null
		};
		data.caracteristiquesUtilisateur = this.formatCaracteristiques(site.caracteristiquesUtilisateur);
		data.caracteristiquesClient = this.formatCaracteristiques(site.caracteristiquesClient);
		data.caracteristiquesProducteur = this.formatCaracteristiques(site.caracteristiquesProducteur);
		data.caracteristiquesFournisseur = this.formatCaracteristiques(site.caracteristiquesFournisseur);
		data.caracteristiquesTransporteur = this.formatCaracteristiques(site.caracteristiquesTransporteur);
		data.caracteristiquesLaboratoire = this.formatCaracteristiques(site.caracteristiquesLaboratoire);
		data.caracteristiquesProprietaire = this.formatCaracteristiques(site.caracteristiquesProprietaire);
		data.caracteristiquesLocataire = this.formatCaracteristiques(site.caracteristiquesLocataire);
		data.caracteristiquesAuditeur = this.formatCaracteristiques(site.caracteristiquesAuditeur);
		data.configurationBalance = site.configurationBalance;
		const result = await this.$http.post(serviceUrl, data);
		return result.data;
	}

	async updateSite(site, application) {
		site.entete.inertes = site.inertes;
		site.entete.zoneStockages = site.zoneStockages;
		site.entete.codeReference = site.codeReference;
		const data = {
			application: application,
			entete: site.entete,
			objectif: site.objectif,
			indicateur: site.indicateur,
			caracteristiquesUtilisateur: [],
			caracteristiquesClient: [],
			caracteristiquesFournisseur: [],
			caracteristiquesProducteur: [],
			caracteristiquesTransporteur: [],
			caracteristiquesLaboratoire: [],
			caracteristiquesProprietaire: [],
			caracteristiquesLocataire: [],
			caracteristiquesAuditeur: [],
			niveaux: site.niveaux,
			etatCompteSite: site.etatCompteSite,
			genreSite: site.genreSite,
			certificationCe: site.certificationCe,
			configurationBalance: null
		};
		data.caracteristiquesUtilisateur = this.formatCaracteristiques(site.caracteristiquesUtilisateur);
		data.caracteristiquesClient = this.formatCaracteristiques(site.caracteristiquesClient);
		data.caracteristiquesProducteur = this.formatCaracteristiques(site.caracteristiquesProducteur);
		data.caracteristiquesFournisseur = this.formatCaracteristiques(site.caracteristiquesFournisseur);
		data.caracteristiquesTransporteur = this.formatCaracteristiques(site.caracteristiquesTransporteur);
		data.caracteristiquesLaboratoire = this.formatCaracteristiques(site.caracteristiquesLaboratoire);
		data.caracteristiquesProprietaire = this.formatCaracteristiques(site.caracteristiquesProprietaire);
		data.caracteristiquesLocataire = this.formatCaracteristiques(site.caracteristiquesLocataire);
		data.caracteristiquesAuditeur = this.formatCaracteristiques(site.caracteristiquesAuditeur);
		data.configurationBalance = site.configurationBalance;
		const url = `${serviceUrl}/${site.id}`;
		return await this.$http.put(url, data);
	}

	formatCaracteristiques(caracteristiques) {
		const data = [];
		for (const property in caracteristiques) {
			let keyValueObject;
			//On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variable interne Js.
			if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
				const propertyVal = caracteristiques[property];
				if (Array.isArray(propertyVal)) {
					if (_.some(propertyVal, 'key')) {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: _.map(propertyVal, 'key')
						};
					} else {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: propertyVal
						};
					}
				} else {
					keyValueObject = {
						IdCaracteristique: parseInt(property),
						valeur: propertyVal
					};
				}
				data.push(keyValueObject);
			}
		}
		return data;
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getGenreSite() {
		const url = 'assets/enum/sites/genre.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPays() {
		const paysUrl = `${__configuration.apiUrl}/massia/pays`;
		const res = await this.$http.get(paysUrl);

		return res.data;
	}

	async getLieux(valeur, idClient) {
		const lieuxUrl = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(lieuxUrl);
		return res.data;
	}

	async getPieces(clientId, page = -1) {
		const piecesUrl = `${__configuration.apiUrl}/massia/documents/piecesfacturation/client/${clientId}/${page}`;
		const res = await this.$http.get(piecesUrl);
		return res.data;
	}

	async print(name) {
		const url = `${__configuration.apiUrl}/massia/document/print/piecefacturation/${name}`;
		const res = await this.$http.get(url, {
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getSocietes(isUtilisateur, isClient, isProducteur, isTransporteur, isFournisseur, isLaboratoire, isProprietaire, isLocataire, isAuditeur) {
		const url = `${__configuration.apiUrl}/massia/societes/header-genre`;
		const res = await this.$http.get(url, {
			params: {
				IsCommercial: isUtilisateur && isUtilisateur === true ? isUtilisateur : false,
				IsFournisseur: isFournisseur && isFournisseur === true ? isFournisseur : false,
				IsClient: isClient && isClient === true ? isClient : false,
				IsTransporteur: isTransporteur && isTransporteur === true ? isTransporteur : false,
				IsProducteur: isProducteur && isProducteur === true ? isProducteur : false,
				IsLaboratoire: isLaboratoire && isLaboratoire === true ? isLaboratoire : false,
				IsProprietaire: isProprietaire && isProprietaire === true ? isProprietaire : false,
				IsLocataire: isLocataire && isLocataire === true ? isLocataire : false,
				IsAuditeur: isAuditeur && isAuditeur === true ? isAuditeur : false
			}
		});
		return res.data;
	}

	async getProspect(id) {
		const lieuxUrl = `${__configuration.apiUrl}/massia/prospects/${id}/entete`;
		const res = await this.$http.get(lieuxUrl, id);

		return res.data;
	}

	// SL appel des donnees du client divers a transformer
	async getClientDivers(id) {
		const clientDiversUrl = `${__configuration.apiUrl}/massia/clients-divers/${id}/entete`;
		const res = await this.$http.get(clientDiversUrl, id);
		return res.data;
	}

	async getAllClientDivers(filters) {
		const clientDiversUrl = `${__configuration.apiUrl}/massia/clients-divers`;
		const res = await this.$http.get(clientDiversUrl, {
			params: {
				filters: JSON.stringify(filters)
			}
		});
		return res.data.items;
	}

	async getSitesNiveaux() {
		const url = `${__configuration.apiUrl}/massia/domaines/sites/niveaux-hierarchiques`;
		const res = await this.$http.get(url);
		return this.buildSitesNiveaux(res.data);
	}

	//methode de conversion du format retourne par le serveur vers le format attendu par le front
	buildSitesNiveaux(niveaux) {
		let newNiveaux = [];

		if (niveaux && niveaux.length > 0) {
			const self = this;

			newNiveaux = _.map(niveaux, function (niveau) {
				return {
					//le resultat retourne par le serveur ait le type int pour id du niveau,
					//cependant, selon json attendu par le front, il est necessire que cet id soit en format string (pour que le reste puisse fonctionner comme prevu)
					//on convertit donc l'id de int a string ici
					idNiveau: niveau.id.toString(),

					label: niveau.label,
					saisi: niveau.type === self.TypesNiveauxHierarchique.Libre.value,
					sousNiveaux: self.buildSitesNiveaux(niveau.items)
				};
			});
		}

		return newNiveaux;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/sites/sites.elements.json
	async getSitesElements() {
		const url = `${__configuration.apiUrl}/massia/domaines/sites/elements-hierarchiques-by-niveau`;
		const res = await this.$http.get(url);
		return res.data;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/sites/sites.valeurs.json
	async getSiteValeursNiveauxById(id) {
		const url = `${serviceUrl}/${id}/elements-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}
	async getBanques() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/banques/headers`);
		return res.data;
	}
	async getCoordonnees(idSite) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/coordonnees-bancaires-sites/${idSite}`);
		return res.data;
	}
	async persistCoordonnees(data) {
		const res = await this.$http.post(`${__configuration.apiUrl}/massia/coordonnees-bancaires-sites`, data);
		return res.data;
	}
	async deleteCoordonnees(id) {
		const res = await this.$http.delete(`${__configuration.apiUrl}/massia/coordonnees-bancaires-sites/${id}`);
		return res.data;
	}
	async updateEtatComptes(site, application, data) {
		const res = this.$http.put(`${__configuration.apiUrl}/massia/sites/${site}/entete/${application}/comptes`, data);
		return res.data;
	}
	async getAllProduits(id, type) {
		const url = `${__configuration.apiUrl}/massia/produits/producteursProduits`;

		const filters = {
			siteCommercialId: id,
			entreeSortie: type
		};
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || {})
			}
		});

		return res.data;
	}

	async getPersonnes(valeur) {
		const url = `${__configuration.apiUrl}/massia/personnes/header/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPersonneName(id) {
		const url = `${__configuration.apiUrl}/massia/personnes/name/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getGenres() {
		const url = 'assets/enum/societes/genres.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProposition(id) {
		const url = `${__configuration.apiUrl}/massia/propositions/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async deleteProspect(idProspect, idSite) {
		const url = `${__configuration.apiUrl}/massia/prospects/transform-to-client/${idProspect}/${idSite}`;
		return this.$http.delete(url);
	}

	async exportListeSites(modeImpr, siteGenre, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				genre: JSON.stringify(siteGenre),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getQuantiteReel(idSite, annee) {
		const url = `${__configuration.apiUrl}/massia/gestion/inertes/${idSite}/${annee}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async geolocalise(enteteSite) {
		const url = `${__configuration.apiUrl}/massia/sites/${enteteSite.id}/entete/geocode/${enteteSite.adresse}/${enteteSite.ville}/${enteteSite.codePostal}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPlanTopo(idSite) {
		const url = `${__configuration.apiUrl}/massia/qse/plans-topos/${idSite}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getCadastreGeoJSON(numCommune) {
		const url = `assets/cadastre/cadastre-${numCommune}-parcelles.json`;
		try {
			const res = await this.$http.get(url);
			return res.data;
		} catch (ex) {
			return undefined;
		}
	}

	async ouvreComptePourSite(idSiteOrig, idNouvSite, genre) {
		const data = {
			idSiteOrig: idSiteOrig,
			idNouvSite: idNouvSite,
			genre: genre
		};
		const res = await this.$http.post(`${__configuration.apiUrl}/massia/ouverture-compte`, data);
		return res.data;
	}

	async getJourFerieSiteById(idSite) {
		const url = `${__configuration.apiUrl}/massia/sites-jours-feries/${idSite}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async updateJourFerieSite(jferiesite, idSite) {
		const url = `${__configuration.apiUrl}/massia/sites-jours-feries/${idSite}`;
		return this.$http.put(url, jferiesite);
	}

	async getListesJoursFeries() {
		const url = `${__configuration.apiUrl}/massia/jours-feries/headers`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getJourFerieById(id) {
		const url = `${__configuration.apiUrl}/massia/jours-feries/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getContacts() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/personnes/contacts-with-fonction/header`);
		return res.data;
	}

	async getAllSitesAuditeurs() {
		const url = `${__configuration.apiUrl}/massia/sites-auditeurs/all`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getAllSitesLaboratoires() {
		const url = `${__configuration.apiUrl}/massia/sites-laboratoires/all`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSitesLaboratoiresFiltreUser() {
		const url = `${__configuration.apiUrl}/massia/sites-laboratoires/header/currentUser`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getAllSitesProducteurs() {
		const url = `${__configuration.apiUrl}/massia/sites-producteurs/all`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getAllSitesClients() {
		const url = `${__configuration.apiUrl}/massia/sites-clients/all`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProducteurs(idProduit) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites/sites-by-produit/${idProduit}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async setDefaultEtatCompte(idSite, application) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites/${idSite}/entete/${application}`;
		const res = await this.$http.post(siteUrl);
		return res;
	}

	async manageSiteCertifications(siteCertificats, idSite) {
		const url = `${__configuration.apiUrl}/massia/laboratoire/certificat/site/${idSite}`;
		const response = await this.$http.post(url, siteCertificats);
		return response;
	}

	async getFamilles() {
		const familleUrl = `${__configuration.apiUrl}/massia/familles/filtre`;
		const res = await this.$http.get(familleUrl);
		return res.data;
	}

	async getFamillesAll() {
		const familleUrl = `${__configuration.apiUrl}/massia/familles`;
		const res = await this.$http.get(familleUrl);
		return res.data;
	}

	async getSitesProducteursByFamille(application, codeFamille) {
		const url = `${__configuration.apiUrl}/massia/sites-producteurs/by-famille`;
		const res = await this.$http.get(url, {
			params: {
				codeFamille: codeFamille,
				app: application
			}
		});

		return res.data;
	}

	async getSitesProducteursByApplication(application) {
		const themeUrl = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
		const res = await this.$http.get(themeUrl);
		return res.data;
	}

	async getCompteOuvertDefault() {
		const a = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/code`, {
			params: {
				cle: 'BooCptOUvert'
			}
		});
		return a.data;
	}

	async updateCoordonnees(id, data) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/coordonnees-bancaires-sites/${id}`, data);
		return res.data;
	}

	async getControlesFamilleSurface() {
		const res = await this.$http.get('assets/enum/sites/controle.famille.surface.enum.json');
		if (res && res.data && res.data.length > 0) {
			for (let i = 0; i < res.data.length; i++) {
				const data = res.data[i];
				data.libelle = this.$translate.instant(data.translate);
			}
		}
		return res.data;
	}

	async getReglesDateEssai() {
		const res = await this.$http.get('assets/enum/sites/regle.date.essai.enum.json');
		if (res && res.data && res.data.length > 0) {
			for (let i = 0; i < res.data.length; i++) {
				const data = res.data[i];
				data.libelle = this.$translate.instant(data.translate);
			}
		}
		return res.data;
	}

	async getTransporteurInterface(id) {
		const url = `${__configuration.apiUrl}/massia/transporteur-interface/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTypeInterface() {
		const url = 'assets/enum/sites/transporteur.interface.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async createTransporteurInterface(idSite, transporteurInterface) {
		const url = `${__configuration.apiUrl}/massia/transporteur-interface`;
		const data = {
			id: transporteurInterface.id,
			idTransporteur: idSite,
			typeInterface: transporteurInterface.typeInterface,
			urlApi: transporteurInterface.urlApi
		};
		const res = await this.$http.post(url, data);
		return res.data;
	}

	async updateTransporteurInterface(idSite, transporteurInterface) {
		const url = `${__configuration.apiUrl}/massia/transporteur-interface`;
		const data = {
			id: transporteurInterface.id,
			idTransporteur: idSite,
			typeInterface: transporteurInterface.typeInterface,
			urlApi: transporteurInterface.urlApi
		};
		const res = await this.$http.put(url, data);
		return res.data;
	}
}
