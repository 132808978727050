import SiteEntete from './site.entete.model';
import Contact from '../../../../../common/personnes/components/mini-form/contact/mini.form.contact.model';

export default class SiteEnteteController {
	newContact = new Contact();

	/* @ngInject */
	constructor(
		$scope,
		$state,
		$stateParams,
		SitesCommunicationService,
		SitesService,
		SitesTypesService,
		SocietesService,
		ModalService,
		notification,
		MassiaApplicationService,
		$translate,
		_,
		ImagesService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SitesCommunicationService = SitesCommunicationService;
		this.SitesService = SitesService;
		this.SitesTypesService = SitesTypesService;
		this.SocietesService = SocietesService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this._ = _;
		this.ImagesService = ImagesService;
	}

	async $onInit() {
		this.loading = false;

		this.site = this.site || {};
		this.site.entete = new SiteEntete(this.site.entete);
		this.site.entete.appName = this.MassiaApplicationService.getApplication();
		this.entete = this.site.entete;

		if (this.site.id) {
			this.isEditMode = true;
			this.setSiteAsTypeAhead();
		}

		this.typesUtilisateur = this.typesUtilisateur || [];
		this.typesClient = this.typesClient || [];
		this.typesProducteur = this.typesProducteur || [];
		this.typesFournisseur = this.typesFournisseur || [];
		this.typesTransporteur = this.typesTransporteur || [];
		this.typesLaboratoire = this.typesLaboratoire || [];
		this.typesProprietaire = this.typesProprietaire || [];
		this.typesLocataire = this.typesLocataire || [];
		this.typesAuditeur = this.typesAuditeur || [];

		this.unregisterReinit = this.SitesCommunicationService.registerReinit(() => this.initForm());
		//this.initForm();
		this.setSiteAsTypeAhead();
		this.setSiteAsPersonneAhead();

		const application = this.MassiaApplicationService.getApplication();

		if (application == 'gestion') {
			this.listeTypesSite = [
				{
					id: 'isUtilisateur',
					libelle: this.$translate.instant('SOCIETES.ISUTILISATEUR'),
					selected: this.entete.isUtilisateur,
					disabled: this.genre == 'commerciaux',
					tech_id: 1
				},
				{
					id: 'isClient',
					libelle: this.$translate.instant('SOCIETES.ISCLIENT'),
					selected: this.entete.isClient,
					disabled: this.genre == 'clients',
					tech_id: 2
				},
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == 'fournisseurs',
					tech_id: 8
				},
				{
					id: 'isTransporteur',
					libelle: this.$translate.instant('SOCIETES.ISTRANSPORTEUR'),
					selected: this.entete.isTransporteur,
					disabled: this.genre == 'transporteurs',
					tech_id: 16
				},
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == 'producteurs',
					tech_id: 4
				}
			];
		} else if (application == 'qse') {
			this.listeTypesSite = [
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == 'fournisseurs',
					tech_id: 8
				},
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == 'producteurs',
					tech_id: 4
				},
				{
					id: 'isAuditeur',
					libelle: this.$translate.instant('SOCIETES.ISAUDITEUR'),
					selected: this.entete.isAuditeur,
					disabled: this.genre == 'auditeurs',
					tech_id: 256
				},
				{
					id: 'isProprietaire',
					libelle: this.$translate.instant('SOCIETES.ISPROPRIETAIRE'),
					selected: this.entete.isProprietaire,
					disabled: this.genre == 'proprietaires',
					tech_id: 64
				},
				{
					id: 'isLocataire',
					libelle: this.$translate.instant('SOCIETES.ISLOCATAIRE'),
					selected: this.entete.isLocataire,
					disabled: this.genre == 'locataires',
					tech_id: 128
				}
			];
		} else if (application == 'performance') {
			this.listeTypesSite = [
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == 'producteurs',
					tech_id: 4
				},
				{
					id: 'isUtilisateur',
					libelle: this.$translate.instant('SOCIETES.ISUTILISATEUR'),
					selected: this.entete.isUtilisateur,
					disabled: this.genre == 'commerciaux',
					tech_id: 1
				},
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == 'fournisseurs',
					tech_id: 8
				}
			];
		} else if (application == 'laboratoire') {
			this.listeTypesSite = [
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == 'producteurs',
					tech_id: 4
				},
				{
					id: 'isLaboratoire',
					libelle: this.$translate.instant('SOCIETES.ISLABORATOIRE'),
					selected: this.entete.isLaboratoire,
					disabled: this.genre == 'laboratoires',
					tech_id: 32
				},
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == 'fournisseurs',
					tech_id: 8
				},
				{
					id: 'isClient',
					libelle: this.$translate.instant('SOCIETES.ISCLIENT'),
					selected: this.entete.isClient,
					disabled: this.genre == 'clients',
					tech_id: 2
				}
			];
		}

		this.getSocietes();

		if (this.MassiaApplicationService.getApplication() == 'laboratoire') {
			this.loadImage();
		}

		await this.getClientDivers(this.site.id);
		await this.getNiveauVisibiliteAuditeur();
		await this.getNiveauVisibiliteClient();
		await this.getNiveauVisibiliteCommercial();
		await this.getNiveauVisibiliteFournisseur();
		await this.getNiveauVisibiliteLaboratoire();
		await this.getNiveauVisibiliteLocataire();
		await this.getNiveauVisibiliteProducteur();
		await this.getNiveauVisibiliteProprietaire();
		await this.getNiveauVisibiliteTransporteur();
		await this.initiateContacts();
		await this.initiateFamilles();
	}

	async loadImage() {
		try {
			const data = await this.ImagesService.getImages([], [], { skip: 0, take: 2000 });
			this.imageList = data.items;
			this.showImage();
		} catch (error) {
			console.error(error);
		}
	}

	async showImage(idLogo = 0) {
		const id = this.entete.imageId ? this.entete.imageId : idLogo;
		if (id) {
			try {
				const data = await this.ImagesService.getShowImageById(id);
				this.image = data;
			} catch (error) {
				console.error(error);
			}
		}
	}

	async getNiveauVisibiliteAuditeur() {
		if (this.entete.isAuditeur && this.entete.typeAuditeurId > 0) {
			this.entete.niveauVisibiliteAuditeur = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeAuditeurId);
		}
	}

	async getNiveauVisibiliteClient() {
		if (this.entete.isClient && this.entete.typeClientId > 0) {
			this.entete.niveauVisibiliteClient = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeClientId);
		}
	}

	async getNiveauVisibiliteCommercial() {
		if (this.entete.isUtilisateur && this.entete.typeUtilisateurId > 0) {
			this.entete.niveauVisibiliteCommercial = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeUtilisateurId);
		}
	}

	async getNiveauVisibiliteFournisseur() {
		if (this.entete.isFournisseur && this.entete.typeFournisseurId > 0) {
			this.entete.niveauVisibiliteFournisseur = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeFournisseurId);
		}
	}

	async getNiveauVisibiliteLaboratoire() {
		if (this.entete.isLaboratoire && this.entete.typeLaboratoireId > 0) {
			this.entete.niveauVisibiliteLaboratoire = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeLaboratoireId);
		}
	}

	async getNiveauVisibiliteLocataire() {
		if (this.entete.isLocataire && this.entete.typeLocataireId > 0) {
			this.entete.niveauVisibiliteLocataire = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeLocataireId);
		}
	}

	async getNiveauVisibiliteProducteur() {
		if (this.entete.isProducteur && this.entete.typeProducteurId > 0) {
			this.entete.niveauVisibiliteProducteur = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeProducteurId);
		}
	}

	async getNiveauVisibiliteProprietaire() {
		if (this.entete.isProprietaire && this.entete.typeProprietaireId > 0) {
			this.entete.niveauVisibiliteProprietaire = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeProprietaireId);
		}
	}

	async getNiveauVisibiliteTransporteur() {
		if (this.entete.isTransporteur && this.entete.typeTransporteurId > 0) {
			this.entete.niveauVisibiliteTransporteur = await this.SitesTypesService.GetNiveauVisibilite(this.entete.typeTransporteurId);
		}
	}

	async genreOnClose() {
		this.dispatch();
		this.getSocietes();

		//gestion compte ouvert
		const allTypeSiteIds = this.listeTypesSite.filter((e) => e.selected);
		if (allTypeSiteIds && allTypeSiteIds.length > 0) {
			const firstTypeSite = this.listeTypesSite.find((e) => e.selected && e.disabled);
			if (firstTypeSite) {
				//gestion ajout ouverture compte
				for (let i = 0; i < allTypeSiteIds.length; i++) {
					const indexType = this.site.entete.etatCompteSites.findIndex((e) => e.genreSite === allTypeSiteIds[i].tech_id);
					if (indexType < 0 && this.verifAffichageEtatCompte(allTypeSiteIds[i].libelle)) {
						let lstEtatCptToAddd = this.site.entete.etatCompteSites.filter((e) => e.genreSite === firstTypeSite.tech_id);
						if (!lstEtatCptToAddd || lstEtatCptToAddd.length <= 0) {
							lstEtatCptToAddd = this.createCptOuverts();
						}

						for (let j = 0; j < lstEtatCptToAddd.length; j++) {
							const newEtatCpt = angular.copy(lstEtatCptToAddd[j]);
							newEtatCpt.genreSite = allTypeSiteIds[i].tech_id;
							newEtatCpt.libelleGenreSite = allTypeSiteIds[i].libelle;
							this.site.entete.etatCompteSites.push(newEtatCpt);
						}
					}
				}
			}
		}

		//gestion supression ouverture compte
		for (let i = this.site.entete.etatCompteSites.length - 1; i >= 0; i--) {
			const indexType = allTypeSiteIds.findIndex((e) => e.tech_id === this.site.entete.etatCompteSites[i].genreSite);
			if (indexType < 0) {
				this.site.entete.etatCompteSites.splice(i, 1);
			}
		}
		if (
			this.entete.isProducteur === true &&
			(this.inputFamilles === undefined || this.inputFamilles === null || this.inputFamilles.length === 0)
		) {
			await this.initiateFamilles();
		}
	}

	createCptOuverts() {
		// let appName = this.MassiaApplicationService.getApplication();
		// let lstSocieteByGenre = [];
		// if (appName == 'gestion') {
		//     lstSocieteByGenre = this.societes.filter(e => e.isCommercial);
		// }
		// if (appName == 'performance') {
		//     lstSocieteByGenre = this.societes.filter(e => e.isProducteur);
		// }
		// if (appName == 'qse') {
		//     lstSocieteByGenre = this.societes.filter(e => e.isAuditeur);
		// }
		// if (appName == 'laboratoire') {
		//     lstSocieteByGenre = this.societes.filter(e => e.isLaboratoire);
		// }

		const lstComptes = [];
		for (let i = 0; i < this.societesEtatComptes.length; i++) {
			const cpt = {
				idSite: this.site.id,
				codeSociete: this.societesEtatComptes[i].code,
				nomSociete: this.societesEtatComptes[i].libelle,
				societeId: this.societesEtatComptes[i].id,
				isCompteSiteOuvert: this.site.compteOuvertDefault
			};
			lstComptes.push(cpt);
		}

		return lstComptes;
	}

	verifAffichageEtatCompte(genreLibelle) {
		const appName = this.MassiaApplicationService.getApplication();
		if (
			(genreLibelle == 'Commercial' && appName == 'gestion') ||
			(genreLibelle == 'Producteur' && appName == 'performance') ||
			(genreLibelle == 'Auditeur' && appName == 'qse') ||
			(genreLibelle == 'Laboratoire' && appName == 'laboratoire')
		) {
			return false;
		}
		return true;
	}

	dispatch() {
		const application = this.MassiaApplicationService.getApplication();

		if (application == 'gestion') {
			this.entete.isUtilisateur =
				this.entete.isClient =
				this.entete.isFournisseur =
				this.entete.isTransporteur =
				this.entete.isProducteur =
					false;
		} else if (application == 'performance') {
			this.entete.isUtilisateur = this.entete.isFournisseur = this.entete.isProducteur = false;
		} else if (application == 'qse') {
			this.entete.isFournisseur =
				this.entete.isProducteur =
				this.entete.isProprietaire =
				this.entete.isLocataire =
				this.entete.isAuditeur =
					false;
		} else if (application == 'laboratoire') {
			this.entete.isFournisseur = this.entete.isProducteur = this.entete.isLaboratoire = this.entete.isClient = false;
		}

		angular.forEach(
			this.output.listeTypesSite,
			function (valeur) {
				this.entete[valeur] = true;
			},
			this
		);
	}

	$onChanges() {
		if (this.site && this.site.entete) {
			this.getTypesUtilisateur();
			this.getTypesClient();
			this.getTypesFournisseur();
			this.getTypesProducteur();
			this.getTypesTransporteur();
			this.getTypesLaboratoire();
			this.getTypesProprietaire();
			this.getTypesLocataire();
			this.getTypesAuditeur();
			this.getPays();
		}
	}

	// $doCheck() {
	//     if (!angular.equals(this.entete, this.site.entete)) {
	//         this.onUpdate({
	//             entete: angular.copy(this.entete)
	//         });
	//     }
	// }

	$onDestroy() {
		this.unregisterReinit();
	}

	setSiteAsTypeAhead() {
		if (this.site.entete) {
			this.lieu = {
				id: this.site.entete.lieuId,
				code: this.site.entete.lieuCode,
				libelle: this.site.entete.lieu,
				codeEtLib:
					this.site.entete && this.site.entete.lieuId && this.site.entete.lieuId != -1
						? this.site.entete.lieu + ' [' + this.site.entete.lieuCode + ']'
						: ''
			};
		}
	}

	setTypeAheadAs() {
		if (this.lieu) {
			this.entete.lieuId = this.lieu.id;
			this.entete.lieu = this.lieu.libelle;
			this.entete.lieuCode = this.lieu.code;
		}
	}

	videLieu() {
		this.lieu = {
			id: undefined,
			code: undefined,
			libelle: undefined,
			codeEtLib: ''
		};
		this.entete.lieuId = undefined;
		this.entete.lieu = undefined;
		this.entete.lieuCode = undefined;
	}

	async createType() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: 'SITES.CONFIRM_CREATE_TYPE.TITLE',
			modalMsg: 'SITES.CONFIRM_CREATE_TYPE.MESSAGE',
			headerClass: 'modal-warning'
		});
		modalInstance.result.then(() => this.$state.go(this.getUrlNewType()));
	}

	getUrlNewType() {
		switch (this.genre) {
			case 'commerciaux':
				return 'sites.newType';
			case 'clients':
				return 'clients.newType';
			case 'producteurs':
				return 'producteurs.newType';
			case 'fournisseurs':
				return 'fournisseurs.newType';
			case 'transporteurs':
				return 'transporteurs.newType';
			case 'laboratoires':
				return 'laboratoires.newType';
			case 'proprietaires':
				return 'proprietaires.newType';
			case 'locataires':
				return 'locataires.newType';
			case 'auditeurs':
				return 'auditeurs.newType';
			default:
				return 'sites.newType';
		}
	}

	async getTypesUtilisateur() {
		this.startLoading();
		try {
			this.typesUtilisateur = await this.SitesTypesService.getAllSiteUtilisateurTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesClient() {
		this.startLoading();
		try {
			this.typesClient = await this.SitesTypesService.getAllSiteClientTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesFournisseur() {
		this.startLoading();
		try {
			this.typesFournisseur = await this.SitesTypesService.getAllSiteFournisseurTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesProducteur() {
		this.startLoading();
		try {
			this.typesProducteur = await this.SitesTypesService.getAllSiteProducteurTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesTransporteur() {
		this.startLoading();
		try {
			this.typesTransporteur = await this.SitesTypesService.getAllSiteTransporteurTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesLaboratoire() {
		this.startLoading();
		try {
			this.typesLaboratoire = await this.SitesTypesService.getAllSiteLaboratoireTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesProprietaire() {
		this.startLoading();
		try {
			this.typesProprietaire = await this.SitesTypesService.getAllSiteProprietaireTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesLocataire() {
		this.startLoading();
		try {
			this.typesLocataire = await this.SitesTypesService.getAllSiteLocataireTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTypesAuditeur() {
		this.startLoading();
		try {
			this.typesAuditeur = await this.SitesTypesService.getAllSiteAuditeurTypes();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getPays() {
		this.startLoading();
		try {
			const res = await this.SitesService.getPays();
			this.paysListe = res.items;
			if (this.paysListe.length == 1) {
				this.site.entete.paysId = this.paysListe[0].id;
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getLieux(valeur) {
		const lieux = await this.SitesService.getLieux(valeur);
		return lieux;
	}

	async getSocietes() {
		if (this.site.entete) {
			this.startLoading();
			try {
				let genre = 0;
				switch (this.genre) {
					case 'commerciaux':
						genre = 1;
						break;
					case 'clients':
						genre = 2;
						break;
					case 'producteurs':
						genre = 4;
						break;
					case 'fournisseurs':
						genre = 8;
						break;
					case 'transporteurs':
						genre = 16;
						break;
					case 'laboratoires':
						genre = 32;
						break;
					case 'proprietaires':
						genre = 64;
						break;
					case 'locataires':
						genre = 128;
						break;
					case 'auditeurs':
						genre = 256;
						break;
					default:
						genre = 1;
						break;
				}

				const res = await this.SocietesService.getSocietes(null, null, null, genre, this.MassiaApplicationService.getApplication());

				//on récupère les sociétés pour liens dans les états comptes
				let appGenre = 0;
				const appName = this.MassiaApplicationService.getApplication();
				if (appName == 'laboratoire' && this.genre !== 'laboratoires') {
					appGenre = 32;
				} else if (appName == 'gestion' && this.genre !== 'commerciaux') {
					appGenre = 1;
				} else if (appName == 'performance' && this.genre !== 'producteurs') {
					appGenre = 4;
				} else if (appName == 'qse' && this.genre !== 'auditeurs') {
					appGenre = 256;
				}

				if (appGenre) {
					const resEtatCompte = await this.SocietesService.getSocietes(
						null,
						null,
						null,
						appGenre,
						this.MassiaApplicationService.getApplication()
					);
					this.societesEtatComptes = resEtatCompte.items;
				} else {
					this.societesEtatComptes = angular.copy(res.items);
				}
				//Création des état comptes pour le contexte actuel
				if (!this.site.entete.etatCompteSites || this.site.entete.etatCompteSites.length <= 0) {
					this.site.entete.etatCompteSites = [];

					const lstEtatCompte = this.createCptOuverts();
					const allTypeSiteIds = this.listeTypesSite.filter((e) => e.selected);
					if (allTypeSiteIds && allTypeSiteIds.length > 0) {
						for (let i = 0; i < allTypeSiteIds.length; i++) {
							const firstTypeSite = allTypeSiteIds[i];
							for (let j = 0; j < lstEtatCompte.length; j++) {
								if (appGenre) {
									this.site.entete.etatCompteSites.push({
										genreSite: firstTypeSite.tech_id,
										libelleGenreSite: firstTypeSite.libelle,
										isCompteSiteOuvert: this.site.compteOuvertDefault,
										idSite: this.site.id,
										codeSociete: lstEtatCompte[j].codeSociete,
										nomSociete: lstEtatCompte[j].nomSociete,
										societeId: lstEtatCompte[j].societeId
									});
								}
							}
						}
					}
				}

				// var res = await this.SitesService.getSocietes(this.site.entete.isUtilisateur, this.site.entete.isClient, this.site.entete.isProducteur,
				//     this.site.entete.isTransporteur, this.site.entete.isFournisseur, this.site.entete.isLaboratoire, this.site.entete.isProprietaire,
				//     this.site.entete.isLocataire, this.site.entete.isAuditeur);
				this.societes = res.items;
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		}
	}

	async getPersonnes(valeur) {
		const personnes = await this.SitesService.getPersonnes(valeur);
		return personnes;
	}

	async getClientDivers(id) {
		this.listeClientsDivers = [];
		this.entete.idClientsDivers = [];
		if (this.site.etatCompteSites) {
			for (let index = 0; index < this.site.etatCompteSites.length; index++) {
				const site = this.site.etatCompteSites[index];
				if (site.isCompteSiteOuvert) {
					const filters = [
						{
							criterion: 'societeId',
							type: 'Eq',
							value: site.societeId
						}
					];
					const res = await this.SitesService.getAllClientDivers(filters);
					res.forEach((r) => {
						this.listeClientsDivers.push(r);
					});
				}
			}
			this.listeClientsDivers = this.listeClientsDivers.filter((x) => x.idClientGenerique == null || x.idClientGenerique == id);
			for (let index = 0; index < this.listeClientsDivers.length; index++) {
				const client = this.listeClientsDivers[index];
				if (client.idClientGenerique == id) {
					this.entete.idClientsDivers.push(client.id);
					client.selected = true;
				}
			}
			this.listeClientsDivers.sort(this.sortClientsDivers);
			await this.preselectClientsDivers();
		}
	}

	async preselectClientsDivers() {
		for (let index = 0; index < this.entete.idClientsDivers.length; index++) {
			const element = this.entete.idClientsDivers[index];
			const filtre = this._.find(this.listeClientsDivers, { id: element });
			if (filtre) {
				filtre.selected = true;
			}
		}
	}

	//On trie les clients par id s'ils viennent de plusieurs comptes ouverts pour garder un ordre homogène
	sortClientsDivers(a, b) {
		if (a.id > b.id) {
			return 1;
		}
		if (a.id < b.id) {
			return -1;
		}
		return 0;
	}

	clientDiversOnUpdate() {
		this.entete.idClientsDivers = [];
		for (let i = 0; i < this.outputClientsDivers.length; i++) {
			const contactId = this.outputClientsDivers[i];
			this.entete.idClientsDivers.push(contactId);
		}
	}

	setPersonneAheadAs() {
		this.entete.personneId = this.personneSuivi.id;
	}

	setSiteAsPersonneAhead() {
		this.personneSuivi = {
			id: this.entete.personneId,
			libelle: this.entete.personne
		};
	}

	async checkCodeUnicity(code) {
		if (code) {
			this.startCodeLoading();

			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.entete.codeExists = await this.SitesService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		} else {
			this.entete.codeExists = null;
		}
	}

	async initiateContacts() {
		if (this.entete.isClient || this.entete.isTransporteur) {
			this.inputContacts = await this.refreshContacts();
			this.preselectContacts();
		}
	}

	async preselectContacts() {
		for (let index = 0; index < this.entete.idContacts.length; index++) {
			const element = this.entete.idContacts[index];
			const filtre = this._.find(this.inputContacts, { id: element });
			if (filtre) {
				filtre.selected = true;
			}
		}
	}

	async saveSelectedContacts() {
		this.entete.idContacts = [];
		for (let i = 0; i < this.outputContacts.length; i++) {
			const contact = this.outputContacts[i];
			this.entete.idContacts.push(contact.id);
		}
	}

	async refreshContacts() {
		let data;
		try {
			data = await this.SitesService.getContacts();
		} catch (ex) {
			data = [];
		}
		return data;
	}

	haveToSocieteMandatory() {
		const appName = this.MassiaApplicationService.getApplication();

		if (appName == 'laboratoire' && this.genre == 'laboratoires') {
			return true;
		} else if (appName == 'gestion' && this.genre == 'commerciaux') {
			return true;
		} else if (appName == 'performance' && this.genre == 'producteurs') {
			return true;
		} else if (appName == 'qse' && this.genre == 'auditeurs') {
			return true;
		}

		return false;
	}

	async initiateFamilles() {
		if (this.entete.isProducteur) {
			this.inputFamilles = await this.refreshFamilles();
			this.preselectFamilles();
		}
	}

	async preselectFamilles() {
		if (this.entete.familles.length > 0) {
			for (let index = 0; index < this.entete.familles.length; index++) {
				const element = this.entete.familles[index];
				const filtre = this._.find(this.inputFamilles, { idFamille: element.idFamille });
				if (filtre) {
					filtre.selected = true;
				}
			}
		} else if (!this.entete.id) {
			for (let index = 0; index < this.inputFamilles.length; index++) {
				this.entete.familles.push({
					idFamille: this.inputFamilles[index].idFamille,
					codeFamille: this.inputFamilles[index].code,
					libelleFamille: this.inputFamilles[index].nom
				});
				this.inputFamilles[index].selected = true;
			}
		}
	}

	async saveSelectedFamilles() {
		this.entete.familles = [];
		for (let i = 0; i < this.outputFamilles.length; i++) {
			const famille = this.outputFamilles[i];
			this.entete.familles.push({ idFamille: famille.idFamille, codeFamille: famille.code, libelleFamille: famille.nom });
		}
	}

	async refreshFamilles() {
		let data;
		try {
			const appName = this.MassiaApplicationService.getApplication();
			if (appName == 'gestion' && this.entete.isProducteur === true) {
				data = await this.SitesService.getFamillesAll();
			} else {
				data = await this.SitesService.getFamilles();
			}
		} catch (ex) {
			data = [];
		}
		return data;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	dispose() {
		this.watchers.forEach((x) => x());
	}

	selectNewContact() {
		this.inputContacts.push({
			id: this.newContact.id,
			libelle: this.newContact.prenom + ' ' + this.newContact.nom,
			selected: true
		});

		this.outputContacts.push({ id: this.newContact.id });
		this.saveSelectedContacts();
	}
}
