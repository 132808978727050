export default class UserPortail {
	constructor(data = {}) {
		this.id = data.id;
		this.login = data.login;
		this.nom = data.nom;
		this.prenom = data.prenom;
		this.password = data.password;
		this.isUser = data.isUser;
		this.idPersonne = data.idPersonne > 0 ? data.idPersonne : null;
		this.idSite = data.idSite > 0 ? data.idSite : null;
		this.isInPortail = data.isInPortail;
		this.mail = data.mail;
		this.phone = data.telMobile;
		this.access = data.access;
		this.idsGroup = data.idsGroup;
		this.group = data.group;
		this.dematBlMail = data.dematBlMail;
		this.dematBlSms = data.dematBlSms;
		this.chauffeur = data.chauffeur || false;
	}
}
