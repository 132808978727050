BennesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'BennesService',
	'notification',
	'MOPService'
];

export default function BennesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	BennesService,
	notification,
	MOPService
) {
	const vm = this;

	let previousTableState;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.bennes = [];
	vm.isEnabled = true;
	vm.previousIsEnabled = true;

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadBennes = loadBennes;
	vm.deleteBenne = deleteBenne;
	vm.createBenne = createBenne;
	vm.updateBenne = updateBenne;
	vm.activeEditMode = activeEditMode;
	vm.desactiveEditMode = desactiveEditMode;
	vm.activateBenne = activateBenne;
	vm.MOPService = MOPService;

	async function init() {
		vm.MOPService.setMop([{ title: 'BENNES.BREADCRUMBS.BENNES_LIST', filename: 'Vehicules.pdf', application: 'gestion' }]);
		vm.typeBennes = await BennesService.getTypeBennes();
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	async function loadBennes(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'bennes.list') {
			$state.go('bennes.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		if (vm.isEnabled != vm.previousIsEnabled) {
			pagination.skip = 0;
			vm.previousIsEnabled = vm.isEnabled;
		}

		vm.bennes = [];
		vm.newBenne = {};

		try {
			const data = await BennesService.getBennes(vm.isEnabled, filters, sorts, pagination);

			vm.bennes = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteBenne(benne) {
		if (benne && benne.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('BENNES.DELETE.TITLE'),
				modalMsg: $translate.instant('BENNES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await BennesService.deleteBenneById(benne.id);
					notification.success($translate.instant('BENNES.DELETE.SUCCESS'));
					loadBennes();
				} catch (ex) {
					if (ex.status == 500) {
						notification.error('BENNES.DELETE.NOTDELETABLE');
					} else notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function activateBenne(idBenne) {
		await BennesService.activateBenne(idBenne);
		loadBennes();
	}

	function activeEditMode(benne) {
		benne.isEditMode = true;
	}

	function desactiveEditMode(benne) {
		benne.isEditMode = false;
	}

	async function createBenne(benne) {
		if (benne && benne.immatriculation && benne.ptac) {
			// const modalInstance = ModalService.confirm({
			//     modalTitle: $translate.instant('BENNES.CREATE.TITLE'),
			//     modalMsg: $translate.instant('BENNES.CREATE.MESSAGE'),
			//     headerClass: 'modal-danger'
			// });

			// modalInstance.result
			//     .then(async function() {
			startLoading();
			try {
				await BennesService.createBenne(benne);
				notification.success($translate.instant('BENNES.CREATE.SUCCESS'));
				loadBennes();
				vm.newBenne = {};
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
			// });
		} else if (!benne.immatriculation && !benne.ptac) notification.error('BENNES.CREATE.MISSINGDATA');
		else if (!benne.immatriculation) notification.error('BENNES.CREATE.MISSINGIMMAT');
		else if (!benne.ptac) notification.error('BENNES.CREATE.MISSINGPTAC');
	}

	async function updateBenne(benne) {
		if (benne && benne.id) {
			if (benne.immatriculation && benne.ptac) {
				// const modalInstance = ModalService.confirm({
				//     modalTitle: $translate.instant('BENNES.UPDATE.TITLE'),
				//     modalMsg: $translate.instant('BENNES.UPDATE.MESSAGE'),
				//     headerClass: 'modal-danger'
				// });

				// modalInstance.result
				//     .then(async function() {
				startLoading();
				try {
					await BennesService.updateBenne(benne);
					notification.success($translate.instant('BENNES.UPDATE.SUCCESS'));
					vm.newBenne = {};
					loadBennes();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
				// });
			} else if (!benne.immatriculation && !benne.ptac) notification.error('BENNES.CREATE.MISSINGDATA');
			else if (!benne.immatriculation) notification.error('BENNES.CREATE.MISSINGIMMAT');
			else if (!benne.ptac) notification.error('BENNES.CREATE.MISSINGPTAC');
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}
}
