import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/* @ngInject */
export default class SyntheseSettingService {
	valeur = {};

	constructor(ApiService, $translate) {
		this._api = ApiService;
		this._$translate = $translate;
		this.currentTypeSynthese = -1;
	}

	/**
	 *
	 * @param {string} app
	 */
	loadTypeSynthese(app) {
		return new Promise((resolve, reject) => {
			return this._api
				.get('massia/setting/synthese/type-synthese', {
					params: {
						app: app
					}
				})
				.then((res) => {
					const typeSynthese = res.map((x) => {
						x.libelle = this._$translate.instant(x.name);
						return x;
					});
					resolve(typeSynthese);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getValeur(critere, siteProducteur) {
		if (
			!this.valeur[
				`${critere.id}${critere.champ}${critere.idCaracteristique}${critere.idDonneeSelectionable}${critere.typeCritere}${siteProducteur}`
			]
		) {
			this.valeur[
				`${critere.id}${critere.champ}${critere.idCaracteristique}${critere.idDonneeSelectionable}${critere.typeCritere}${siteProducteur}`
			] = new BehaviorSubject([]);
		}
		return this.valeur[
			`${critere.id}${critere.champ}${critere.idCaracteristique}${critere.idDonneeSelectionable}${critere.typeCritere}${siteProducteur}`
		]
			.asObservable()
			.pipe(distinctUntilChanged());
	}

	loadValeur(critere, siteProducteur) {
		if (
			!this.valeur[
				`${critere.id}${critere.champ}${critere.idCaracteristique}${critere.idDonneeSelectionable}${critere.typeCritere}${siteProducteur}`
			]
		) {
			this.valeur[
				`${critere.id}${critere.champ}${critere.idCaracteristique}${critere.idDonneeSelectionable}${critere.typeCritere}${siteProducteur}`
			] = new BehaviorSubject([]);
		}
		this._api
			.get('massia/setting/synthese/valeur', {
				params: {
					critere: critere.id,
					producteurs: siteProducteur
				}
			})
			.then(
				(res) => {
					this.valeur[
						`${critere.id}${critere.champ}${critere.idCaracteristique}${critere.idDonneeSelectionable}${critere.typeCritere}${siteProducteur}`
					].next(res);
				},
				(err) => console.error(err)
			);
	}

	loadValeurs(idCritere, siteProducteur) {
		this._api
			.get('massia/setting/synthese/valeur', {
				params: {
					critere: idCritere,
					producteurs: siteProducteur
				}
			})
			.then(
				(res) => {
					this.valeur[`${idCritere}${siteProducteur}`].next(res);
				},
				(err) => console.error(err)
			);
	}

	loadEnum(idCritere) {
		return this._api.get('massia/setting/synthese/enum', {
			params: {
				critere: idCritere
			}
		});
	}

	loadTable(idTypeSynthese) {
		return this._api.get('massia/setting/synthese/table', {
			params: {
				idSynthese: idTypeSynthese
			}
		});
	}

	loadDonneeValeur(idTypeSynthese) {
		return this._api.get('massia/setting/synthese/donnee-valeur', {
			params: {
				idSynthese: idTypeSynthese
			}
		});
	}
}
