import DapEntete from './dap.entete.model';

export default class DapEnteteController {
	static $inject = [
		'$stateParams',
		'DAPsCommunicationService',
		'DAPsService',
		'notification',
		'moment',
		'$translate',
		'$uibModal',
		'ModalService',
		'DocumentsService',
		'globalizationManagementService',
		'LieuxService',
		'SocietesService',
		'ChantiersService'
	];

	constructor(
		$stateParams,
		DAPsCommunicationService,
		DAPsService,
		notification,
		moment,
		$translate,
		$uibModal,
		ModalService,
		DocumentsService,
		globalizationManagementService,
		LieuxService,
		SocietesService,
		ChantiersService
	) {
		this.$stateParams = $stateParams;
		this.DAPsCommunicationService = DAPsCommunicationService;
		this.DAPsService = DAPsService;
		this.notification = notification;
		this.moment = moment;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.ModalService = ModalService;
		this.DocumentsService = DocumentsService;
		this.globalizationManagementService = globalizationManagementService;
		this.LieuxService = LieuxService;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.SocietesService = SocietesService;
		this.ChantiersService = ChantiersService;
	}

	async $onInit() {
		this.dap = this.dap || {};
		this.entete = new DapEntete(this.dap.entete);
		this.selectedControles = [];
		this.getControles();
		this.getNomenclatures();
		this.getTransporteurs();
		this.getEcoOrganismes();
		if (this.$stateParams.id || this.$stateParams.idCarnet) {
			this.setDAPAsTypeAhead();
		}
		this.getCodeAuto();
		if (!this.dap.entete.dateCreation) {
			const today = this.moment().format(this.dateFormat);
			this.dap.entete.dateCreation = today;
			const lastDay = this.moment().add('years', 1).format(this.dateFormat);
			this.dap.entete.dateValidite = lastDay;
		}
	}

	async getCodeAuto() {
		try {
			this.dap.entete.estCodeAuto = await this.DAPsService.estCodeAuto();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	$doCheck() {
		if (!angular.equals(this.entete, this.dap.entete)) {
			this.onUpdate({
				entete: angular.copy(this.entete)
			});
		}
	}

	setTypeAheadAs(val) {
		if (!this.dap) {
			this.dap = {};
		}
		switch (val) {
			case 'siteCommercial':
				this.dap.entete.siteCommercialId = this.siteCommercial.id;
				this.dap.entete.siteCommercial = this.siteCommercial.libelle;
				this.dap.entete.siteCommercialCode = this.siteCommercial.code;
				this.getTransporteurs();
				break;
			case 'producteur':
				this.dap.entete.producteurId = this.producteur.id;
				this.dap.entete.producteur = this.producteur.libelle;
				this.dap.entete.producteurCode = this.producteur.code;
				break;
			case 'client':
				this.dap.entete.clientDetenteurId = this.client.id;
				this.dap.entete.clientDetenteur = this.client.libelle;
				this.dap.entete.clientDetenteurCode = this.client.code;
				this.dap.entete.clientDetenteurIsDuBatiment = this.client.IsduBatiment;
				//this.loadChantiers();
				break;
			case 'lieu':
				this.dap.entete.lieuOrigineId = this.lieu.id;
				this.dap.entete.lieuOrigine = this.lieu.libelle;
				this.dap.entete.lieuOrigineCode = this.lieu.code;
				break;
			case 'chantier':
				this.dap.entete.chantierOrigineId = this.chantier.id;
				this.dap.entete.chantierOrigine = this.chantier.libelle;
				this.dap.entete.chantierOrigineCode = this.chantier.code;
				this.dap.entete.isChantierDuBatiment = this.chantier.isChantierDuBatiment;
				if (this.chantier.isChantierDuBatiment && this.ecoOrganismes && this.ecoOrganismes.length == 1) {
					this.ecoOrganismes[0].selected = true;
					this.dap.entete.ecoOrganisme = this.ecoOrganismes[0];
				}
				break;
			case 'producteurDechet':
				this.dap.entete.producteurDechetId = this.producteurDechet.id;
				this.dap.entete.producteurDechet = this.producteurDechet.libelle;
				this.dap.entete.producteurDechetCode = this.producteurDechet.code;
				break;
		}
	}

	setDAPAsTypeAhead() {
		this.siteCommercial = {
			id: this.dap.entete.siteCommercialId,
			libelle: this.dap.entete.siteCommercial,
			code: this.dap.entete.siteCommercialCode
		};
		this.transporteur = {
			id: this.dap.entete.transporteurId,
			libelle: this.dap.entete.transporteur,
			code: this.dap.entete.transporteurCode
		};
		this.producteur = {
			id: this.dap.entete.producteurId,
			libelle: this.dap.entete.producteur,
			code: this.dap.entete.producteurCode
		};
		this.client = {
			id: this.dap.entete.clientDetenteurId,
			libelle: this.dap.entete.clientDetenteur,
			code: this.dap.entete.clientDetenteurCode
		};
		this.lieu = {
			id: this.dap.entete.lieuOrigineId,
			code: this.dap.entete.lieuOrigineCode,
			libelle: this.dap.entete.lieuOrigine,
			codeEtLib: this.dap.entete.lieuOrigineId ? this.dap.entete.lieuOrigine + ' [' + this.dap.entete.lieuOrigineCode + ']' : ''
		};
		this.chantier = {
			id: this.dap.entete.chantierOrigineId,
			code: this.dap.entete.chantierOrigineCode,
			libelle: this.dap.entete.chantierOrigine
		};
		this.producteurDechet = {
			id: this.dap.entete.producteurDechetId,
			code: this.dap.entete.producteurDechetCode,
			libelle: this.dap.entete.producteurDechet
		};
	}

	videSiteCommercial() {
		this.siteCommercial = undefined;
		this.dap.entete.siteCommercialId = undefined;
		this.dap.entete.siteCommercial = undefined;
		this.dap.entete.siteCommercialCode = undefined;
	}

	async getSitesCommerciaux(valeur) {
		const sitesCommerciaux = await this.DAPsService.getSitesCommerciaux(valeur);
		return sitesCommerciaux;
	}

	videTransporteur() {
		this.transporteur = undefined;
		this.dap.entete.transporteurId = undefined;
		this.dap.entete.transporteur = undefined;
		this.dap.entete.transporteurCode = undefined;
	}

	videLieu() {
		this.lieu = undefined;
		this.dap.entete.lieuOrigineId = undefined;
		this.dap.entete.lieuOrigine = undefined;
		this.dap.entete.lieuOrigineCode = undefined;
	}

	async getLieux(valeur) {
		const lieux = await this.DAPsService.getLieux(valeur);
		return lieux;
	}

	videProducteur() {
		this.producteur = undefined;
		this.dap.entete.producteurId = undefined;
		this.dap.entete.producteur = undefined;
		this.dap.entete.producteurCode = undefined;
	}

	async getProducteurs(valeur) {
		const producteurs = await this.DAPsService.getProducteurs(valeur, this.dap.entete.siteCommercialId);
		return producteurs;
	}

	videProducteurDechet() {
		this.producteurDechet = undefined;
		this.dap.entete.producteurDechetId = undefined;
		this.dap.entete.producteurDechet = undefined;
		this.dap.entete.producteurDechetCode = undefined;
	}

	async getProducteurDechets(valeur) {
		const producteurDechets = await this.DAPsService.getProducteurDechets(valeur);
		return producteurDechets;
	}

	videClient() {
		this.client = undefined;
		this.dap.entete.clientDetenteurId = undefined;
		this.dap.entete.clientDetenteur = undefined;
		this.dap.entete.clientDetenteurCode = undefined;
	}

	async getClients(valeur) {
		const clients = await this.DAPsService.getClients(valeur, this.dap.entete.siteCommercialId);
		return clients;
	}

	videChantier() {
		this.chantier = undefined;
		this.dap.entete.chantierOrigineId = undefined;
		this.dap.entete.chantierOrigine = undefined;
		this.dap.entete.chantierOrigineCode = undefined;
	}

	async getChantiers(valeur) {
		const chantiers = await this.DAPsService.getChantiers(valeur, this.dap.entete.clientDetenteurId);
		return chantiers;
	}

	async getControles() {
		this.startLoading();
		try {
			this.allControles = await this.DAPsService.getControles();
			if (this.dap.entete && this.dap.entete.controlesIds) {
				this.allControles.items.forEach((item) => {
					if (this.dap.entete.controlesIds.indexOf(item.id) !== -1) {
						item.selected = true;
					}
				});
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getNomenclatures() {
		this.startLoading();
		try {
			this.nomenclatures = await this.DAPsService.getNomenclatures();
			if (this.dap.entete && this.dap.entete.nomenclatureIds) {
				this.nomenclatures.forEach((item) => {
					if (this.dap.entete.nomenclatureIds.indexOf(item.id) !== -1) {
						item.selected = true;
					}
				});
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getTransporteurs() {
		this.startLoading();
		try {
			if (this.dap.entete.siteCommercialId) {
				this.transporteurs = await this.DAPsService.getTransporteurs(this.dap.entete.siteCommercialId);
				if (this.dap.entete && this.dap.entete.transporteurIds) {
					this.transporteurs.forEach((item) => {
						if (this.dap.entete.transporteurIds.indexOf(item.id) !== -1) {
							item.selected = true;
						}
					});
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}
	async getEcoOrganismes() {
		this.ecoOrganismes = await this.DAPsService.getSocietesByType();

		if (this.ecoOrganismes && this.ecoOrganismes.length > 0 && this.dap.entete.ecoOrganisme) {
			const i = this.ecoOrganismes.findIndex((x) => x.id == this.dap.entete.ecoOrganisme?.id);
			if (i > -1) {
				this.ecoOrganismes[i].selected = true;
			}
		} else if (this.ecoOrganismes.length == 1 && this.dap.entete.isChantierDuBatiment) {
			this.ecoOrganismes[0].selected = true;
			this.dap.entete.ecoOrganisme = this.ecoOrganismes[0];
		}
	}
	setEcoOrganisme() {
		this.dap.entete.ecoOrganisme = this.tmpEcoOrganisme ? this.tmpEcoOrganisme[0] : null;
	}
	async checkCodeUnicity(code) {
		if (code) {
			this.startCodeLoading();

			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.entete.codeExists = await this.DAPsService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		} else {
			this.entete.codeExists = null;
		}
	}

	async deleteControle(controle) {
		let index = -1;
		for (let i = 0; i < this.dap.entete.prestations.length; i++) {
			if (this.dap.entete.prestations[i].prestationId == controle.id) {
				index = i;
			}
		}
		this.dap.entete.prestations.splice(index, 1);
	}

	async addControle() {
		const element = {
			prestationId: this.new.controle.id,
			code: this.new.controle.code,
			libelle: this.new.controle.libelle
		};
		this.dap.entete.prestations.push(element);
		this.new = {};
	}

	setTransporteurSignature() {
		for (let i = 0; i < this.dap.entete.transporteurIds.length; i++) {
			const idTransporteur = this.dap.entete.transporteurIds[i];

			const indexTransporteur = this.dap.entete.transporteurSignatures.findIndex((x) => x.id === idTransporteur);
			if (indexTransporteur === -1) {
				const transporteurLibelle = this.transporteurs.find((x) => x.id === idTransporteur);
				const newTransporteurSignature = {
					id: idTransporteur,
					libelle: 'Signature ' + transporteurLibelle.libelle,
					isSignature: false
				};
				this.dap.entete.transporteurSignatures.push(newTransporteurSignature);
			}
		}

		for (let i = 0; i < this.dap.entete.transporteurSignatures.length; i++) {
			const idTransporteurSignature = this.dap.entete.transporteurSignatures[i].id;
			const indexTransporteurSignature = this.dap.entete.transporteurIds.findIndex((x) => x === idTransporteurSignature);
			if (indexTransporteurSignature === -1) {
				this.dap.entete.transporteurSignatures.splice(i, 1);
			}
		}
	}

	resetTransporteurSignature() {
		this.dap.entete.transporteurSignatures = [];
	}

	setNomenclatures() {
		this.dap.entete.nomenclatureIds = [];
		for (let i = 0; i < this.dap.entete.nomenclaturesOutput.length; i++) {
			const currentNomenclature = this.dap.entete.nomenclaturesOutput[i];
			this.dap.entete.nomenclatureIds.push(currentNomenclature.id);

			const indexNomenclature = this.dap.entete.nomenclatures.findIndex((x) => x.idNomenclature === currentNomenclature.id);
			if (indexNomenclature === -1) {
				const newNomenclature = {
					idNomenclature: currentNomenclature.id,
					libelle: currentNomenclature.libelle,
					code: currentNomenclature.code,
					quantite: 0
				};
				this.dap.entete.nomenclatures.push(newNomenclature);
			}
		}

		for (let i = 0; i < this.dap.entete.nomenclatures.length; i++) {
			const idNomenclature = this.dap.entete.nomenclatures[i].idNomenclature;
			const indexNomenclature = this.dap.entete.nomenclaturesOutput.findIndex((x) => x.id === idNomenclature);
			if (indexNomenclature === -1) {
				this.dap.entete.nomenclatures.splice(i, 1);
				i--;
			}
		}
	}

	resetNomenclatures() {
		this.dap.entete.nomenclatures.splice(0, this.dap.entete.nomenclatures.length);
	}

	async downloadFichierAnalyse() {
		this.startLoading();
		const resultat = await this.DocumentsService.print(
			this.dap.entete.documentAnalyse.libelle,
			this.dap.entete.documentAnalyse.extension,
			this.dap.entete.documentAnalyse.code
		);

		const data = resultat.data;
		let headers = resultat.headers;
		const fileName = this.dap.entete.documentAnalyse.libelle;
		const extension = this.dap.entete.documentAnalyse.extension;

		headers = headers();

		const contentType = headers['content-type'];

		const linkElement = document.createElement('a');
		try {
			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', fileName + '.' + extension);

			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);
		} catch (ex) {
			//console.log(ex);
		} finally {
			this.stopLoading();
		}
	}

	async deleteDocument() {
		const _this = this;
		if (this.dap.entete.documentAnalyse && this.dap.entete.documentAnalyse.id) {
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('DOCUMENTS.DELETE.TITLE', { code: this.dap.entete.documentAnalyse.libelle }),
				modalMsg: this.$translate.instant('DOCUMENTS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				try {
					await _this.DocumentsService.deleteDocumentById(_this.dap.entete.documentAnalyse.id);
					_this.notification.success(_this.$translate.instant('DOCUMENTS.DELETE.SUCCESS'));
					_this.dap.entete.documentAnalyse = null;
				} catch (ex) {
					_this.notification.error(ex.data);
				}
			});
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	async geoloc() {
		this.dap.entete.longitude = null;
		this.dap.entete.latitude = null;

		const lieu = this.dap.entete.lieuOrigineId ? await this.LieuxService.getLieuEnteteById(this.dap.entete.lieuOrigineId) : null;
		if (this.checkAdress(lieu)) {
			const res = await this.DAPsService.getLieuLocation(lieu);
			if (res) {
				this.dap.entete.longitude = res.lon;
				this.dap.entete.latitude = res.lat;
				this.notification.success('DAPS.GET_COORDINATES_SUCCESS', 'Lieu');
			}
		}

		if (!this.dap.entete.latitude && !this.dap.entete.longitude) {
			const chantier = await this.ChantiersService.getChantierEnteteById(this.dap.entete.chantierOrigineId);
			if (this.checkAdress(chantier, true)) {
				const res = await this.DAPsService.getLieuLocation(chantier);
				if (res) {
					this.dap.entete.longitude = res.lon;
					this.dap.entete.latitude = res.lat;
					this.notification.success('DAPS.GET_COORDINATES_SUCCESS', 'Chantier');
				} else {
					this.notification.info('DAPS.ERROR_GET_COORDINATES', 'Chantier');
				}
			}
		}
	}
	checkAdress(lieu, isChantier = false) {
		const type = isChantier ? 'Chantier' : 'Lieu';
		if (!lieu) {
			this.notification.info('DAPS.NO_LIEU', type);
			this.notification.info('DAPS.ERROR_GET_COORDINATES', type);
			return false;
		}
		let imcompleteAddress = false;
		if (!lieu.adress) {
			lieu.adress = '';
		}
		if (!lieu.codePostal || lieu.codePostal === '') {
			imcompleteAddress = true;
		}
		if (!lieu.ville || lieu.ville === '') {
			imcompleteAddress = true;
		}

		if (imcompleteAddress) {
			this.notification.info('DAPS.INCOMPLETE_ADDRESS', type);

			this.notification.info('DAPS.ERROR_GET_COORDINATES', type);
		}

		return !imcompleteAddress;
	}

	showMap() {
		const url = `https://www.openstreetmap.org/?mlat=${this.dap.entete.latitude}&mlon=${this.dap.entete.longitude}&zoom=14&layers=M`;
		window.open(url, 'carte');
	}
}
