ReleveBonsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'ReleveBonsService',
	'notification',
	'moment',
	'DataShareService',
	'_',
	'$uibModal',
	'globalizationManagementService'
];

export default function ReleveBonsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	ReleveBonsService,
	notification,
	moment,
	DataShareService,
	_,
	$uibModal,
	globalizationManagementService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.loading = false;

	vm.params = $stateParams;
	vm.state = $state;
	vm.moment = moment;
	vm.DataShareService = DataShareService;
	vm._ = _;
	vm.$uibModal = $uibModal;
	vm.translate = $translate;
	vm.releveBons = [];
	vm.isSearchOngletOpen = true;
	vm.searchingFormReleveBons = {};
	vm.searchingFormReleveBons.entity = 'releveBons';
	vm.savedPagination = {};
	vm.savedPagination.entity = 'pagination';
	vm.societesComLoaded = [];
	vm.sitesComLoaded = [];
	vm.searchingFormReleveBons.societeComIdSelected = null;
	vm.searchingFormReleveBons.siteComIdSelected = null;
	vm.searchingFormReleveBons.entreeSortie = null;
	vm.searchingFormReleveBons.achatVente = null;

	vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

	// initialise les dates de debut et fin au premier et dernier jour du mois en cours
	const maDate = new Date();
	const y = maDate.getFullYear();
	const m = maDate.getMonth();
	const firstDay = new Date(y, m, 1);
	const lastDay = new Date(y, m + 1, 0);
	// SL Mantis 387 RG 3
	vm.searchingFormReleveBons.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
	// SL Mantis 387 RG 4
	vm.searchingFormReleveBons.dateFin = vm.moment(lastDay).format(vm.dateFormat);

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200, 1000];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadReleveBons = loadReleveBons;
	vm.reset = reset;
	vm.getSitesCom = getSitesCom;
	vm.saveFiltreEntreeSortie = saveFiltreEntreeSortie;
	vm.saveFiltreAchatVente = saveFiltreAchatVente;
	vm.checkAllReleves = checkAllReleves;
	vm.checkReleve = checkReleve;
	vm.generer = generer;
	vm.openSettings = openSettings;
	async function init() {
		watchers.push($scope.$on('$destroy', dispose));

		if (_.isEmpty(vm.DataShareService.savedData) == false) {
			const item = _.find(vm.DataShareService.savedData, { entity: 'releveBons' });
			if (item != null) {
				await updateSearchForm();
			}
		}

		await getSocietesCom();
		await getSitesCom();

		if (vm.searchingFormReleveBons.societeComIdSelected != null && vm.searchingFormReleveBons.siteComIdSelected != 0) {
			await loadReleveBons(null);
		}

		vm.inputFiltreAchatVente = [
			{ id: '0', libelle: vm.translate.instant('CENTRE_GESTION.ACHAT') },
			{ id: '1', libelle: vm.translate.instant('CENTRE_GESTION.VENTE') }
		];
		vm.inputFiltreEntreeSortie = [
			{ id: '0', libelle: vm.translate.instant('CENTRE_GESTION.ENTREE') },
			{ id: '1', libelle: vm.translate.instant('CENTRE_GESTION.SORTIE') }
		];
	}

	init();

	async function updateSearchForm() {
		vm.searchingFormReleveBons = vm.DataShareService.getDataShare('releveBons');
	}

	async function getSocietesCom() {
		vm.societesComLoaded = await ReleveBonsService.getSocietesCom();
		if (vm.societesComLoaded.length == 1) {
			vm.searchingFormReleveBons.societeComIdSelected = vm.societesComLoaded[0].id;
		}
	}

	async function getSitesCom() {
		vm.sitesComLoaded = await ReleveBonsService.getSitesCom(vm.searchingFormReleveBons.societeComIdSelected);
		if (vm.sitesComLoaded.length == 1) {
			vm.searchingFormReleveBons.siteComIdSelected = vm.sitesComLoaded[0].id;
		}
	}

	async function loadReleveBons(tableState) {
		startLoading();

		// gestion de la sauvegarde et de la restitution de la pagination
		const objectToManage = {};
		objectToManage.tableState = tableState;
		objectToManage.savedPagination = vm.savedPagination;
		objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
		objectToManage.itemsByPage = vm.itemsByPage;
		const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
		if (resultObject) {
			tableState = resultObject.tableState;
			vm.savedPagination = resultObject.savedPagination;
			vm.itemsByPageOptions = resultObject.itemsByPageOptions;
			vm.itemsByPage = resultObject.itemsByPage;
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.releveBons = [];

		vm.DataShareService.setDataShare(vm.searchingFormReleveBons, 'releveBons');

		try {
			const data = await ReleveBonsService.getReleveBons(
				filters,
				sorts,
				pagination,
				vm.searchingFormReleveBons.societeComIdSelected,
				vm.searchingFormReleveBons.siteComIdSelected,
				vm.searchingFormReleveBons.dateDebut,
				vm.searchingFormReleveBons.dateFin,
				vm.searchingFormReleveBons.entreeSortie,
				vm.searchingFormReleveBons.achatVente
			);

			vm.releveBons = data.items.map((x) => {
				x.isChecked = x.isChecked || false;
				return x;
			});

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function saveFiltreAchatVente() {
		if (this.outputFiltreAchatVente.length == 0) vm.searchingFormReleveBons.achatVente = null;
		else vm.searchingFormReleveBons.achatVente = [];
		for (let i = 0; i < this.outputFiltreAchatVente.length; i++) {
			const output = this.outputFiltreAchatVente[i];
			vm.searchingFormReleveBons.achatVente.push(output.id);
		}
	}

	async function saveFiltreEntreeSortie() {
		if (this.outputFiltreEntreeSortie.length == 0) vm.searchingFormReleveBons.entreeSortie = null;
		else vm.searchingFormReleveBons.entreeSortie = [];
		for (let i = 0; i < this.outputFiltreEntreeSortie.length; i++) {
			const output = this.outputFiltreEntreeSortie[i];
			vm.searchingFormReleveBons.entreeSortie.push(output.id);
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}

	function reset() {
		vm.searchingFormReleveBons.societeComIdSelected = null;
		vm.searchingFormReleveBons.siteComIdSelected = null;
		vm.searchingFormReleveBons.entreeSortie = null;
		vm.searchingFormReleveBons.achatVente = null;

		// initialise les dates de debut et fin au premier et dernier jour du mois en cours
		const maDate = new Date();
		const y = maDate.getFullYear();
		const m = maDate.getMonth();
		const firstDay = new Date(y, m, 1);
		const lastDay = new Date(y, m + 1, 0);
		vm.searchingFormReleveBons.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
		vm.searchingFormReleveBons.dateFin = vm.moment(lastDay).format(vm.dateFormat);
		vm.releveBons = [];

		vm.DataShareService.setDataShare(vm.searchingFormReleveBons, 'releveBons');

		getSocietesCom();
	}

	function checkAllReleves(value) {
		vm.releveBons.forEach(function (element) {
			element.isChecked = value;
		}, this);
	}

	function checkReleve(releveBon) {
		const id = releveBon.id;
		const value = releveBon.isChecked;
		if (id) {
			vm.releveBons
				.filter((e) => e.idLink == id)
				.forEach(function (element) {
					element.isChecked = value;
				}, this);
		}
	}

	async function generer() {
		const ArrayTickets = vm.releveBons.filter((e) => e.isChecked);
		const tickets = [];
		ArrayTickets.forEach(function (element) {
			tickets.push({
				idTickets: element.idTickets,
				codeClient: element.codeClient,
				client: element.client,
				chantier: element.chantier,
				Mail: 'Test'
			});
		}, this);

		if (tickets.length > 0) {
			startLoading();
			const fileName = Date.now();
			const resultat = await ReleveBonsService.print(
				tickets,
				fileName,
				vm.searchingFormReleveBons.societeComIdSelected,
				vm.searchingFormReleveBons.siteComIdSelected,
				vm.searchingFormReleveBons.dateDebut,
				vm.searchingFormReleveBons.dateFin,
				vm.searchingFormReleveBons.entreeSortie,
				vm.searchingFormReleveBons.achatVente
			);

			const data = resultat.data;
			let headers = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', fileName + '.zip');

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				//console.log(ex);
			} finally {
				stopLoading();
			}
		}
	}

	function openSettings() {
		vm.state.go('releve-bons.print');
	}
}
