import Offre from './offre.model';
import OffreEntete from '../offre-entete/offre.entete.model';
import OffreDemande from '../offre-demande/offre.demande.model';
import OffreProposition from '../offre-proposition/offre.proposition.model';
import { EnumEtatOffre } from '../../enum.etat.offre';
import angular from 'angular';

export default class OffreFormController {
	/* @ngInject */

	constructor(
		$scope,
		$state,
		$stateParams,
		OffresCommunicationService,
		OffresService,
		notification,
		ModalService,
		$location,
		$anchorScroll,
		$translate,
		$uibModal,
		moment,
		TemplateImpressionService,
		MassiaAuthService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.OffresCommunicationService = OffresCommunicationService;
		this.OffresService = OffresService;
		this.notification = notification;
		this.ModalService = ModalService;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.licenceGest = __configuration.licenceGest;
		this.moment = moment;
		this.TemplateImpressionService = TemplateImpressionService;
		this.MassiaAuthService = MassiaAuthService;
		this.isUserCanModifyOffer = true;
		this.isUserCanModifyEntete = true;
	}

	async $onInit() {
		this.personnes = [];
		const _this = this;
		this.$scope.$watch('$ctrl.offre.demande.idSociete', function () {
			_this.loadPersonnes();
		});

		this.offre = {
			entete: {},
			demande: {}
		};

		this.ongletOpen = {
			isDemandeOpen: true,
			isEnteteOpen: true,
			isPropositionOpen: false
		};
		this.dateDemandeCreation = null;
		await this.setOffre();
		this.offre.haveCommand = false;
		angular.forEach(
			this.offre.entete.propositions,
			function (propositon) {
				if (propositon.codeCommande != null) {
					this.offre.haveCommand = true;
				}
			},
			this
		);

		if (this.offre.entete.id) {
			this.offre.caracteristiques = await this.OffresService.getOffreValeursCaracteristiquesAssociationsById(this.offre.entete.id);
		} else {
			this.offre.caracteristiques = [];
		}

		await this.getTemplates();
	}

	async loadPersonnes() {
		if (this.offre.demande.idSociete) {
			this.personnes = await this.OffresService.getPersonnes(this.offre.demande.idSociete);
		}
	}

	async setOffre() {
		if (this.$stateParams.id) {
			this.startLoading();
			try {
				this.isEditMode = true;
				const offreEntete = await this.getOffreEntete(this.$stateParams.id);
				await this.setOffreEntete(offreEntete);
				await this.setOffreDemande(offreEntete);
			} catch (ex) {
				if (ex.status === 404) {
					this.$state.go('offres.list', {}, { reload: true });
				}
				this.notification.error(ex.data);
			} finally {
				this.isFranco = await this.OffresService.getFranco(this.offre.demande.client.id, this.offre.demande.idSociete);
				this.stopLoading();
			}
		} else {
			this.isEditMode = false;
			this.offre = new Offre();
			const today = this.moment().format('DD/MM/YYYY');
			this.offre.demande.demandeDateCreation = today;
			this.offre.demande.demandeDateDebut = today;
			this.offre.entete.dateCreation = today;
			const lastDay = this.moment().endOf('year').format('DD/MM/YYYY');
			this.offre.demande.demandeDateFin = lastDay;
			this.offre.demande.demandeDateValidite = lastDay;
			this.offre.entete.dateValidite = lastDay;
		}
		this.getCodeAuto();
		this.offre.devise = '€';
	}

	async getOffreEntete(offreId) {
		const entete = await this.OffresService.getOffreEnteteById(offreId);
		return entete;
	}

	async setOffreEntete(offreEntete) {
		this.offre.entete = new OffreEntete(offreEntete);
		//Check si loffre peut etre modifier dans l'etat VALIDEE
		this.canModifyOffre();
	}

	async getCodeAuto() {
		try {
			this.offre.entete.estCodeAuto = await this.OffresService.estCodeAuto();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async setOffreDemande(offreEntete) {
		this.offre.demande = new OffreDemande(offreEntete);
	}

	onUpdateCaracteristiques(caracteristiques) {
		this.offre.caracteristiques = caracteristiques;
	}

	getModalDemandeValidation(idOffre, proposition) {
		this.$uibModal
			.open({
				template: '<offre-resultat-validation source="$ctrl.resultat" modal-instance="$ctrl.uibModalInstance"></offre-resultat-validation>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.resultat = proposition?.resultatValidation;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xl'
			})
			.result.then(async (result) => {
				try {
					await this.OffresService.requestValidationOffre({
						idOffre,
						idsociete: this.offre.demande.idSociete,
						idProposition: proposition.id,
						idValidateurs: result.idValidateurs,
						validations: result.validations
					});
				} catch (ex) {
					return this.notification.error(ex.data);
				} finally {
					this.backToPreviousState();
				}
			});
	}

	async confirmer() {
		if (this.checkValidity()) {
			const result = await this.enregistrer();
			if (result !== false) {
				this.$state.go('offres.edit', { id: result });
			}
		} else {
			this.notification.error(this.$translate.instant('VALIDATION_FORM_NOT_VALID'));
		}
	}

	async confirmerEtFermer() {
		if (this.checkValidity()) {
			this.enregistrer();
			this.backToPreviousState();
		} else {
			this.notification.error(this.$translate.instant('VALIDATION_FORM_NOT_VALID'));
		}
	}

	async enregistrer() {
		let result = false;
		if (this.validateOffre()) {
			if (this.$stateParams.id) {
				this.updateOffre();
				return this.offre.entete.id;
			}
			result = await this.createOffre();
		}

		return result;
	}

	async updateOffre() {
		try {
			await this.OffresService.updateOffre(this.offre);
		} catch (ex) {
			this.notification.error('OFFRES.NOT_UPDATED');
			return;
		}

		this.notification.success('OFFRES.UPDATED');
		this.$onInit();
	}

	validateOffre() {
		/*
		 * Verification de la quantité ADO 4415
		 */
		let validate = true;
		for (let i = 0; i < this.offre.entete.propositions.length; i++) {
			const prop = this.offre.entete.propositions[i];
			for (let j = 0; j < prop.producteurs.length; j++) {
				const producteur = prop.producteurs[j];
				for (let k = 0; k < producteur.produits.length; k++) {
					const produit = producteur.produits[k];
					if (!produit.quantite) {
						validate = false;
					}
				}
			}
		}

		if (!validate) {
			this.notification.error('OFFRES.VALIDATIONS.QUANTITY.NULL');
		}

		return validate;
	}

	async createOffre() {
		let result = null;
		try {
			result = await this.OffresService.createOffre(this.offre);
		} catch (ex) {
			this.notification.error('OFFRES.NOT_CREATED');
			return result;
		}

		this.notification.success('OFFRES.CREATED');
		return result;
	}

	openFirstOnglet() {
		this.ongletOpen = {
			isEnteteOpen: true,
			isDemandeOpen: true,
			isPropositionOpen: true
		};
	}

	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false,
			isDemandeOpen: false,
			isPropositionOpen: false
		};
	}

	annuler() {
		this.backToPreviousState();
	}

	backToPreviousState() {
		this.$state.go('offres.list');
	}

	checkValidity() {
		this.closeAllOnglets();
		let validity = true;
		let firstScroll = true;

		if (!this.offre.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}
			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('offreEnteteValidations');
		}

		if (!this.offre.demande.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('demande');
				firstScroll = false;
			}

			this.ongletOpen.isDemandeOpen = true;
			validity = false;
			this.$scope.$broadcast('offreDemandeValidations');
		}

		this.ongletOpen.isEnteteOpen = true;
		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	async addProposition() {
		const proposition = new OffreProposition();
		if (!this.$stateParams.id && this.offre.demande.client) {
			this.isFranco = await this.OffresService.getFranco(this.offre.demande.client.id, this.offre.demande.idSociete);
		}
		proposition.isFranco = this.isFranco;
		this.offre.entete.propositions.push(proposition);
	}

	async printOld(isValide, isDevis, idProposition = null) {
		const vm = this;

		let title = 'OFFRES.PRINTCHECKED.TITLE';
		let msg = 'OFFRES.PRINTCHECKED.MESSAGE';
		if (!isDevis) {
			title = 'OFFRES.PRINTCHECKED.TITLE_PROPOSITION';
			msg = 'OFFRES.PRINTCHECKED.MESSAGE_PROPOSITION';
		}

		const modalInstance = this.ModalService.confirm({
			modalTitle: title,
			modalMsg: msg,
			headerClass: 'modal-warning'
		});
		modalInstance.result.then(async function () {
			const fileName = Date.now();
			const resultat = await vm.OffresService.printOld(vm.$stateParams.id, fileName, isValide, isDevis, idProposition);

			const data = resultat.data;
			let headers = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', fileName + '.pdf');

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				console.log('🔥: printOld -> ex', ex);
			}
		});
	}

	async print(isValide, isDevis, idProposition = null, isOld = false) {
		if (idProposition) {
			await this.getValidateProposition(idProposition);
			await this.printCommand(isValide, isDevis, idProposition, isOld);
		} else {
			const isValid = await this.verifPrint();
			if (isValid) {
				await this.printCommand(isValide, isDevis, idProposition, isOld);
			}
		}
	}

	async verifPrint() {
		await Promise.all(
			this.offre.entete.propositions.map(async (propositon) => {
				await this.getValidateProposition(propositon.id);
				if (this.isValidateProposition == false) {
					return false;
				}
			})
		);
		return true;
	}

	async printCommand(isValide, isDevis, idProposition, isOld) {
		if (this.isValidateProposition) {
			try {
				if (isOld) {
					return this.printOld(isValide, isDevis, idProposition);
				}
				const res = await this.TemplateImpressionService.downloadModal('offres');
				if (res === 'cancel') {
					throw res;
				}
				this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
					autoDismiss: false
				});
				const template = await this.OffresService.print(this.$stateParams.id, res.filename, isValide, isDevis, idProposition);
				template.pdf = res.pdf;
				await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'offres');
			} catch (err) {
				if (err !== 'cancel') {
					this.notification.error(err.data);
				}
			}
		}
	}

	async command(idProposition, idPersonneSuivi) {
		await this.getValidateProposition(idProposition); //WORKFLOW SOMECA VALIDATION D'OFFRE
		if (this.isValidateProposition) {
			this.$state.go('offres.commande', { id: idProposition, personneId: idPersonneSuivi });
		}
	}

	async getValidateProposition(idProposition) {
		this.isValidateProposition = true;
		const confVal = await this.OffresService.getConfigValidationDevis();
		const isConfigValidationDevisActive = confVal !== null ? confVal.isActif : false;

		const proposition = this.offre.entete.propositions.find((x) => x.id === idProposition);

		const isOffreValid = [EnumEtatOffre.EN_COURS, EnumEtatOffre.REFUSEE].includes(this.offre.entete.etat);

		const hasValidations = proposition?.resultatValidation?.validations.length > 0;
		const isValidationDevisActive = isConfigValidationDevisActive;

		const idOffre = this.offre.entete.id;
		const user = this.MassiaAuthService.getAuthenticatedUser();
		//si loffre est en EN_COURS et que le user est du groupe validateur, on affiche la modale d'information
		if (isOffreValid && this.offre.entete.validateur.some((x) => x.id === user.id)) {
			this.isValidateProposition = false;
			this.isValidateProposition = await this.getConfirmationValidation(idOffre, proposition);
		} else if (isOffreValid && hasValidations && isValidationDevisActive) {
			this.isValidateProposition = false;
			this.getModalDemandeValidation(idOffre, proposition);
		}
	}

	//renvois la reponse du validateur
	async responseValidation(isValidate) {
		const proposition = this.offre.entete.propositions.find((x) => x.validation !== null && typeof x.validation !== 'undefined');
		const data = { isValidate, idOffre: this.offre.entete.id, idProposition: proposition.id };
		try {
			await this.OffresService.responseValidationOffre(data);
			this.backToPreviousState();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	//Dans l'etat à valider, lentete peux etre modifié par le demandeur et le validateur
	canModifyOffre() {
		const user = this.MassiaAuthService.getAuthenticatedUser();
		const { entete } = this.offre;
		const { idPersonneDemandeValidation, validateur, etat } = entete;

		//Si l'etat est different de 6 (A_VALIDER), loffre peut etre modifié
		this.isUserCanModifyOffer = etat !== EnumEtatOffre.A_VALIDER;

		//Mais dans l'etat VALIDEE (2), loffre ne peut etre modifié que par le demandeur et les validateurs
		if (etat === EnumEtatOffre.VALIDEE) {
			this.isUserCanModifyOffer = validateur.some((x) => x.id === user.id) || user.id === idPersonneDemandeValidation;
		}

		//Dans l'etat A_VALIDER (6), seulement l'entete peut etre modifié par le demandeur et les validateurs
		if (etat === EnumEtatOffre.A_VALIDER) {
			this.isUserCanModifyEntete = validateur.some((x) => x.id === user.id) || user.id === idPersonneDemandeValidation;
		}
	}

	async dupliquer() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant('OFFRES.DUPLICATE.TITLE', {
				code: this.offre.entete.code
			}),
			modalMsg: this.$translate.instant('OFFRES.DUPLICATE.MESSAGE'),
			headerClass: 'modal-info'
		});

		const that = this;
		modalInstance.result.then(async function () {
			try {
				const duplicatedOffreId = await that.OffresService.duplicateOffre(that.$stateParams.id);
				that.$state.go('offres.edit', { id: duplicatedOffreId });
			} catch (ex) {
				console.log('🔥: OffreFormController -> dupliquer -> ex', ex);
			}
		});
	}

	async exportTemplate(idTemplate) {
		const vm = this;

		const title = 'OFFRES.EXPORT_TEMPLATE_CHECKED.TITLE';
		const msg = 'OFFRES.EXPORT_TEMPLATE_CHECKED.MESSAGE';

		const modalInstance = this.ModalService.confirm({
			modalTitle: title,
			modalMsg: msg,
			headerClass: 'modal-warning'
		});
		modalInstance.result.then(async function () {
			const fileName = vm.offre.entete.fileName;
			vm.startLoading();
			try {
				const resultat = await vm.OffresService.exportTemplate(vm.$stateParams.id, fileName, idTemplate);

				const data = resultat.data;
				let headers = resultat.headers;

				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');

				const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', fileName);

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				vm.notification.error('DOCUMENTS.MUST_XLSM');
			} finally {
				vm.stopLoading();
			}
		});
	}

	ajouterTemplate() {
		this.$state.go('documents.newForTemplate', {
			idOffre: this.$stateParams.id
		});
	}

	async getTemplates() {
		this.templates = await this.OffresService.getTemplates();
	}

	async deleteTemplate(id) {
		if (id) {
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('OFFRES.DELETE_TEMPLATE.TITLE'),
				modalMsg: this.$translate.instant('OFFRES.DELETE_TEMPLATE.MESSAGE'),
				headerClass: 'modal-danger'
			});
			const that = this;
			modalInstance.result.then(async function () {
				try {
					await that.OffresService.deleteTemplate(id);
					that.notification.success(that.$translate.instant('OFFRES.DELETE_TEMPLATE.SUCCESS'));
					that.getTemplates();
				} catch (ex) {
					that.notification.error(ex.data);
				}
			});
		}
	}

	async openTemplate() {
		const _this = this;
		_this.idOffre = this.$stateParams.id;
		this.$uibModal
			.open({
				template: '<document-form id-offre="$ctrl.idOffre" modal-instance="$ctrl.uibModalInstance"></document-form>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.idOffre = _this.idOffre;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(async function (result) {
				await _this.getTemplates();
				_this.openSecondTemplate(result);
			});
	}

	async openSecondTemplate(id) {
		const that = this;
		that.idDoc = id;
		this.$uibModal
			.open({
				template: '<document-form docfromoffre="$ctrl.docfromoffre" modal-instance="$ctrl.uibModalInstance"></document-form>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.docfromoffre = that.idDoc;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(async function () {});
	}

	openHelper() {
		this.$uibModal
			.open({
				animation: true,
				size: 'lg',
				component: 'offreImpressionHelper'
			})
			.result.then(async function () {});
	}

	cancel() {
		this.modalInstance.dismiss();
	}
	async getConfirmationValidation(idOffre, proposition) {
		const callback = this.$uibModal
			.open({
				template: `
				<div class="modal-header">
					<h2 class="modal-title" id="modal-title">
						<span translate="OFFRES.RESULTAT_VALIDATION.TITLE"></span>
					</h2>
				</div>
				<div class="modal-body" id="modal-body">
					<div ng-if="!$ctrl.loading">
					<span
                    class="field-label"
                    title="{{'OFFRES.RESULTAT_VALIDATION.DEMANDE_CONFIRMATION_VALIDATION' | translate}}"
                    translate="OFFRES.RESULTAT_VALIDATION.DEMANDE_CONFIRMATION_VALIDATION"
                >
                </span>
					</div>
					<div class="modal-footer">
						<button class="btn btn-primary" type="button" ng-click="$ctrl.validate()">
							<span translate="VALIDER"></span>
						</button>
						<button class="btn btn-primary" type="button" ng-click="$ctrl.close()">
							<span class="massia-icon-door_exit"></span>
							<span class="hidden-xs" translate="FERMER"></span>
						</button>
					</div>
				</div>`,
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.close = () => $uibModalInstance.dismiss('cancel'); // Utilisez dismiss pour fermer la boîte de dialogue
						$ctrl.isValidationSuccessful = false; // Variable pour suivre l'état de validation
						$ctrl.validate = () => {
							$ctrl.isValidationSuccessful = true; // ou false en fonction du résultat de la validation
							$uibModalInstance.close($ctrl.isValidationSuccessful); // Ferme la boîte de dialogue en renvoyant le résultat
						};
					}
				],
				controllerAs: '$ctrl',
				size: 'xl',
				animation: true
			})
			.result.then((isValidate) => {
				if (isValidate) {
					const data = { isValidate, idOffre, idProposition: proposition.id };
					this.OffresService.responseValidationOffre(data);
					this.offre.entete.etat = EnumEtatOffre.VALIDEE;
					return isValidate;
				}
			});
		return callback;
	}
}
