import { Component } from '../../../core/decorators';
import eraseFiltersTemplate from './massia.erase.filters.tpl.html';

@Component({
	selector: 'massia-erase-filters',
	template: eraseFiltersTemplate
})
export class MassiaEraseFiltersController {
	constructor($translate) {
		this.$translate = $translate;
	}

	$onInit() {}

	eraseAllFilters() {
		//Récupération des boutons de filtres de la table
		const eraseButtons = $('.btn-erase');
		if (eraseButtons && eraseButtons.length > 0) {
			for (let index = 0; index < eraseButtons.length; index++) {
				const button = eraseButtons[index];
				//S'il n'est pas caché c'est qu'un filtre a été mis, on simule un clic dessus pour le traitement interne de la table
				if (!button.classList.contains('ng-hide')) button.click();
			}
		}
	}
}

MassiaEraseFiltersController.$inject = ['$translate'];
