import * as _ from 'lodash';
import prestaLiesEntree from './prestations.liees.entrees.html';
import prestaLiesSortie from './prestations.liees.sorties.html';

export default class PrestationsLieesController {
	static $inject = [
		'$state',
		'$stateParams',
		'CentreGestionService',
		'ModalService',
		'notification',
		'$translate',
		'$filter',
		'_',
		'$templateCache'
	];

	constructor($state, $stateParams, CentreGestionService, ModalService, notification, $translate, $filter, _, $templateCache) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.CentreGestionService = CentreGestionService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this._ = _;
		this.eligible = ['prestationsLiees'];
		$templateCache.put('prestation-liee-entree.html', prestaLiesEntree);
		$templateCache.put('prestation-liee-sortie.html', prestaLiesSortie);
	}

	async $onInit() {
		this.codeParametre = this.parametre.codeParametre;
		if (this.eligiblePrestationsLiees(this.codeParametre)) {
			this.fullyHerited = [false, false];
			this.changedValue = [false, false];
			await this.getElementsSelectionnables(this.codeParametre);
		}
	}

	eligiblePrestationsLiees(codeParametre) {
		return this.eligible.indexOf(codeParametre) !== -1 ? true : false;
	}

	//region Récupération et preparation
	// /**
	//  *  INFOS  Il s'agit de la mise en forme des élements qui
	//  * PEUVENT être selectionnés
	// */
	prepareListeElementsLies(elementsATraiter) {
		const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'prestationLibelle');
		const temp = [];
		for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			const currentValue = elementsSelectionnablesOrdonnes[index];
			const toReturn = [];
			if (Object.prototype.hasOwnProperty.call(currentValue, 'prestationId')) {
				const newlist = {
					valeur: false,
					prestationId: currentValue.prestationId,
					libelle: currentValue.prestationLibelle,
					entreeSortie: null,
					elementCode: 'prestaLie',
					elementId: 8,
					elementType: 'int',
					elementDomaine: 'prestation',
					produitId: null,
					transportId: null,
					producteurId: null,
					achatVente: null,
					id: null,
					societeId: null,
					siteCommercialId: null,
					transporteurId: null,
					chantierId: null,
					benneId: null,
					clientId: null,
					tvaId: null,
					paysId: null,
					dateApplication: null,
					disabled: false
				};
				if (this.frontFilters) {
					const pourFiltre = this.getPropFiltre(this.frontFilters);
					newlist[this.getProperty(pourFiltre.domaine)] = pourFiltre.id;
				}
				toReturn.push(newlist);
			}
			temp.push(toReturn);
		}
		const sorties = _.flatten(temp);
		for (let i = 0; i < sorties.length; i++) {
			const sortie = sorties[i];
			sortie.entreeSortie = 1;
		}
		const entrees = angular.copy(sorties);
		for (let i = 0; i < entrees.length; i++) {
			const entree = entrees[i];
			entree.entreeSortie = 0;
		}
		return new Promise((resolve) => resolve([entrees, sorties]));
	}

	/**
	 * INFOS  Récupère les élements séléctionnables
	 */
	async getElementsSelectionnables() {
		if (
			(this.domaine == 'SiteUtil' && this.frontFilters.siteCommercial) ||
			(this.domaine == 'Societes' && this.frontFilters.societes) ||
			((this.domaine != 'Societes' || this.domaine != 'SiteUtil' || this.domaine != 'Produits') && this.filtresAppliques == true)
		) {
			// this.monFiltreElementsSelectionnables = this.buildFilters();

			try {
				// la récupération via getPrestationsSelectionnables() se fait maintenant dans le parent car utilisé dans la liste des Taux de TVA
				this.listes.prestationsSelectionnables = await this.CentreGestionService.getPrestationsSelectionnables(this.filterToBack);
				this.listes.prestationsLiees = await this.prepareListeElementsLies(this.listes.prestationsSelectionnables);
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.setSelection(this.listes.prestationsLiees, this.parametre, true);
			}
		}
	}
	//endregion

	setSelection(listeSelectionnables, parametre, disableable = false) {
		// INFOS Entrées ET sorties
		for (let es = 0; es < 2; es++) {
			const nombreValeurs = parametre.donnees[es].valeurs.length;
			let cpt = 0;
			for (let i = 0; i < nombreValeurs; i++) {
				const selected = parametre.donnees[es].valeurs[i];
				const index = this._.findIndex(listeSelectionnables[es], {
					prestationId: selected.prestationId,
					entreeSortie: selected.entreeSortie
				});
				if (index != -1) {
					listeSelectionnables[es][index].valeur = true;
					listeSelectionnables[es][index].id = selected.id;
					if (disableable) {
						listeSelectionnables[es][index].disabled = selected.isHerited;
						cpt++;
					} else {
						listeSelectionnables[es][index].disabled = false;
					}
				}
			}

			this.fullyHerited[es] = cpt == nombreValeurs && nombreValeurs > 0 ? true : false;
		}
	}

	async refreshLabels() {
		let newParameters = await this.CentreGestionService.getParametersWithFilter(this.domaine, this.source.id, this.filterToBack);
		let factuParam = newParameters.find((p) => p.codeGroupe == 'FACTU');
		if (factuParam) this.parametre = factuParam.parametres.find((p) => p.codeParametre == this.codeParametre && p.id == this.parametre.id);
	}

	/**
	 *  INFOS  Gère l'enregistrement des produits
	 * en cas de selection
	 */
	async istevenOnClick(data, selectionnables, es) {
		const index = _.findIndex(this.listes[selectionnables][es], {
			prestationId: data.prestationId
		});

		if (this.filterToBack) {
			const entries = Object.entries(this.filterToBack);
			for (let i = 0; i < entries.length; i++) {
				data[entries[i][0]] = entries[i][1];
			}
		}
		data.codeDomaine = this.domaine;
		data.entreeSortie = es;

		if (data.valeur == true) {
			// INFOS  Insertion
			try {
				if (this.domaine == 'Produits') {
					this.listes[selectionnables][es][index].produitId = this.source.id;
					data.produitId = this.source.id;
				} else {
					const prop = this.getProperty(this.domaine);
					data[prop] = this.source.id;
				}
				this.listes[selectionnables][es][index].valeur = true;

				const retour = await this.CentreGestionService.insert(data);
				this.listes[selectionnables][es][index].id = retour;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.changedValue[es] = true;
			}
		} else {
			// INFOS  DELETION
			try {
				this.retour = await this.CentreGestionService.effacer(data.id);
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.listes[selectionnables][es][index].valeur = false;
				this.listes[selectionnables][es][index].id = null;
				this.changedValue[es] = true;
			}
		}
		await this.refreshLabels();
	}

	async saveAllItem(selectionnables, es) {
		const elmToSave = [];
		const dataToAdd = {};
		let cptOK = 0;
		let cptNOK = 0;

		if (this.filterToBack) {
			const entries = Object.entries(this.filterToBack);
			for (var i = 0; i < entries.length; i++) {
				dataToAdd[entries[i][0]] = entries[i][1];
			}
		}
		elmToSave.push(dataToAdd);

		for (let i = 0; i < this.listes[selectionnables][es].length; i++) {
			const element = this.listes[selectionnables][es][i];

			if (element.msGroup == true || element.msGroup == false) {
				// on ne prend pas la ligne
			} else {
				//id != null implique que l'élément est déjà une valeur propre ou héritée
				if (element.id == null && element.valeur) {
					const prop = this.getProperty(this.domaine);
					element[prop] = this.source.id;
					element.codeDomaine = this.domaine;
					elmToSave.push(element);
				}
			}
		}

		if (elmToSave.length < 2)
			//Le premier n'est pas un élément mais des infos de liens. S'il est seul il n'y a pas nécessité de sauvegarde
			return;

		try {
			if (elmToSave.length > 30) {
				this.savingElements = true;
			}
			await this.CentreGestionService.insertMultiple(elmToSave);
			this.savingElements = false;
			cptOK++;
		} catch (err) {
			cptNOK++;
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.notification.success(this.$translate.instant('CENTRE_GESTION.MESSAGES.SAVED', { nombre: cptOK }));

			if (this.cptNOK > 0) {
				this.notification.error(this.$translate.instant('CENTRE_GESTION.MESSAGES.NOT_SAVED', { nombre: cptNOK }));
			}
		}
		this.onUpdate();
	}

	async upsertElement(objet, es, groupIndex) {
		let element;
		let parent;
		if (this.parametre.donnees.length > 1) {
			element = this.parametre.donnees[es].valeurs;
			parent = this.parametre.donnees[es].parent;
		} else {
			element = this.parametre.donnees[0].valeurs;
			parent = this.parametre.donnees[0].parent;
		}

		for (let i = 0; i < element.length; i++) {
			const valeur = element[i];
			if (this.frontFilters) {
				this.monFiltre = {};
				const pourFiltre = this.getPropFiltre(this.frontFilters);
				valeur[this.getProperty(pourFiltre.domaine)] = pourFiltre.id;
			}
			try {
				if (this.domaine == 'SiteUtil') {
					valeur.societeId = null;
				} else if (this.domaine == 'Chantiers') {
					valeur.clientId = null;
				}
				const prop = this.getProperty(this.domaine);
				valeur[prop] = this.source.id;

				if (parent != null) {
					valeur.id = null;
					const nouvelId = await this.CentreGestionService.insert(valeur);
					this.parametre.donnees[es].valeurs[i].id = nouvelId;
					this.parametre.donnees[es].parent = null;
				} else {
					// TODO-EFFACER Effacer le console.log
					//('Should update', parent, valeur);
					// this.retour = await this.CentreGestionService.update(element);
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.changedValue[es] = true;
				this.activeTab = groupIndex;
			}
		}
	}

	async reinit(selectionnables, es, fromEraser = false) {
		const elmToDelete = [];
		for (let i = 0; i < this.listes[selectionnables][es].length; i++) {
			const element = this.listes[selectionnables][es][i];
			//S'il est disable c'est un héritage on supprime pas
			if (element.id != null && !element.disabled && (fromEraser || !element.valeur)) {
				//eraser || !valeur permet de différencier la gomme et le removeAll de la popup
				element.valeur = false;
				elmToDelete.push(element.id);
			}
		}

		try {
			await this.deleteMultipleAction(elmToDelete);
			this.onDelete();
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.onDelete();
		}
	}

	deleteMultipleAction(ids) {
		try {
			this.CentreGestionService.deleteMultiple(ids);
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	getPropFiltre(filtres) {
		const keyNames = Object.keys(filtres);
		let id = 0;
		for (let i = 0; i < keyNames.length; i++) {
			switch (keyNames[i]) {
				case 'SiteCommercial':
				case 'siteCommercial':
					if (this.frontFilters.siteCommercial) {
						this.domaineFiltre = 'SiteCommercial';
						id = this.frontFilters.siteCommercial.id;
					}
					break;
				case 'Societes':
				case 'societes':
					if (this.frontFilters.societes) {
						this.domaineFiltre = 'Societes';
						id = this.frontFilters.societes.id;
					}
					break;
				case 'siteTransporteur':
					// this.domaineFiltre = "SiteTransporteur";
					// var id = this.frontFilters.siteTransporteur.id
					break;
				case 'siteClient':
					if (this.frontFilters.siteClient) {
						this.domaineFiltre = 'Client';
						id = this.frontFilters.siteClient.id;
					}
					break;
				case 'typeClient':
					// this.domaineFiltre = "";
					// var id = this.frontFilters.typeClient
					break;
				case 'chantier':
					if (this.frontFilters.chantier) {
						this.domaineFiltre = 'Chantier';
						id = this.frontFilters.chantier.id;
					}
					break;
				default:
				// this.domaineFiltre = "";
				// var id = this.frontFilters.dateApplication
			}
		}
		return {
			domaine: this.domaineFiltre,
			id: id
		};
	}

	getProperty(domaine) {
		switch (domaine) {
			case 'Societes':
			case 'societes':
				return 'societeId';
			case 'SiteCommercial':
			case 'siteCommercial':
			case 'SiteUtil':
				return 'siteCommercialId';
			case 'chantier':
			case 'chantiers':
			case 'Chantiers':
				return 'chantierId';
			case 'Produits':
				return 'produitId';
			case 'SiteClient':
				return 'clientId';
			case 'Pays':
				return 'paysId';
			case 'TypeTransport':
				return 'transportId';
		}
	}
	startLoading() {
		this.loading = true;
	}
	stopLoading() {
		this.loading = false;
	}
	startCodeLoading() {
		this.codeLoading = true;
	}
	stopCodeLoading() {
		this.codeLoading = false;
	}
}
