import ClientDivers from './client.divers.model';
import ClientDiversEntete from '../client-divers-entete/client.divers.entete.model';

export default class ClientDiversFormController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'ClientsDiversCommunicationService',
		'ClientsDiversService',
		'notification',
		'$location',
		'$anchorScroll',
		'ProspectsService',
		'MOPService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		ClientsDiversCommunicationService,
		ClientsDiversService,
		notification,
		$location,
		$anchorScroll,
		ProspectsService,
		MOPService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ClientsDiversCommunicationService = ClientsDiversCommunicationService;
		this.ClientsDiversService = ClientsDiversService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.ProspectsService = ProspectsService;
		this.MOPService = MOPService;
	}

	$onInit() {
		this.MOPService.setMop([{ title: 'CLIENTSDIVERS.BREADCRUMBS.CLIENTSDIVERS_LIST', filename: 'ClientsDivers.pdf', application: 'gestion' }]);
		this.isEditMode = false;
		/**
		 * @type ClientDivers
		 */
		this.clientDivers = {};
		this.loading = false;

		this.closeAllOnglets();
		this.openFirstOnglet();
		this.setClientDivers(this.$stateParams.id);

		if (!this.fromOffreCommande) {
			this.fromOffreCommande = false;
		}
	}

	$onDestroy = () => {
		this.MOPService.resetMop();
	};

	async setClientDivers(clientDiversId) {
		if (clientDiversId && !this.fromOffreCommande) {
			this.startLoading();
			try {
				this.isEditMode = true;
				await this.setClientDiversEntete(clientDiversId);
			} catch (ex) {
				if (ex.status === 404) {
					this.$state.go('clients-divers.list', {}, { reload: true });
				}
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		} else {
			this.isEditMode = false;
			this.clientDivers = new ClientDivers();
			if (this.prospect && this.fromOffreCommande) {
				this.setClientFromProspect(this.prospect);
			}
		}
	}

	setClientFromProspect(prospect) {
		this.clientDivers.entete = {
			idSociete: prospect.societeId,
			idClientGenerique: prospect.idClientGenerique,
			adresse: prospect.adresse,
			civilite: prospect.civilite,
			code: prospect.code,
			codePostal: prospect.codePostal,
			commentaire: prospect.commentaire,
			fax: prospect.fax,
			idPays: prospect.paysId,
			isBloquagePesee: prospect.isBloquagePesee,
			isDematBLMail: prospect.isDematBLMail,
			isDematBLSMS: prospect.isDematBLSMS,
			isEnabled: prospect.isEnabled,
			isOuverture: prospect.isOuverture,
			libelle: prospect.libelle,
			mail: prospect.email,
			telephone: prospect.telephone,
			telephoneMobile: prospect.telephoneMobile,
			type: prospect.type,
			ville: prospect.ville,
			web: prospect.web
		};
	}

	async setClientDiversEntete(clientDiversId) {
		const entete = await this.ClientsDiversService.getClientDiversEnteteById(clientDiversId);
		this.clientDivers.entete = new ClientDiversEntete(entete);
	}

	async sauvegarder() {
		if (this.checkValidity()) {
			this.startLoading();
			try {
				let id = false;
				if (this.clientDivers.entete.id) {
					await this.ClientsDiversService.updateEnteteClientDivers(this.clientDivers.entete);
					this.notification.success('CLIENTSDIVERS.UPDATED');
					id = this.clientDivers.entete.id;
				} else {
					id = await this.ClientsDiversService.createClientDivers(this.clientDivers.entete);
					this.notification.success('CLIENTSDIVERS.CREATED');
				}
				return id;
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
		}
	}

	async valider() {
		const id = await this.sauvegarder();
		if (id && this.clientDivers.id) {
			this.reset();
		} else if (id) {
			this.$state.go('clients-divers.edit', { id: id });
		}
	}

	async validerEtFermer() {
		const success = await this.sauvegarder();
		if (success) {
			if (!this.fromOffreCommande) {
				this.fermer();
			} else {
				this.notification.success('OFFRES.TRANSFORMATION_REUSSI');
				this.modalInstance.close(success);
			}
		}
	}

	openFirstOnglet() {
		this.ongletOpen = {
			isEnteteOpen: true
		};
	}

	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false
		};
	}

	fermer() {
		if (this.fromOffreCommande === true) {
			this.modalInstance.close();
			return;
		}

		this.$state.go('clients-divers.list');
		return;
	}

	checkValidity() {
		this.closeAllOnglets();
		let validity = true;

		let firstScroll = true;
		if (!this.clientDivers.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('clientDiversEnteteValidations');
		}

		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
