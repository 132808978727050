import Norme from './norme.model';

export default class NormeFormController {
	/* @ngInject */
	constructor(
		$scope,
		$state,
		$stateParams,
		NormesCommunicationService,
		NormesService,
		ProduitsService,
		notification,
		$location,
		$timeout,
		MassiaApplicationService,
		$translate,
		$filter,
		FamillesService,
		MOPService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.NormesCommunicationService = NormesCommunicationService;
		this.NormesService = NormesService;
		this.ProduitsService = ProduitsService;
		this.notification = notification;
		this.$location = $location;
		this.itemsByPageOptions = [20, 100, 200];
		this.$timeout = $timeout;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this.$filter = $filter;
		this.FamillesService = FamillesService;
		this.MOPService = MOPService;
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'NORMES.BREADCRUMBS.NORMES_LIST', filename: 'Normes.pdf', application: 'laboratoire' }]);
		this.form = {
			entete: {},
			categories: {},
			specifications: {}
		};
		this.ongletOpen = {
			isEnteteOpen: true,
			isSpecificationsOpen: false,
			isCategoriesOpen: false
		};

		this.norme = {};
		this.specifications = [];
		this.categories = [];
		this.gammes = [];
		this.specData = [];
		this.familles = [];
		this.normesSpec = [];
		this.selectedSpecification = {};
		this.selectedTreeNode = {};
		this.isEditMode = false;
		this.newIndexCounter = 0;

		this.loading = false;
		this.unregisterReinit = this.NormesCommunicationService.registerReinit(() => this.initForm());
		this.application = this.MassiaApplicationService.getApplication();
		await this.initForm();
		await this.getModeCalculEcartType();
		await this.getCategories(this.$stateParams.id);
		await this.getFamilles();
		await this.getGammes();
		await this.loadSeriesTamis();

		if (this.norme.codeFamille) {
			await this.getNormesSpec(this.norme.codeFamille);
		}
	}

	$onDestroy() {
		this.unregisterReinit();
		this.MOPService.resetMop();
	}

	async initForm() {
		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
			await this.setNorme(this.$stateParams.id);
		} else {
			this.isEditMode = false;
		}
		if (this.application == 'gestion' && !this.norme.id) {
			this.norme.normeSpec = 1;
		} //marqué CE par défaut pour les docs CE en gestion
	}

	async setNorme(normeId) {
		if (normeId) {
			//this.startLoading();
			try {
				this.isEditMode = true;
				const stillThatNorme = await this.NormesService.getNormeById(normeId);
				this.norme = new Norme(stillThatNorme);
				this.specifications = await this.NormesService.getSpecifications(normeId);
				if (!this.specifications) {
					this.specifications = [];
				}

				if (this.norme && this.norme.idFamille >= 0) {
					this.gammes.filter((x) => {
						if (x.idFamille === null) {
							return true;
						} else if (x.idFamille >= 0 && x.idFamille === this.norme.idFamille) {
							return true;
						}
						return false;
					});
				}
			} catch (ex) {
				if (ex.status === 404) {
					if (this.MassiaApplicationService.getApplication() == 'gestion') {
						this.$state.go('normes.list', {}, { reload: true });
					} else {
						this.$state.go('normes-labo.list', {}, { reload: true });
					}
				}
				this.notification.error(ex.data);
			} finally {
				this.initSpecificationsTreeView();
			}
		} else {
			this.isEditMode = false;
		}
	}

	async getNormesSpec(famille) {
		this.normesSpec = await this.NormesService.getNormeSpecList(famille);
	}

	async getCategories(normeId) {
		try {
			this.categories = [];
			if (normeId) {
				this.categories = await this.NormesService.getCategories(normeId);
			}
			this.categories.splice(0, 0, { id: 0, categorie: '' });
		} catch (ex) {
			if (ex.status === 404) {
				this.$state.go('norme.categories.list', {}, { reload: true });
			}
			this.notification.error(ex.data);
		}
	}

	async selectFamille() {
		const famille = this.familles.find((x) => x.idFamille === angular.copy(this.norme.idFamille));

		this.norme.idFamille = famille.idFamille;
		this.norme.codeFamille = famille.code;
		this.norme.libelleFamille = famille.nom;

		if (this.norme.normeSpec) {
			this.norme.normeSpec = null;
		}

		await this.getNormesSpec(famille.code);
		await this.getGammes();
	}

	async selectItem(data, listNormesSpec) {
		if (data) {
			if (data.id !== this.norme.normeSpec) {
				this.norme.normeSpec = data.id;
			} else {
				listNormesSpec.map((e) => {
					if (data.id === this.norme.normeSpec) {
						return (e.selected = !e.selected);
					}
				});
				this.norme.normeSpec = null;
			}
		}
	}

	initSpecificationsTreeView() {
		this.specData = [];

		if (this.norme && this.specifications) {
			// for (let i = this.specifications.length - 1; i >= 0; i--) {
			//     const spec = this.specifications[i];

			//     if (spec.isDeleted)
			//         this.specifications.splice(i, 1);
			// }

			for (let i = 0; i < this.specifications.length; i++) {
				const spec = this.specifications[i];
				if (!spec.isDeleted) {
					if (Object.prototype.hasOwnProperty.call(spec, 'idCategorie')) {
						if (!spec.idCategorie) {
							spec.idCategorie = 0;
						}

						if (spec.idCategorie && this.norme.codeFamille === 'BPE') {
							//mode categorie 'ON'
							this.norme.hasCategorie = true;
						} else if (this.norme.codeFamille !== 'BPE') {
							//mode categorie 'ON'
							this.norme.hasCategorie = true;
						}
					}

					const treeChildSpec = {
						id: spec.idCategorie,
						label: spec.libelleCategorie ? spec.libelleCategorie : '', //(spec.ordreCategorie ? spec.ordreCategorie + ' ' : '') + (spec.libelleCategorie ? spec.libelleCategorie : ''),
						data: spec,
						level: 2
					};

					const specKey =
						(spec.petitD ? spec.petitD : 'null') +
						'_' +
						(spec.grandD ? spec.grandD : 'null') +
						'_' +
						(spec.idGamme && spec.idGamme > 0 ? spec.idGamme : 'null');
					let treeItemSpec = this.specData.find(function (e) {
						return e.id == specKey;
					});
					if (!treeItemSpec) {
						let specLabel = (spec.petitD ? 'd' + spec.petitD + '/' : '') + (spec.grandD ? 'D' + spec.grandD : '');
						if (specLabel) {
							specLabel += ' - ';
						}
						specLabel += spec.libelleGamme ? ' ' + spec.libelleGamme : '';

						treeItemSpec = {
							id: specKey,
							label: specLabel,
							data: spec,
							children: this.norme.hasCategorie && (treeChildSpec.id || treeChildSpec.id === 0) ? [treeChildSpec] : [],
							level: 1
						};

						if (treeItemSpec.label.endsWith('/')) {
							treeItemSpec.label = treeItemSpec.label.substring(0, treeItemSpec.label.length - 1);
						}
						if (treeItemSpec.label.startsWith(' - ')) {
							treeItemSpec.label = treeItemSpec.label.substring(3);
						}

						this.specData.push(treeItemSpec);
					} else if (this.norme.hasCategorie && (treeChildSpec.id || treeChildSpec.id === 0)) {
						treeItemSpec.children.push(treeChildSpec);
					}
				}
			}
		}
	}

	async loadSeriesTamis() {
		this.seriesTamis = [];

		try {
			this.seriesTamis = await this.NormesService.getSeriesTamis();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async checkCodeUnicity(code) {
		if (code) {
			this.startCodeLoading();

			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.norme.codeExists = await this.NormesService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		} else {
			this.norme.codeExists = null;
		}
	}

	// boutons de validation
	async sauvegarder() {
		if (this.checkValidity()) {
			this.startLoading();
			try {
				if (this.norme.id) {
					await this.updateNorme();
					if (this.specifications) {
						this.specifications = await this.NormesService.saveSpecifications(this.norme.id, this.specifications);
					}
					this.notification.success('NORMES.UPDATED');
					return this.norme.id;
				}
				const tmp = await this.createNorme();
				this.norme.id = tmp;

				if (this.categories) {
					await this.saveCategories();
				}
				if (this.specifications) {
					this.specifications = await this.NormesService.saveSpecifications(this.norme.id, this.specifications);
				}

				this.notification.success('NORMES.CREATED');
				return tmp;
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.initSpecificationsTreeView();
				this.stopLoading();
			}
		} else {
			return false;
		}
	}

	async valider() {
		console.log(angular.copy(this.norme));

		const returnedId = await this.sauvegarder();
		if (returnedId) {
			if (this.MassiaApplicationService.getApplication() == 'gestion') {
				this.$state.go('normes.edit', { id: returnedId });
			} else {
				this.$state.go('normes-labo.edit', { id: returnedId });
			}
		}
	}

	async validerEtFermer() {
		if ((await this.sauvegarder()) != false) this.backToPreviousState();
	}

	annuler() {
		this.backToPreviousState();
	}

	backToPreviousState() {
		if (this.MassiaApplicationService.getApplication() == 'gestion') {
			this.$state.go('normes.list');
		} else {
			this.$state.go('normes-labo.list');
		}
	}

	async createNorme() {
		this.startLoading();
		try {
			const returnedId = await this.NormesService.createNorme(this.norme);
			this.notification.success('NORMES.CREATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async updateNorme() {
		this.startLoading();
		try {
			const returnedId = await this.NormesService.updateNorme(this.norme);
			this.notification.success('NORMES.UPDATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
			this.reinit();
		} finally {
			this.stopLoading();
		}
	}

	reinit() {
		this.setNorme(this.$stateParams.id);
		this.NormesCommunicationService.raiseReinit();
	}

	checkValidity() {
		this.$scope.$broadcast('normeValidations');
		this.norme = new Norme(this.norme);
		return this.norme.isValid();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	async getModeCalculEcartType() {
		if (!this.modeCalculET) {
			try {
				this.modeCalculET = await this.NormesService.getmodeCalculET();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}

	addSpecification() {
		const spec = {};

		const specKey =
			(spec.petitD ? spec.petitD : 'null') +
			'_' +
			(spec.grandD ? spec.grandD : 'null') +
			'_' +
			(spec.idGamme && spec.idGamme > 0 ? spec.idGamme : 'null');
		const treeItemSpec = {
			id: specKey,
			label: '',
			data: spec,
			children: [],
			level: 1,
			isEdited: true
		};

		if (treeItemSpec.label.endsWith('/')) {
			treeItemSpec.label = treeItemSpec.label.substring(0, treeItemSpec.label.length - 1);
		}

		if (!this.specData) {
			this.specData = [];
		}
		if (!this.specifications) {
			this.specifications = [];
		}
		this.specData.push(treeItemSpec);
		//this.specifications.push(treeItemSpec.data);
	}

	editSpecification(b) {
		b.isEdited = true;
	}

	saveEdition(b, parent) {
		if (b) {
			if (b.level < 2) {
				const gamme = this.gammes.find(function (e) {
					return e.id === b.data.idGamme;
				});
				if (!b.data.libelleGamme || gamme) {
					let specification = this.specifications.find(
						(e) => e.petitD == b.data.petitD && e.grandD == b.data.grandD && e.idGamme == b.data.idGamme
					);

					if (!specification) {
						b.id = b.data.petitD + '_' + b.data.grandD + '_' + (b.data.idGamme && b.data.idGamme > 0 ? b.data.idGamme : 'null');
						b.data.idGamme = gamme ? gamme.id : -1;
						b.data.libelleGamme = gamme ? gamme.libelle : null;

						b.label = (b.data.petitD ? 'd' + b.data.petitD + '/' : '') + (b.data.grandD ? 'D' + b.data.grandD : '');
						if (b.label) {
							b.label += ' - ';
						}
						b.label += b.data.libelleGamme ? ' ' + b.data.libelleGamme : '';
						if (b.label.endsWith('/')) {
							b.label = b.label.substring(0, b.label.length - 1);
						}

						specification = {
							id: -1,
							caracteristique: null,
							essais:
								b.data.essais && b.data.essais.length > 0
									? angular.copy(b.data.essais).map((e) => {
											e.id = 0;
											return e;
										})
									: [{ IsAdding: true, id: 0, code: 0, position: -999 }],
							petitD: b.data.petitD,
							grandD: b.data.grandD,
							idGamme: b.data.idGamme,
							libelleGamme: b.data.libelleGamme,
							idNorme: this.norme.id,
							isDeleted: false
						};

						if (b.children && b.children.length > 0) {
							for (let i = 0; i < b.children.length; i++) {
								const child = b.children[i];
								this.newIndexCounter--;

								const s = angular.copy(specification);
								s.id = this.newIndexCounter;
								s.idCategorie = child.data.idCategorie;
								s.libelleCategorie = child.data.libelleCategorie;
								s.essais = angular.copy(child.data.essais).map((e) => {
									e.id = 0;
									return e;
								});

								this.specifications.push(s);
							}
						} else {
							this.newIndexCounter--;
							specification.id = this.newIndexCounter;

							this.specifications.push(specification);
						}
					} else {
						const specifications = this.specifications.filter(
							(e) => parent.id === e.petitD + '_' + e.grandD + '_' + (e.idGamme && e.idGamme > 0 ? e.idGamme : 'null')
						);
						for (let i = 0; i < specifications.length; i++) {
							specifications[i].petitD = b.data.petitD;
							specifications[i].grandD = b.data.grandD;
							specifications[i].idGamme = b.data.idGamme;
							specifications[i].codeGamme = b.data.codeGamme;
							specifications[i].libelleGamme = b.data.libelleGamme;
						}
					}

					delete b.isEdited;
					this.initSpecificationsTreeView();

					const specKey =
						(parent.data.petitD ? parent.data.petitD : 'null') +
						'_' +
						(parent.data.grandD ? parent.data.grandD : 'null') +
						'_' +
						(parent.data.idGamme && parent.data.idGamme > 0 ? parent.data.idGamme : 'null');
					const firstItemSpec = this.specData.find(function (e) {
						return e.id == specKey;
					});
					if (firstItemSpec) {
						firstItemSpec.expanded = true;
						this.onTreeItemSelected(firstItemSpec, true);
					}
				}
			} else {
				let idCat = 0;
				let libCat = '';
				if (b.data.idCategorie) {
					const cat = this.categories.find((e) => {
						return e.id === b.data.idCategorie;
					});
					idCat = cat.id;
					libCat = cat.categorie;
				}

				if (parent) {
					b.data.idCategorie = 0;

					if (this.specifications.length === 1 && !this.specifications[0].id) {
						this.specifications = [];
					}

					const specIndex = this.specifications.findIndex(function (e) {
						const specKey =
							(e.petitD ? e.petitD : 'null') +
							'_' +
							(e.grandD ? e.grandD : 'null') +
							'_' +
							(e.idGamme && e.idGamme > 0 ? e.idGamme : 'null');
						return specKey === parent.id && (!Object.prototype.hasOwnProperty.call(e, 'idCategorie') || e.idCategorie === idCat);
					});

					if (
						specIndex < 0 ||
						(Object.prototype.hasOwnProperty.call(this.specifications[specIndex], 'idCategorie') &&
							this.specifications[specIndex].idCategorie !== idCat)
					) {
						this.newIndexCounter--;

						this.specifications.push({
							id: this.newIndexCounter,
							caracteristique: null,
							essais: [{ IsAdding: true, id: 0, code: 0, position: -999 }],
							petitD: parent.data.petitD,
							grandD: parent.data.grandD,
							idGamme: parent.data.idGamme,
							libelleGamme: parent.data.libelleGamme,
							idCategorie: idCat,
							libelleCategorie: libCat,
							idNorme: this.norme.id,
							isDeleted: false
						});
					} else {
						this.specifications[specIndex].idCategorie = idCat;
						this.specifications[specIndex].libelleCategorie = libCat;
						this.specifications[specIndex].idNorme = this.norme.id;
						this.specifications[specIndex].isDeleted = false;
					}

					delete b.isEdited;
					this.initSpecificationsTreeView();

					const specKey =
						(parent.data.petitD ? parent.data.petitD : 'null') +
						'_' +
						(parent.data.grandD ? parent.data.grandD : 'null') +
						'_' +
						(parent.data.idGamme && parent.data.idGamme > 0 ? parent.data.idGamme : 'null');
					const firstItemSpec = this.specData.find(function (e) {
						return e.id == specKey;
					});
					if (firstItemSpec) {
						firstItemSpec.expanded = true;
						this.onTreeItemSelected(firstItemSpec, true);
					}
				}
			}
		}
	}

	deleteSpecification(b, index) {
		if (b && b.level < 2) {
			if (index >= 0) {
				this.specData.splice(index, 1);
				this.specifications.map(function (e) {
					if (e.petitD == b.data.petitD && e.grandD == b.data.grandD && e.idGamme == b.data.idGamme) {
						e.isDeleted = true;
						e.essais.map(function (s) {
							s.isDeleted = true;
						});
					}
				});
			}
		}
	}

	addSpecCategorie(b) {
		//const b = this.specTree.get_selected_branch();
		if (b && b.level < 2) {
			b.children.push({
				label: 'New',
				level: 2,
				data: { essais: [{ IsAdding: true, id: 0 }] },
				isEdited: true
			});

			b.expanded = true;
		}
	}

	copySpecCategorie(b) {
		//const b = this.specTree.get_selected_branch();
		if (b && b.level < 2) {
			b.expanded = false;

			const newTreeItemSpec = angular.copy(b);
			delete newTreeItemSpec.uid;
			delete newTreeItemSpec.id;
			delete newTreeItemSpec.label;

			delete newTreeItemSpec.data.petitD;
			delete newTreeItemSpec.data.grandD;
			delete newTreeItemSpec.data.idGamme;
			delete newTreeItemSpec.data.libelleGamme;

			newTreeItemSpec.data.id = 0;
			newTreeItemSpec.data = angular.copy(newTreeItemSpec.data);
			if (newTreeItemSpec.data.essais) {
				newTreeItemSpec.data.essais.map((e) => {
					e.idSpecification = 0;
					e.id = 0;
					return e;
				});
			}

			newTreeItemSpec.children = angular.copy(newTreeItemSpec.children);
			if (newTreeItemSpec.children) {
				newTreeItemSpec.children.map((e) => {
					delete e.parent_uid;
					delete e.uid;

					e.data = angular.copy(e.data);
					e.data.id = 0;
					delete e.data.petitD;
					delete e.data.grandD;
					delete e.data.idGamme;
					delete e.data.libelleGamme;
					return e;
				});
			}

			newTreeItemSpec.expanded = true;
			newTreeItemSpec.isEdited = true;

			this.specData.push(newTreeItemSpec);
			//this.specifications.push(newTreeItemSpec.data);
		}
	}

	deleteSpecCategorie(b, parent, index) {
		if (b && b.level >= 2) {
			if (parent) {
				if (index >= 0) {
					parent.children.splice(index, 1);
					this.specifications.map(function (e) {
						if (
							e.id == b.data.id &&
							e.petitD === b.data.petitD &&
							e.grandD === b.data.grandD &&
							e.idGamme === b.data.idGamme &&
							e.idCategorie === b.data.idCategorie
						) {
							e.isDeleted = true;
							e.essais.map(function (s) {
								s.isDeleted = true;
							});
						}
					});
				}
			}
		}
	}

	updateSpecCategorie() {
		const spec = this.selectedSpecification;
		spec.libelleCategorie = this.categories.find(function (e) {
			return e.id === spec.idCategorie;
		}).categorie;

		const specs = this.specifications.filter(function (e) {
			return e.petitD === spec.petitD && e.grandD === spec.grandD && e.idGamme === spec.idGamme && e.idCategorie === spec.idCategorie;
		});
		if (specs && specs.length > 1) {
			for (let j = 0; j < specs.length; j++) {
				if (specs[j].id !== spec.id) {
					spec.essais = spec.essais.concat(specs[j].essais);
					specs[j].isDeleted = true;
				}
			}
		}

		this.initSpecificationsTreeView();
	}

	addTest() {
		const sousEssaiCreation = this.selectedSpecification.essais.find(function (e) {
			return e.IsAdding === true;
		});
		if (!sousEssaiCreation) {
			this.selectedSpecification.essais.splice(0, 0, {
				IsAdding: true,
				id: 0,
				code: 0,
				position: -999
			});
		}
	}

	deleteTest(essai) {
		if (this.selectedSpecification.essais && this.selectedSpecification.essais.length > 0) {
			const essaiIndex = this.selectedSpecification.essais.findIndex(function (e) {
				return e.id == essai.id;
			});
			if (essaiIndex >= 0) {
				if (!essai.libelle) {
					this.selectedSpecification.essais.splice(essaiIndex, 1);
				} else {
					this.selectedSpecification.essais[essaiIndex].isDeleted = true;
				}

				const isDisplayEssai =
					this.selectedSpecification.essais.findIndex(function (e) {
						return !e.isDeleted;
					}) >= 0;
				if (!isDisplayEssai || !this.selectedSpecification.essais || this.selectedSpecification.essais.length <= 0) {
					this.selectedSpecification.essais.push({ IsAdding: true, id: 0, code: 0, position: -999 });
				}
			}
		}
	}

	deleteAllTest() {
		if (this.selectedSpecification.essais && this.selectedSpecification.essais.length > 0) {
			this.selectedSpecification.essais.map(function (e) {
				e.isDeleted = true;
			});
		}

		this.selectedSpecification.essais.push({ IsAdding: true, id: 0, code: 0, position: -999 });
	}

	selectEssai(items) {
		if (items && items.length > 0) {
			this.selectedSpecification.essais.splice(0, 1);
			for (let i = 0; i < items.length; i++) {
				const addItem = items[i];
				addItem.IsAdding = false;

				this.newIndexCounter--;
				addItem.id = this.newIndexCounter;

				// let essai = null;
				// if (addItem.idTamis)
				//     essai = this.selectedSpecification.essais.find(function (e) { return e.idEssai == addItem.idEssai && e.idSousEssai == addItem.idSousEssai && e.idTamis == addItem.idTamis; });
				// else if (addItem.idDimRq)
				//     essai = this.selectedSpecification.essais.find(function (e) { return e.idEssai == addItem.idEssai && e.idSousEssai == addItem.idSousEssai && e.idDimRq == addItem.idDimRq; });
				// else
				//     essai = this.selectedSpecification.essais.find(function (e) { return e.idEssai == addItem.idEssai && e.idSousEssai == addItem.idSousEssai; });

				// if (!essai)
				this.selectedSpecification.essais.push(addItem);
			}

			//this.trieEssais();
			this.selectedSpecification.essais.sort(function (a, b) {
				const codeFirst = a.libelle.split(' | ')[0];
				const codeSecond = b.libelle.split(' | ')[0];
				return codeFirst.localeCompare(codeSecond);
			});
		}
	}

	trieEssais() {
		this.selectedSpecification.essais = this.$filter('orderBy')(
			this.selectedSpecification.essais,
			['libelle', 'libelleSousEssai', 'idCalcStat'],
			false,
			function (v1, v2) {
				let retour = 0;
				if (v1.type == 'boolean') {
					if (v1.value != v2.value) {
						if (v1.value) {
							retour = -1;
						} else {
							retour = 1;
						}
					} else {
						retour = 0;
					}
				} else if (v1.value) {
					if (v1.type == 'string' && !parseFloat(v1.value.replace(',', '.').replace(' ', ''))) {
						retour = v1.value.localeCompare(v2.value);
					} else {
						//tri descendant pour les tamis
						let d1 = null;
						let d2 = null;

						if (v1.type == 'number') {
							d1 = parseFloat(v1.value);
						} else if (v1.value) {
							d1 = parseFloat(v1.value.toString().replace(',', '.').replace(' ', ''));
						}

						if (v2.type == 'number') {
							d2 = parseFloat(v2.value);
						} else if (v2.value) {
							d2 = parseFloat(v2.value.toString().replace(',', '.').replace(' ', ''));
						}

						if (d1 != d2) {
							if (d1 > d2) {
								retour = -1;
							} else {
								retour = 1;
							}
						} else {
							retour = 0;
						}
					}
				}
				return retour;
			}
		);
	}

	addCategorie() {
		if (!this.categories) {
			this.categories = [];
		}

		this.newIndexCounter--;
		this.categories.splice(0, 0, { id: this.newIndexCounter, isEdit: true });
	}

	editCategorie(categorie) {
		categorie.isEdit = true;
	}

	async saveCategorie(categorie) {
		if (this.norme.id) {
			try {
				categorie.id = await this.NormesService.createOrUpdateCategorie(this.norme.id, categorie);
				categorie.isEdit = false;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		} else {
			categorie.isEdit = false;
		}
	}

	async saveCategories() {
		if (this.norme.id && this.categories && this.categories.length > 0) {
			for (let i = 0; i < this.categories.length; i++) {
				const categorie = this.categories[i];
				if (categorie.id !== 0 && categorie.categorie) {
					try {
						const lastId = categorie.id;
						categorie.id = await this.NormesService.createOrUpdateCategorie(this.norme.id, categorie);

						if (this.specData && this.specData.length > 0) {
							for (let d = 0; d < this.specData.length; d++) {
								if (this.specData[d].data.idCategorie === lastId) {
									this.specData[d].data.idCategorie = categorie.id;
									this.specData[d].data.libelleCategorie = categorie.categorie;
									this.specData[d].data.idNorme = this.norme.id;
								}
							}
						}
					} catch (err) {
						if (err.data) {
							this.notification.error(err.data);
						} else {
							throw err;
						}
					}
				}
			}
		}
	}

	async deleteCategorie(categorie) {
		if (categorie.isEdit) {
			categorie.isEdit = false;
			this.categories.splice(0, 1);
		} else {
			const categorieIndex = this.categories.findIndex(function (e) {
				return e.id == categorie.id;
			});
			if (categorieIndex >= 0) {
				if (this.norme.id) {
					try {
						await this.NormesService.deleteCategorieById(this.norme.id, categorie.id);
						this.categories.splice(categorieIndex, 1);
					} catch (err) {
						if (err.data) {
							this.notification.error(err.data);
						} else {
							throw err;
						}
					}
				} else {
					this.categories.splice(categorieIndex, 1);
				}
			}
		}
	}

	async getGammes() {
		try {
			const data = await this.ProduitsService.getGammes();
			let tmpId = -1;
			if (this.norme && this.norme.idFamille >= 0) {
				tmpId = this.norme.idFamille;
			}

			if (tmpId >= 0) {
				data.items = data.items.filter((x) => {
					if (x.idFamille === null) {
						return true;
					} else if (x.idFamille >= 0 && x.idFamille === tmpId) {
						return true;
					}
					return false;
				});
			}

			this.gammes = data.items;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async changeGamme(b) {
		b.data.idGamme = b.data.libelleGamme.id;
		b.data.libelleGamme = b.data.libelleGamme.libelle;

		this.saveEdition(b, b);
	}

	onTreeItemSelected(item, forceExpand) {
		this.cancelLastEditing();

		if (item.level < 2) {
			if (this.norme.hasCategorie) {
				this.selectedSpecification = null;
			} else {
				this.selectedSpecification = item.data;

				//const isDisplayEssai = this.selectedSpecification.essais.findIndex(function (e) { return !e.isDeleted; }) >= 0;
				if (!this.selectedSpecification.essais || this.selectedSpecification.essais.length <= 0) {
					this.selectedSpecification.essais = [{ IsAdding: true, id: 0, code: 0, position: -999 }];
				}
			}

			item.expanded = forceExpand ? forceExpand : !item.expanded;
		} else if (item.label !== 'New') {
			this.selectedSpecification = item.data;

			//const isDisplayEssai = this.selectedSpecification.essais.findIndex(function (e) { return !e.isDeleted; }) >= 0;
			if (!this.selectedSpecification.essais || this.selectedSpecification.essais.length <= 0) {
				this.selectedSpecification.essais = [{ IsAdding: true, id: 0, code: 0, position: -999 }];
			}
		}

		this.specData.map((e) => {
			delete e.selected;

			e.children.map((c) => {
				delete c.selected;
				return c;
			});

			return e;
		});

		item.selected = true;
	}

	cancelLastEditing() {
		for (let i = this.specData.length - 1; i >= 0; i--) {
			const spec = this.specData[i];

			if (spec.isEdited) {
				spec.isEdited = false;
			} else {
				if (spec.children && spec.children.length > 0) {
					for (let j = spec.children.length - 1; j >= 0; j--) {
						const child = spec.children[j];

						if (child.isEdited) {
							spec.children.splice(j, 1);
						}
					}
				}
			}
		}
	}

	async getFamilles() {
		this.familles = await this.FamillesService.getFamilles();
	}

	debug() {
		console.log(this);
	}
}
