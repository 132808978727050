export default class DapValidationModalController {
	/* @ngInject */
	constructor($scope, $state, $stateParams, DAPsService, SitesService, LieuxService, notification, ModalService, $uibModal, moment, $translate) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.DAPsService = DAPsService;
		this.SitesService = SitesService;
		this.LieuxService = LieuxService;
		this.notification = notification;
		this.ModalService = ModalService;
		this.$uibModal = $uibModal;
		this.$translate = $translate;
		this.moment = moment;
		this.motifRefus = null;
		this.isRetour = false;
		this.dateValidate = null;
		this.isRefus = false;
		this.fullName = '';
		this.statut = 0;
		this.producteurs = [];
		this.tmp = {
			lieu: null,
			producteur: null
		};
		this.lieux = [];
	}

	async $onInit() {
		this.isRefus = this.resolve.isRefus;
		this.statut = this.isRefus ? 4 : 3;
		const userData = JSON.parse(localStorage.getItem('massia.MassiaPersistsUserData'));
		this.personneValidateId = userData.id;
		this.fullName = `${userData.nom} ${userData.prenom}`;
		this.dateValidate = new Date().toLocaleDateString();

		if (!this.isRefus) {
			await this.getProducteurs();
			await this.getLieux();
		}
	}

	async getProducteurs() {
		try {
			this.producteurs = await this.SitesService.getSitesProducteurs([], [], {});
		} catch (error) {
			console.error(error);
		}
	}

	async getLieux() {
		try {
			this.lieux = await this.LieuxService.getLieuxHeaders();
		} catch (error) {
			console.error(error);
		}
	}

	getControles() {
		let res = true;
		if (this.isRefus) {
			if (!this.motifRefus) {
				res = false;
			}
			if (!this.dateValidate) {
				res = false;
			}
		} else {
			if (!this.tmp.lieu[0]) {
				res = false;
			}
			if (!this.tmp.producteur[0]) {
				res = false;
			}
		}

		if (!res) {
			this.notification.error(this.$translate.instant('DAPS.VALIDATE.ERR_FORM'));
		}
		return res;
	}

	async validateDap() {
		try {
			const res = await this.DAPsService.createDAPfromAttente(this.resolve.dap, this.tmp.producteur[0], this.tmp.lieu[0]);
			if (res) {
				this.notification.success(this.$translate.instant('DAPS.ATTENTE.VALIDATE_TOAST'));
				this.DAPsService.deleteDAPAttente(this.resolve.dap.id);
				this.$state.go('daps.edit', { id: res });
			}
		} catch (error) {
			console.log(error);
		}
	}

	async refusDap() {
		const data = {
			id: this.resolve.dap.id,
			personneValidationId: this.personneValidateId,
			dateValidation: this.dateValidate,
			statut: this.isRetour ? 5 : 4,
			motifRefus: this.motifRefus
		};

		await this.DAPsService.UpdateDapAttente(data);

		this.notification.success(this.$translate.instant('DAPS.ATTENTE.REJECT_TOAST'));
		this.resolve.dap.statut = 4;
	}

	async ok() {
		if (!this.getControles()) {
			return;
		}

		if (this.isRefus) {
			await this.refusDap();
		} else {
			await this.validateDap();
		}

		this.cancel();
	}

	cancel() {
		this.modalInstance.dismiss();
	}
}
