import { Component } from '../../../core/decorators';
import multiSelectTemplate from './massia.multi.select.tpl.html';

@Component({
	selector: 'massia-multi-select',
	template: multiSelectTemplate,
	bindings: {
		inputModel: '<',
		outputModel: '=',
		tickProperty: '@',
		disableProperty: '@',
		searchProperty: '@',
		itemLabel: '@',
		buttonLabel: '@',
		maxLabels: '<',
		placeholder: '@',
		maxWidth: '@',
		selectionMode: '@',
		onItemClick: '&',
		onSelectAll: '&',
		onSelectNone: '&',
		outputProperties: '@',
		onClose: '&',
		onReset: '&',
		disabled: '<'
	}
})
export class MassiaMultiSelect {
	NumberPerPage = 10;
	/* @ngInject */
	constructor($scope, $element, $compile, $filter, $uibModal, $document, $timeout, _) {
		/**
		 * @type ng.IScope
		 */
		this.$scope = $scope;
		this.$element = $element;
		this.$compile = $compile;
		this.$filter = $filter;
		this.$uibModal = $uibModal;
		this.$document = $document;
		this.$timeout = $timeout;
		this._ = _;
	}

	$onInit() {
		this.checkBindings();
		this.$scope.currentPage = 1;
		this.$scope.numberPerPage = this.NumberPerPage;
		if (!this.inputModel) {
			this.inputModel = [];
		}
		this.$scope.log = (data) => this.log(data);
	}

	$onChanges(newVal) {
		if (newVal.inputModel && newVal.inputModel.currentValue) {
			this.inputModel = newVal.inputModel.currentValue.map((x) => {
				x[this.tickProperty] = !!x[this.tickProperty];
				return x;
			});
			this.refreshOutput();
		}
	}

	$onDestroy() {
		if (this.u) {
			this.u();
		}
	}

	getSelected() {
		return this.inputModel.filter((x) => x[this.tickProperty]);
	}

	getNotSelected() {
		return this.inputModel.filter((x) => !x[this.tickProperty]);
	}

	addToOutput(match) {
		if (this._outputModel.findIndex((x) => angular.equals(x, match)) === -1) {
			if (this.selectionMode === 'single') {
				let i = 0;
				while (i < this.inputModel.length) {
					this.inputModel[i][this.tickProperty] = false;
					i++;
				}
			}
			let i = this.inputModel.findIndex((x) => angular.equals(x, match));
			this.selected = '';
			this.clickItemDigest(this.inputModel[i], true);
			if (this.onClose) {
				this.onClose();
			}
		}
	}

	selectedEvent(event, match) {
		if ((event.type === 'keyup' || event.type === 'keydown' || event.type === 'keypress') && (event.which === 13 || event.which === 9)) {
			this.addToOutput(match);
		}

		event.stopPropagation();
		event.stopImmediatePropagation();
		event.preventDefault();
	}

	refreshOutput() {
		this._outputModel = this.$scope.outputs = this.getSelected();
		this.outputModel = this.getSelectWithProperties();
	}

	clickItemDigest(item, tick) {
		item[this.tickProperty] = tick;
		const superman = { ...item };
		this.refreshOutput();
		this.$timeout(() => {
			this.onItemClick({ data: superman });
		});
	}

	getSelectWithProperties() {
		if (this.outputProperties) {
			let property = this.outputProperties.split(' ');
			let filter = this._.filter(this.inputModel, (x) => x[this.tickProperty]);
			if (property.length === 1) {
				return this._.map(filter, (x) => x[property[0]]);
			}
			return this._.map(filter, (x) => {
				let tmp = {};
				for (let i = 0; i < property.length; i++) {
					const prop = property[i];
					tmp[prop] = x[prop];
				}
				return tmp;
			});
		}
		return this.getSelected();
	}

	createFilter(value) {
		let property = this.searchProperty.split(' ');
		let options = {};
		for (let i = 0; i < property.length; i++) {
			const p = property[i];
			let temp = p.match(/\w+/g);
			options[temp] = value;
		}
		return options;
	}

	checkBindings() {
		if (!this._outputModel) this._outputModel = [];
		if (!this.tickProperty) this.tickProperty = 'selected';
		if (!this.itemlabel && this.itemLabel.split(' ').length < 1) {
			throw new Error('Massia-multi-select: item-label is required and need minimum one property');
		}
		if (!this.buttonlabel && this.buttonLabel.split(' ').length < 1) {
			throw new Error('Massia-multi-select: button-label is required and need minimum one property');
		}
		if (!this.searchProperty) this.searchProperty = this.buttonLabel;
		if (!this.maxLabels) this.maxLabels = 5;
		if (!this.placeholder) this.placeholder = 'RECHERCHER';
		if (!this.maxWidth) this.maxWidth = '100%';
		if (!this.selectionMode) this.selectionMode = 'multi';
	}

	getSearch(scope) {
		let property = this.buttonLabel.split(' ');
		let options = '';
		for (let i = 0; i < property.length; i++) {
			const p = property[i];
			let temp = p.match(/\w+/g);
			if (this.searchProperty.includes(temp)) {
				options += `${p.replace(temp, scope.match.label[temp] != null ? scope.match.label[temp] : '')} `;
			}
		}
		scope.search = options;
	}

	//TODO GetSearch et noSearch a regrouper en une seule méthode

	getNoSearch(scope) {
		let property = this.buttonLabel.split(' ');
		let options = '';
		for (let i = 0; i < property.length; i++) {
			const p = property[i];
			let temp = p.match(/\w+/g);
			if (!this.searchProperty.includes(temp)) {
				options += `${p.replace(temp, scope.match.label[temp] != null ? scope.match.label[temp] : '')} `;
			}
		}
		scope.noSearch = options;
	}

	//TODO GetItemLabel idem que getSearch et getNoSearch a regrouper

	getItemLabel(output) {
		let property = this.itemLabel.split(' ');
		let options = '';
		for (let i = 0; i < property.length; i++) {
			const p = property[i];
			let temp = p.match(/\w+/g);
			options += `${p.replace(temp, output[temp] != null ? output[temp] : '')} `;
		}
		// console.log(options);
		return options;
	}

	removeOutput(output) {
		let obj = this.inputModel.find((x) => this.compareObject(x, output));
		this.clickItemDigest(obj, false);
		if (this.onClose) {
			this.onClose();
		}
	}

	compareObject(x, y) {
		return angular.equals(x, y);
	}

	async openModal() {
		try {
			var modalInstance = this.$uibModal.open({
				animation: false,
				component: 'massia-multi-select-modal',
				windowClass: 'modern-modal',
				appendTo: this.$document.find('massia-application').eq(0),
				resolve: {
					mode: () => this.selectionMode,
					onClick: () => this.clickItemDigest.bind(this),
					numberPerPage: () => this.NumberPerPage,
					tickProperty: () => this.tickProperty,
					onSelectAll: () => this.selectAll.bind(this),
					disableProperty: () => this.disableProperty,
					placeholder: () => this.placeholder,
					searchProperty: () => this.searchProperty,
					inputModel: () => this.inputModel,
					getModel: () => this.getModel.bind(this),
					getSearch: () => this.getSearch.bind(this),
					getNoSearch: () => this.getNoSearch.bind(this)
				}
			});

			var res = await modalInstance.result;
			if (res) {
				if (res.selectAll) {
					this.selectAll(true, null, res.search);
					this.refreshOutput('select all');
					this.$timeout(() => this.onSelectAll());
				} else if (res.selectNone) {
					this.selectAll(false, null, res.search);
					this.refreshOutput('select none');
					this.$timeout(() => this.onSelectNone());
				} else if (res.itemClick) {
					this.selectAll(false, res.clicked);
					this.clickItemDigest(res.clicked, res.clicked[this.tickProperty]);
				}
			}
			if (this.onClose) {
				this.$timeout(() => {
					this.onClose();
				});
			}
		} catch (err) {
			if (this.onClose) {
				this.$timeout(() => {
					this.onClose();
				});
			}
		}
	}

	selectAll(selected = false, item = null, search = '') {
		let i = 0;
		const searched = this.$filter('conditional')(this.inputModel, search, this.searchProperty);
		while (i < searched.length) {
			if (!item || (item && !this.compareObject(item, searched[i]))) {
				if (!searched[i][this.disableProperty]) {
					searched[i][this.tickProperty] = selected;
				}
			}
			i++;
		}
	}

	getModel(item) {
		return this.inputModel.findIndex((x) => angular.equals(x, item));
	}
}
