import SwitchContentController from './switch.controller';

export default {
    bindings: {
        element: '=',
        isDependingOnTypeBenne: '<',
        listes: '<',
        disable: '<',
        onUpdate: '&',
        filterToBack: '<'
    },
    template: require('./switch.html'),
    controller: SwitchContentController
};
