let unregisterReinit;

export default class SwitchContentController {
	static $inject = ['$stateParams', 'notification', 'CentreGestionService'];

	constructor($stateParams, notification, CentreGestionService) {
		this.$stateParams = $stateParams;
		this.CentreGestionService = CentreGestionService;
		this.notification = notification;
		this.loading = false;
		this.codeLoading = false;
	}

	$onInit() {
		this.oldValue = this.element?.valeur;
		// this.element = this.element;
		// this.listes = this.listes;
		this.convertParams();
		this.unite = "";

		if(this.filterToBack.typeBenneId && this.isDependingOnTypeBenne){
			this.listes.typesBenne.forEach(element => {
				if(element.id == this.filterToBack.typeBenneId)
					this.unite = element.unite;
			});
		}
	}
	convertParams() {
		if (typeof this.element !== 'undefined') {
			if (this.element.elementType == 'int') {
				if (this.element.elementDomaine == 'modeleTicket') {
					this.element.valeur = this.element.valeur.toString();
				} else {
					this.element.valeur = parseInt(this.element.valeur, 10);
				}
			} else if (this.element.elementType == 'bool') {
				this.element.valeur = this.element.valeur === 'true' || this.element.valeur === '1';
			} else if (this.element.elementType == 'string' && this.element.elementDomaine != null) {
				// switch (this.element.domaine) {
				//     case 'genereChantLieu':
				//         break;
				// }
			}
		}
	}

	$onChanges() {}

	// listElementsLD(elementDomaine) {
	//     console.log(elementDomaine);
	//     switch (elementDomaine) {
	//         case 'transport':
	//             return this.listes.transportsSelectionnables;
	//         case 'bennes':
	//         case 'listeBennes':
	//             return this.listes.listeBennes;
	//         case 'modePaiement':
	//         case 'modesPaiement':
	//             return this.listes.modesPaiement;
	//         case 'presentFacture':
	//             return this.listes.presentationFactures;
	//         case 'modeTarage':
	//         case 'modeTarages':
	//             return this.listes.modesTarages;
	//         case 'controleCreaDap':
	//             return this.listes.controleCreationDaps;
	//         case 'prestation':
	//             return this.listes.prestations;
	//         case 'pays':
	//             return this.listes.Pays;
	//         case 'genereChantLieu':
	//             return this.listes.chantLieu;
	//         case 'tva':
	//             break;
	//         case 'modeleTicket':
	//         case 'modelesImpressionTickets':
	//             return this.listes.modelesImpressionTickets;
	//         case 'modeleBadge':
	//             return this.listes.modelesImpressionBadges;
	//         default:
	//     }
	// }

	listElementsLD(elementDomaine) {
		let returnlist = [];
		switch (elementDomaine) {
			case 'transport':
				returnlist = this.listes.transportsSelectionnables;
				break;
			case 'bennes':
			case 'listeBennes':
				returnlist = this.listes.listeBennes;
				break;
			case 'modePaiement':
			case 'modesPaiement':
				returnlist = this.listes.modesPaiement;
				break;
			case 'presentFacture':
				returnlist = this.listes.presentationFactures;
				break;
			case 'modeTarage':
			case 'modeTarages':
				returnlist = this.listes.modesTarages;
				break;
			case 'controleCreaDap':
				returnlist = this.listes.controleCreationDaps;
				break;
			case 'prestation':
				returnlist = this.listes.prestations;
				break;
			case 'pays':
				returnlist = this.listes.Pays;
				break;
			case 'genereChantLieu':
				returnlist = this.listes.chantLieu;
				break;
			case 'tva':
				break;
			case 'modeleTicket':
			case 'modelesImpressionTickets':
				returnlist = this.listes.modelesImpressionTickets;
				break;
			case 'modeleBadge':
				returnlist = this.listes.modelesImpressionBadges;
				break;
			default:
		}
		if (!returnlist || returnlist.length === 0) {
			this.disable = true;
		}

		return returnlist;
	}

	numberOnly() {
		if (isNaN(this.element.valeur)) {
			this.element.valeur = null;
		}
	}

	updateParam(reload) {
		if (this.oldValue != reload.valeur) {
			if (
				(isNaN(this.element.valeur) && reload.elementCode == 'regrouperLigneFactureLieuChantier') ||
				reload.valeur != null ||
				typeof reload.valeur === 'boolean'
			) {
				// cas nominal, cad un string, un int ou un boolean
			} else if (reload.valeur == null) {
				// cas ou on sélectionne "Sélectionner", avoir le même comportement que la gomme
				this.element.valeur = 0;
			}
			this.onUpdate(reload);
			this.oldValue = reload.valeur;
		}
	}

	async $onChanges(changesObj) {}

	$onDestroy() {}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
