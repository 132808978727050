TracteursController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'TracteursService',
	'notification',
	'MOPService'
];

export default function TracteursController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	TracteursService,
	notification,
	MOPService
) {
	const vm = this;

	let previousTableState;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.tracteurs = [];
	vm.isEnabled = true;
	vm.previousIsEnabled = true;

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadTracteurs = loadTracteurs;
	vm.deleteTracteur = deleteTracteur;
	vm.createTracteur = createTracteur;
	vm.updateTracteur = updateTracteur;
	vm.activeEditMode = activeEditMode;
	vm.desactiveEditMode = desactiveEditMode;
	vm.activateTracteur = activateTracteur;
	vm.MOPService = MOPService;

	vm.$onInit = () => {
		vm.MOPService.setMop([{ title: 'TRACTEURS.BREADCRUMBS.TRACTEURS_LIST', filename: 'Vehicules.pdf', application: 'gestion' }]);
	};

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	async function loadTracteurs(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'tracteurs.list') {
			$state.go('tracteurs.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		if (vm.isEnabled != vm.previousIsEnabled) {
			pagination.skip = 0;
			vm.previousIsEnabled = vm.isEnabled;
		}

		vm.tracteurs = [];
		vm.newTracteur = {};

		try {
			const data = await TracteursService.getTracteurs(vm.isEnabled, filters, sorts, pagination);

			vm.tracteurs = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteTracteur(tracteur) {
		if (tracteur && tracteur.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('TRACTEURS.DELETE.TITLE'),
				modalMsg: $translate.instant('TRACTEURS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await TracteursService.deleteTracteurById(tracteur.id);
					notification.success($translate.instant('TRACTEURS.DELETE.SUCCESS'));
					loadTracteurs();
				} catch (ex) {
					if (ex.status == 500) {
						notification.error('TRACTEURS.DELETE.NOTDELETABLE');
					} else notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function activateTracteur(idTracteur) {
		await TracteursService.activateTracteur(idTracteur);
		loadTracteurs();
	}

	function activeEditMode(benne) {
		benne.isEditMode = true;
	}

	function desactiveEditMode(benne) {
		benne.isEditMode = false;
		loadTracteurs();
	}

	async function createTracteur(tracteur) {
		if (tracteur && tracteur.code && tracteur.poidsTotalRoulantAutorise) {
			// const modalInstance = ModalService.confirm({
			//     modalTitle: $translate.instant('TRACTEURS.CREATE.TITLE'),
			//     modalMsg: $translate.instant('TRACTEURS.CREATE.MESSAGE'),
			//     headerClass: 'modal-danger'
			// });

			// modalInstance.result
			//     .then(async function() {
			startLoading();
			try {
				await TracteursService.createTracteur(tracteur);
				notification.success($translate.instant('TRACTEURS.CREATE.SUCCESS'));
				loadTracteurs();
				vm.newTracteur = {};
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
			// });
		} else if (!tracteur.code && !tracteur.poidsTotalRoulantAutorise) notification.error('TRACTEURS.CREATE.MISSINGDATA');
		else if (!tracteur.code) notification.error('TRACTEURS.CREATE.MISSINGIMMAT');
		else if (!tracteur.poidsTotalRoulantAutorise) notification.error('TRACTEURS.CREATE.MISSINGPTRA');
	}

	async function updateTracteur(tracteur) {
		if (tracteur && tracteur.id) {
			if (tracteur.code && tracteur.poidsTotalRoulantAutorise) {
				// const modalInstance = ModalService.confirm({
				//     modalTitle: $translate.instant('TRACTEURS.UPDATE.TITLE'),
				//     modalMsg: $translate.instant('TRACTEURS.UPDATE.MESSAGE'),
				//     headerClass: 'modal-danger'
				// });

				// modalInstance.result
				//     .then(async function() {
				startLoading();
				try {
					await TracteursService.updateTracteur(tracteur);
					notification.success($translate.instant('TRACTEURS.UPDATE.SUCCESS'));
					vm.newTracteur = {};
					loadTracteurs();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
				// });
			} else if (!tracteur.code && !tracteur.poidsTotalRoulantAutorise) notification.error('TRACTEURS.CREATE.MISSINGDATA');
			else if (!tracteur.code) notification.error('TRACTEURS.CREATE.MISSINGIMMAT');
			else if (!tracteur.poidsTotalRoulantAutorise) notification.error('TRACTEURS.CREATE.MISSINGPTRA');
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}
}
