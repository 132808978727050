export default class ImportDonneesController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'ImportDonneesService',
		'notification',
		'$location',
		'MassiaApplicationService',
		'_',
		'ModalService',
		'$translate'
	];

	constructor($scope, $state, $stateParams, ImportDonneesService, notification, $location, MassiaApplicationService, _, ModalService, $translate) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ImportDonneesService = ImportDonneesService;
		this.notification = notification;
		this.$location = $location;
		this.MassiaApplicationService = MassiaApplicationService;
		this._ = _;
		this.ModalService = ModalService;
		this.$translate = $translate;
	}

	async $onInit() {
		this.application = this.MassiaApplicationService.getApplication();

		this.stopLoading();
		this.initForm();

		await this.loadFiles();
	}

	$onDestroy() {
		//this.unregisterReinit();
	}

	async initForm() {
		this.domaines = [
			{ id: 1, code: 'TYPE_EQUIPEMENTS', libelle: 'IMPORT_DONNEES.TYPE_EQUIPEMENTS', applications: ['performance'] },
			{ id: 2, code: 'TYPE_ENGINS', libelle: 'IMPORT_DONNEES.TYPE_ENGINS', applications: ['performance'] },
			{ id: 3, code: 'TYPE_SALARIES', libelle: 'IMPORT_DONNEES.TYPE_SALARIES', applications: ['performance'] },
			{ id: 4, code: 'TYPE_AFFECTATIONS', libelle: 'IMPORT_DONNEES.TYPE_AFFECTATIONS', applications: ['performance'] },
			{ id: 5, code: 'ARRETS_MOBILES_ENGINS', libelle: 'IMPORT_DONNEES.ARRETS_MOBILES_ENGINS', applications: ['performance'] },
			{ id: 6, code: 'ARRETS_FIXES_PROCESSUS', libelle: 'IMPORT_DONNEES.ARRETS_FIXES_PROCESSUS', applications: ['performance'] },
			{ id: 7, code: 'TYPE_PANNES_PROCESSUS', libelle: 'IMPORT_DONNEES.TYPE_PANNES_PROCESSUS', applications: ['performance'] },
			{ id: 8, code: 'PANNES_PROCESSUS', libelle: 'IMPORT_DONNEES.PANNES_PROCESSUS', applications: ['performance'] },
			{ id: 9, code: 'PANNES_ENGINS', libelle: 'IMPORT_DONNEES.PANNES_ENGINS', applications: ['performance'] },
			{ id: 10, code: 'AFFECTATIONS', libelle: 'IMPORT_DONNEES.AFFECTATIONS', applications: ['performance'] },
			{ id: 11, code: 'ENGINS', libelle: 'IMPORT_DONNEES.ENGINS', applications: ['performance'] },
			{ id: 12, code: 'SALARIES', libelle: 'IMPORT_DONNEES.SALARIES', applications: ['performance'] },
			{ id: 13, code: 'PROCESSUS', libelle: 'IMPORT_DONNEES.PROCESSUS', applications: ['performance'] },
			{ id: 14, code: 'EQUIPEMENTS_PROCESSUS', libelle: 'IMPORT_DONNEES.EQUIPEMENTS_PROCESSUS', applications: ['performance'] },
			{ id: 15, code: 'CONFIGS_PROCESSUS', libelle: 'IMPORT_DONNEES.CONFIGS_PROCESSUS', applications: ['performance'] },
			{ id: 16, code: 'PRODUITS_CONFIGS_PROCESSUS', libelle: 'IMPORT_DONNEES.PRODUITS_CONFIGS_PROCESSUS', applications: ['performance'] },
			{ id: 17, code: 'AFFECTATIONS_DEFAUT_ENGINS', libelle: 'IMPORT_DONNEES.AFFECTATIONS_DEFAUT_ENGINS', applications: ['performance'] },
			{ id: 18, code: 'AFFECTATIONS_DEFAUT_SALARIES', libelle: 'IMPORT_DONNEES.AFFECTATIONS_DEFAUT_SALARIES', applications: ['performance'] },
			{ id: 19, code: 'EQUIPEMENTS', libelle: 'IMPORT_DONNEES.EQUIPEMENTS', applications: ['performance'] },
			{ id: 20, code: 'ZONES_EXTRACTION', libelle: 'IMPORT_DONNEES.ZONES_EXTRACTION', applications: ['performance'] },
			{ id: 21, code: 'ZONES_ABATTAGES', libelle: 'IMPORT_DONNEES.ZONES_ABATTAGES', applications: ['performance'] },

			{ id: 22, code: 'LIEUX', libelle: 'IMPORT_DONNEES.LIEUX', applications: ['gestion'] },
			{ id: 23, code: 'SITES', libelle: 'IMPORT_DONNEES.SITES', applications: ['gestion', 'laboratoire'] },

			{ id: 23, code: 'PRODUITS', libelle: 'IMPORT_DONNEES.PRODUITS', applications: ['laboratoire'] },
			{ id: 23, code: 'PRODUITS_PRODUCTEURS', libelle: 'IMPORT_DONNEES.PRODUITS_PRODUCTEURS', applications: ['laboratoire'] }
		];

		this.domaines = this.domaines.filter((e) => e.applications.indexOf(this.application) >= 0);
	}

	async loadFiles() {
		const domaines = await this.ImportDonneesService.loadDomaines();
		if (domaines) {
			for (let i = 0; i < domaines.length; i++) {
				const index = this._.findIndex(this.domaines, { code: domaines[i].code });
				if (index >= 0) {
					this.domaines[index] = domaines[i];
				}
			}
		}
	}

	async downloadTemplate(domaine) {
		const data = await this.ImportDonneesService.getTemplate(domaine, this.application);
		this.downloadFile(data, domaine);
	}

	downloadFile(data, domaine) {
		if (data) {
			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: 'application/octet-stream' + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);

				linkElement.setAttribute('download', domaine.code + '.xlsx');

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
				this.notification.error(ex.data);
			}
		}
	}

	async download(index) {
		try {
			this.downloadedFileIndex = index;
			document.getElementById('file-upload').click();
		} catch (ex) {
			this.notification.error(ex.data);
			this.downloadedFileIndex = -1;
		}
	}

	async downloadAll() {
		try {
			let msg = '<p>' + this.$translate.instant('IMPORT_DONNEES.DOWNLOAD_ALL_DESCRIPTION') + '</p>';
			msg += "<table class='width-100p'>";
			msg += '<tr>';
			msg += '<th>' + this.$translate.instant('IMPORT_DONNEES.DONNEE') + '</th>';
			msg += '<th>' + this.$translate.instant('IMPORT_DONNEES.NOM_FICHIER') + '</th>';
			msg += '</tr>';
			for (let i = 0; i < this.domaines.length; i++) {
				msg += '<tr>';
				msg += '<td>' + this.$translate.instant(this.domaines[i].libelle) + '</td>';
				msg += '<td>' + this.domaines[i].code + '.xlsx</td>';
				msg += '</tr>';
			}
			msg += '</table>';

			const that = this;
			const modalInstance = this.ModalService.info({
				modalTitle: this.$translate.instant('IMPORT_DONNEES.TELECHARGEMENT_MASSE'),
				modalMsg: msg,
				headerClass: 'modal-info'
			});

			modalInstance.result.then(async function () {
				that.downloadedFileIndex = -1;
				document.getElementById('file-upload-all').click();
			});
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async change(event) {
		if (event && event.target.files.length > 0) {
			if (this.downloadedFileIndex >= 0) {
				const fileInfo = event.target.files[0];

				const domaine = this.domaines[this.downloadedFileIndex];
				if (domaine) {
					domaine.b64File = await this.toBase64(fileInfo);
					domaine.ignoreCheckValidation = false;
					domaine.fileName = domaine.code;
					domaine.ready = true;
					await this.ImportDonneesService.import([domaine]);

					await this.insert(this.downloadedFileIndex);
				}
			} else {
				for (let i = 0; i < event.target.files.length; i++) {
					const fileInfo = event.target.files[i];
					const fileName = fileInfo.name;

					const domaine = this._.find(this.domaines, { code: fileName.split('.')[0].toUpperCase() });
					if (domaine) {
						domaine.b64File = await this.toBase64(fileInfo);
						domaine.ignoreCheckValidation = false;
						domaine.fileName = domaine.code;
						domaine.ready = true;
					} else {
						this.notification.warning(this.$translate.instant('IMPORT_DONNEES.DOWNLOAD_ALL_ERROR', { fileName: fileName }));
					}
				}

				await this.ImportDonneesService.import(this._.filter(this.domaines, { ready: true }));

				await this.insertAll();
			}
		}

		document.getElementById('file-upload').value = '';
		document.getElementById('file-upload-all').value = '';
		this.downloadedFileIndex = -1;
	}

	async toBase64(file) {
		if (file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		}
	}

	async insert(index) {
		this.startLoading();
		try {
			const domaine = this.domaines[index];
			if (domaine.fileName && domaine.b64File && domaine.ready) {
				const result = await this.ImportDonneesService.insert(domaine.code);
				if (result) {
					this.domaines[index] = result[0];
					this.displayCR = true;
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async insertAll() {
		this.startLoading();
		try {
			const domainsImported = await this.ImportDonneesService.insert('all');
			for (let i = 0; i < domainsImported.length; i++) {
				const copyDomaine = domainsImported[i];

				const domaineIndex = this._.findIndex(this.domaines, { id: copyDomaine.id });
				this.domaines[domaineIndex] = copyDomaine;
			}

			this.displayCR = true;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
