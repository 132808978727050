import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';
import * as _ from 'lodash';

export default class PersonneDetailController {
	/* @ngInject */
	constructor($stateParams, $state, PersonnesService, notification, PersonnesCommunicationService, MassiaApplicationService) {
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.PersonnesService = PersonnesService;
		this.notification = notification;
		this.PersonnesCommunicationService = PersonnesCommunicationService;
		this.MassiaApplicationService = MassiaApplicationService;
	}

	$onInit() {
		this.loading = false;
		this.personne = {};
		this.caracteristiques = [];

		this.ongletOpen = {
			isEnteteOpen: true,
			isCaracteristiqueOpen: false,
			isSpecificiteOpen: false
		};

		this.initPersonne();
	}

	async initPersonne() {
		this.personne = { id: this.$stateParams.id };
		if (this.personne.id) {
			this.startLoading();
			try {
				this.personne = await this.PersonnesService.getPersonneById(this.personne.id);
				this.personne.caracteristiques = await this.PersonnesService.getPersonneValeursCaracteristiquesById(this.personne.id);
				this.setCaracteristiques(this.personne.caracteristiques);
			} catch (ex) {
				if (ex.status === 404) {
					this.goListPersonne();
				}
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		} else {
			this.goListPersonne();
		}
	}

	async setCaracteristiques(caracteristiques = []) {
		const natures = getNatureNamesByValues();
		this.caracteristiques = [];
		caracteristiques.forEach((c) => {
			this.caracteristiques.push({
				code: c.code,
				label: c.label,
				values: getValue(c.value),
				nature: natures[c.idNature],
				numericAdditionalInformation: c.numericAdditionalInformation,
				labelCls: 'col-xs-12 col-sm-4',
				inputCls: 'col-xs-12 col-sm-8'
			});
		});

		function getValue(val) {
			if (angular.isArray(val)) {
				if (_.some(val, 'key')) {
					return _.map(val, 'value');
				}
				return val;
			}
			return [val];
		}
	}

	getUrlListe() {
		switch (this.$stateParams.genre) {
			case 'personne':
				if (this.MassiaApplicationService.getApplication() == 'performance') {
					return 'personnes-perf.list';
				}
				return 'personnes.list';
			case 'contact':
				return 'contacts.list';
			case 'utilisateur':
				return 'utilisateurs.list';
			case 'chauffeur':
				return 'chauffeurs.list';
			default:
				return 'personnes.list';
		}
	}

	goListPersonne() {
		this.$state.go(this.getUrlListe(), {}, { reload: true });
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
