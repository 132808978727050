import CodeAutomatique from '../models/code-automatique.model';

export default class CodeAutoController {
	constructor($scope, CodeAutomatiqueService, notification, $stateParams, MassiaApplicationService, ModalService, $uibModal) {
		this.$scope = $scope;
		this.CodeAutomatiqueService = CodeAutomatiqueService;
		this.notification = notification;
		this.$stateParams = $stateParams;
		this.MassiaApplicationService = MassiaApplicationService;
		this.ModalService = ModalService;
		this.$uibModal = $uibModal;
	}

	$onInit() {
		this.loading = true;
		this.codeAutomatique = [];
		const data = this.loadCodeAutomatique();
		//console.log(data)
		this.codeAutomatiqueModel = new CodeAutomatique(data);
		this.loading = false;
		this.isShow = false;
	}

	$onDestroy() {}

	async loadCodeAutomatique() {
		try {
			this.loading = true;
			const data = await this.CodeAutomatiqueService.getCodeAutomatique(this.MassiaApplicationService.getApplication());
			for (let i = 0; i < data.length; i++) {
				const code = data[i];
				if (!code.estCodeAutomatique) {
					code.estCodeAffiche = true;
				}
			}

			this.codeAutomatique = data;
			this.loading = false;
			//console.log(data);
			return data;
		} catch (error) {
			this.notification.error(error.data);
		}
	}

	checkCodeAffiche(item) {
		if (!item.estCodeAutomatique && item.masque === '[N15]') {
			item.estCodeAffiche = true;
			item.masque = null;
		}
		if (item.estCodeAutomatique && !item.masque) {
			item.masque = '[N15]';
			item.estCodeAffiche = false;
		}
	}

	async saveCodeAutomatique() {
		try {
			for (let i = 0; i < this.codeAutomatique.length; i++) {
				const element = this.codeAutomatique[i];

				if (element.estCodeAutomatique) {
					this.maskValidation(element);
				}
				element.domaineId = await this.CodeAutomatiqueService.updateCodeAutomatique(this.MassiaApplicationService.getApplication(), element);
			}
			this.notification.success('CODE_AUTOMATIQUE.UPDATED');
			this.loadCodeAutomatique();
		} catch (ex) {
			console.log(ex);
			this.notification.error(ex);
		}
	}

	maskValidation(element) {
		element.masque = element.masque.replace(/\s+/g, ''); //Suppression des espaces dans le masque
		element.masque = element.masque.replace(/([^a-zA-Z0-9 [\]]|\[+?\])/g, ''); //Suppression des caractères spéciaux
		const regex = new RegExp(/(\[[A-Z]+&?[0-9]+\])+/g); // Verification de la présence du masque de nomenclature (exmple: [N5])

		if (element.masque === null || element.masque === '') {
			throw this.notification.error('Un champ Masque activé doit être renseigné.');
		}

		if (!regex.test(element.masque)) {
			throw this.notification.error('Un ou plusieurs champs Masque ne sont pas au bon format.');
		}

		//verification de la longueur maximal du masque (doit être inférieur à 15)
		const nomenclature = element.masque.match(regex)[0];
		const nomenclatureNumber = parseInt(nomenclature.match(/(\d+)/)[0]);
		const CharactersOutsideNmenclature = element.masque.replace(regex, '');
		const maskLength = CharactersOutsideNmenclature.length + nomenclatureNumber;

		if (maskLength > 15) {
			throw this.notification.error('La longueur du champ Masque ne peut excéder 15 caractères.');
		}

		//vérification de l'utilisation des lettres de la nomenclature
		const validNomenclature = ['N', 'U', 'PI', 'J', 'JA', 'M', 'A', 'A&', 'SIU', 'SIP', 'TYPEFAC'];
		const letterRegex = new RegExp('(?<=\\[).+?(?=[0-9]*\\])', 'g'); //récuperation des Lettres contenues antre crochet
		const nomenclatureLetterInCurrentMask = nomenclature.match(letterRegex);
		let isFounded = nomenclatureLetterInCurrentMask.some((item) => validNomenclature.includes(item));

		if (element.domaineCode == 'Factures') {
			const nomencFacture = ['SIU', 'SOU'];
			const nomencRegex = new RegExp('(?<=\\[).+?(?=[0-9]*\\])', 'g');
			const nomencFactureInMask = nomenclature.match(nomencRegex);
			let isNomencFactureFound = nomencFactureInMask.some((item) => nomencFacture.includes(item));
			if (!isNomencFactureFound) {
				let inputFactures = document.getElementById(element.domaineCode);
				inputFactures.style.border = '2px solid red';
				throw this.notification.error(
					'Factures: Le Code de la Société ou du Site est absent du masque de saisie. Veuillez le compléter avec [SOUx] ou [SIUx]'
				);
			}
		}

		if (!isFounded) {
			throw this.notification.error('Un ou plusieurs champs Masque ne sont pas au bon format.');
		}
	}

	openHelper() {
		this.$uibModal.open({
			animation: true,
			size: 'lg',
			component: 'codeAutoHelper'
		});
	}

	startLoading() {
		this.loading = true;
	}

	async annuler() {
		this.loadCodeAutomatique();
	}

	stopLoading() {
		this.loading = false;
	}
}

CodeAutoController.$inject = [
	'$scope',
	'CodeAutomatiqueService',
	'notification',
	'$stateParams',
	'MassiaApplicationService',
	'ModalService',
	'$uibModal'
];
