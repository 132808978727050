import { DebugLevel } from '../../../../../../../libs/massia-component/massia-service/logger.service';

/**
 *
 * @param {ng.IScope} $scope
 * @param {ng.ui.bootstrap.IModalService} $uibModal
 * @param {StateService} $state
 * @ngInject
 */
export default function SyntheseListController(
	$scope,
	$state,
	SyntheseSettingService,
	MassiaApplicationService,
	PaginationService,
	SyntheseEntityService,
	ModalService,
	notification,
	$translate,
	$uibModal,
	$document,
	Logger,
	MOPService
) {
	let previousTableState;
	this.loading = false;
	this.syntheseTable = [];
	this.activite = MassiaApplicationService.getApplication();
	this.domain = this.activite !== 'laboratoire' ? 'massiasynthapp' : 'synthadmin';
	this.actualTypeSynthese = 0;
	$scope.itemsByPageOptions = [20, 50, 100];
	this.MOPService = MOPService;

	this.$onInit = () => {
		this.MOPService.setMop([{ title: 'Synthèses', filename: 'Syntheses.pdf', application: 'gestion' }]);
		this.loadTypeSynthese();

		this.unregister = $scope.$watch(
			() => this.actualTypeSynthese,
			async () => await this.loadSynthese(this.previousTableState),
			true
		);
	};

	this.$onDestroy = () => {
		this.unregister();
		this.MOPService.resetMop();
	};

	this.changeTypeSynthese = (type) => {
		this.actualTypeSynthese = type.id;
	};

	this.loadTypeSynthese = async () => {
		try {
			this.typeSynthese = await SyntheseSettingService.loadTypeSynthese(this.activite);
			// temporaire tant que les synth V1 sont encore dispo
			if (this.activite == 'laboratoire') {
				this.typeSynthese = this.typeSynthese.filter((x) => x.name == 'TYPE_SYNTHESE.CONTROLEPESEE');
			}
			if (SyntheseSettingService.currentTypeSynthese == -1) {
				this.actualTypeSynthese = this.typeSynthese[0].id;
			} else {
				this.actualTypeSynthese = SyntheseSettingService.currentTypeSynthese;
				SyntheseSettingService.currentTypeSynthese = -1;
			}
		} catch (err) {
			Logger.log(DebugLevel.ERROR, err);
		}
	};

	this.loadSynthese = async (tableState) => {
		this.toggleLoading();
		try {
			if ($state && $state.name !== 'synthese.synthese.list') {
				$state.go('synthese.synthese.list');
			}

			if (tableState) {
				previousTableState = tableState;
			}

			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const pagination = PaginationService.getPagination(previousTableState);
			const data = await SyntheseEntityService.getAll(this.actualTypeSynthese, filters, sorts, pagination);
			this.syntheseTable = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			Logger.log(DebugLevel.ERROR, ex);
		}
		this.toggleLoading();
	};

	this.toggleLoading = () => {
		this.loading = !this.loading;
	};

	this.openSynthese = (synth) => {
		$state.go('synthese.synthese.generate', { id: synth.id });
	};

	this.deleteSynthese = async (synth) => {
		try {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('SYNTHENTS.DELETE.TITLE', { code: synth.libelle }),
				modalMsg: $translate.instant('SYNTHENTS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			await modalInstance.result;
			await SyntheseEntityService.delete(synth);
			this.loadSynthese();
			notification.success($translate.instant('SYNTHENTS.DELETE.SUCCESS'));
		} catch (err) {
			Logger.log(DebugLevel.ERROR, err);
			notification.error(err.data.message || err.data);
		}
	};

	//Fx func for bind
	this.generateSynthese = async (id, user) => {
		try {
			const modal = $uibModal.open({
				animation: false,
				component: 'setupSyntheseModal',
				windowClass: 'modern-modal',
				size: 'lg',
				appendTo: $document.find('massia-application').eq(0),
				resolve: {
					synthese: async () => await SyntheseEntityService.getOne(id, !user),
					isUser: () => user
				}
			});
			const res = await modal.result;
			if (user) {
				$state.go('^.edit', { id: res.id, synthese: res, user: true });
			} else {
				if (res.id === id) {
					await SyntheseEntityService.updateOne(id, res, user);
					notification.clear();
					notification.success('SUCCESS_UPDATE', null, {
						closeButton: false
					});
				}
				this.loadSynthese(previousTableState);
			}
		} catch (err) {
			Logger.log(DebugLevel.ERROR, err);
			//modal closed
		}
	};
}
